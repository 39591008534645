export default class SecurityRequestPayload {
  isin: string;
  marketValue: number;
  currency: string;
  baseYear: number;

  constructor(
    isin: string,
    marketValue: number,
    currency: string,
    baseYear: number
  ) {
    this.isin = isin;
    this.marketValue = marketValue;
    this.currency = currency;
    this.baseYear = baseYear;
  }
}
