
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";

@Component
export default class BaseAlert extends mixins(Vue, AssetMixin) {
  @Prop({ required: true }) id!: string;

  showAlert = false;

  show(): void {
    this.showAlert = true;
  }

  hide(): void {
    this.showAlert = false;
  }
}
