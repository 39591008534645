
import { Component, Vue } from "vue-property-decorator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BCol, BRow } from "bootstrap-vue";
import BaseAndTargetYearSelector from "@/pages/main/components/toolbar/configuration/components/BaseAndTargetYearSelector.vue";
import ParameterSelector from "@/pages/main/components/toolbar/configuration/components/ParameterSelector.vue";
import { SSP } from "@/models/SSP";
import { Provider } from "@/models/Provider";
import { mixins } from "vue-class-component";
import FeedbackMixin from "@/mixin/FeedbackMixin";
import UserMixin from "@/mixin/UserMixin";
import { ModalID } from "@/enums/ModalID";

export type Settings = {
  baseYear: number;
  targetYear: number;
  provider: string;
  sspCode: string;
};
@Component({
  components: {
    BaseAndTargetYearSelector,
    ParameterSelector,
    BaseModal,
    BaseButton,
    BRow,
    BCol,
  },
})
export default class ConfigurationModal extends mixins(
  Vue,
  FeedbackMixin,
  UserMixin
) {
  private readonly id = ModalID.CHANGE_CONFIGURATION;

  currentSettings: Settings = {
    baseYear: this.storedBaseYear,
    targetYear: this.storedTargetYear,
    provider: this.storedProvider,
    sspCode: this.storedSspCode,
  };

  isApplyButtonStatus = true;
  scenarioOptions = [
    SSP.SSP1,
    SSP.SSP2,
    SSP.SSP2_RCP60,
    SSP.SSP3,
    SSP.SSP4,
    SSP.SSP5,
  ];
  providerOptions = [Provider.IEA, Provider.OECM, Provider.NGFS];
  sspCodeChanged = false;
  providerChanged = false;
  targetYearChanged = false;
  baseYearChanged = false;

  resetParametersState(): void {
    this.currentSettings.baseYear = this.storedBaseYear;
    this.currentSettings.targetYear = this.storedTargetYear;
    this.currentSettings.provider = this.storedProvider;
    this.currentSettings.sspCode = this.storedSspCode;
    this.baseYearChanged = false;
    this.targetYearChanged = false;
    this.providerChanged = false;
    this.sspCodeChanged = false;
    this.applyButtonDisabled = true;
  }

  get applyButtonDisabled(): boolean {
    this.isApplyButtonStatus = !(
      this.baseYearChanged ||
      this.targetYearChanged ||
      this.providerChanged ||
      this.sspCodeChanged
    );

    return this.isApplyButtonStatus;
  }

  set applyButtonDisabled(value: boolean) {
    this.isApplyButtonStatus = value;
  }

  applyChangedConfiguration(): void {
    this.storeChangeConfiguration(this.currentSettings);
  }

  storeChangeConfiguration(currentSettings: Settings): void {
    this.$store.commit("setBaseYear", currentSettings.baseYear);
    this.$store.commit("setTargetYear", currentSettings.targetYear);
    this.$store.commit("setSspCode", currentSettings.sspCode);
    this.$store.commit("setProvider", currentSettings.provider);

    this.openFeedbackPopUpAndReloadData(currentSettings.baseYear);
    this.close();
  }

  close(): void {
    this.applyButtonDisabled = true;
    this.resetParametersState();
    Vue.prototype.$modal.hide(this.id);
  }

  updateBaseYear(newValue: number): void {
    this.currentSettings.baseYear = newValue;
    this.baseYearChanged = this.storedBaseYear !== newValue;
  }

  updateTargetYear(newValue: number): void {
    this.currentSettings.targetYear = newValue;
    this.targetYearChanged = this.storedTargetYear !== newValue;
  }

  updateSspCode(newValue: string): void {
    this.currentSettings.sspCode = newValue;
    this.sspCodeChanged = this.storedSspCode !== newValue;
  }

  updateProvider(newValue: string): void {
    this.currentSettings.provider = newValue;
    this.providerChanged = this.storedProvider !== newValue;
  }

  get storedBaseYear(): number {
    return this.$store.state.baseYear;
  }

  get storedTargetYear(): number {
    return this.$store.state.targetYear;
  }

  get storedProvider(): string {
    return this.$store.state.provider;
  }

  get storedSspCode(): string {
    return this.$store.state.sspCode;
  }

  private openFeedbackPopUpAndReloadData(year: number): void {
    let sizeOfAllSecurities = this.sizeOfAllSecurities();

    if (sizeOfAllSecurities > 0) {
      this.resetFeedback();
      Vue.prototype.$modal.show(ModalID.UPLOAD_FEEDBACK_MODAL);
      this.showFeedback(sizeOfAllSecurities, [], 0, [], [], "");

      this.$store.commit("setImportingBulkData", true);
      this.$store.dispatch("addLoadingPortfolioValues");
      this.$store.dispatch("updateSecurities", year);
    }
  }

  private sizeOfAllSecurities(): number {
    return this.$store.state.validSecurities.concat(
      this.$store.state.invalidSecurities
    ).length;
  }
}
