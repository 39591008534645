import { MetricEventType } from "@/enums/MetricEventType";

export default class Metric {
  type: MetricEventType;
  value: number;

  constructor(type: MetricEventType, value: number) {
    this.type = type;
    this.value = value;
  }
}
