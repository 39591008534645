import { Component, Vue } from "vue-property-decorator";
import MetricsGateway from "@/services/MetricsGateway";
import Metric from "@/models/Metric";
import { MetricEventType } from "@/enums/MetricEventType";

@Component
export default class MetricsMixin extends Vue {
  sendMetric(event: MetricEventType, value = 1): void {
    const payload = new Metric(event, value);

    MetricsGateway.sendMetricData(payload);
  }
}
