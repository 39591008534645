export const FREE_TIER_ROLE = "free_tier";
export const PAID_TIER_ROLE = "paid_tier";
export const MODULE_SB = "module_sb";
export const STAFF = "pe_staff";

export const ALL_ROLES = [FREE_TIER_ROLE, PAID_TIER_ROLE, MODULE_SB, STAFF];

export type Roles =
  | typeof FREE_TIER_ROLE
  | typeof PAID_TIER_ROLE
  | typeof MODULE_SB
  | typeof STAFF;
