
import { Component, Prop, Vue } from "vue-property-decorator";
import { BImg } from "bootstrap-vue";

@Component({ components: { BImg } })
export default class ShowcaseItem extends Vue {
  @Prop({ required: true }) tag!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) text!: string;
  @Prop({ required: true }) imagePath!: string;
  @Prop({ required: false, default: "right" }) imagePosition!: string;

  get order(): string {
    if (this.imagePosition === "right") {
      return "flex-row";
    } else {
      return "flex-row-reverse";
    }
  }

  get imageOrder(): string {
    if (this.imagePosition === "right") {
      return "justify-content-end";
    } else {
      return "justify-content-start";
    }
  }

  get imgUrl(): string {
    return require("@/assets/" + this.imagePath);
  }
}
