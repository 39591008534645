
import { Component, Vue } from "vue-property-decorator";
import BaseDropdownMenu from "@/pages/main/components/base/BaseDropdownMenu.vue";
import DropdownMenuItem from "@/pages/shared/components/buttons/DropdownMenuItem.vue";
import { FAQRoute, LandingRoute, PlansRoute } from "@/router/Routes";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";
import UserMixin from "@/mixin/UserMixin";
import ChangeLogModal from "@/pages/faq/modals/ChangeLogModal.vue";
import ChangeLogAction from "@/pages/faq/modals/ChangeLogAction.vue";

@Component({
  components: {
    DropdownMenuItem,
    BaseDropdownMenu,
    ChangeLogModal,
    ChangeLogAction,
  },
})
export default class ProfileDropdownMenu extends mixins(
  Vue,
  AssetMixin,
  UserMixin
) {
  routeFAQPath = FAQRoute.path;
  routePlansPath = PlansRoute.path;

  created(): void {
    this.$store.dispatch("informationStore/getXDCVersion");
  }

  get engineVersion(): string {
    return this.$store.state.informationStore.engineVersion;
  }

  signOut(): void {
    if (this.isUserAuthenticated) {
      this.$store.dispatch("auth/logout");
      this.$router.push({ path: LandingRoute.path });
    }
  }
}
