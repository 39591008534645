
import { Component, Vue } from "vue-property-decorator";
import ActionButton from "@/pages/shared/components/buttons/ActionButton.vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";
import { ModalID } from "@/enums/ModalID";
import UserMixin from "@/mixin/UserMixin";
import { ActionID } from "@/enums/ActionID";
import CSVExporterMixin from "@/mixin/CSVExporterMixin";
import MetricsMixin from "@/mixin/MetricsMixin";
import { MetricEventType } from "@/enums/MetricEventType";

@Component({
  components: { ActionButton },
})
export default class CSVExportAction extends mixins(
  Vue,
  AssetMixin,
  UserMixin,
  CSVExporterMixin,
  MetricsMixin
) {
  id = ActionID.CSV_DOWNLOAD;

  get isDisabled(): boolean {
    const importingBulkData = this.$store.state.importingBulkData;
    const securityCount = this.$store.getters.totalSecuritiesCount;

    // download button is inactive, if importing OR if no securities available
    return importingBulkData || securityCount === 0;
  }

  downloadCSV(): void {
    if (this.isUserPremiumTier) {
      this.downloadSecuritiesCSV();
      this.sendMetric(MetricEventType.CLICK_CSV_DOWNLOAD);
    } else {
      Vue.prototype.$modal.show(ModalID.UPGRADE_MODAL);
    }
  }
}
