
import { Component, Vue } from "vue-property-decorator";
import { BIconChevronLeft } from "bootstrap-vue";
import Config from "@/constants/Config";
import TierCard from "@/pages/plans/components/TierCard.vue";
import ModuleCard from "@/pages/plans/components/ModuleCard.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixin/UserMixin";

@Component({
  components: { BaseButton, ModuleCard, TierCard, BIconChevronLeft },
})
export default class PlansPage extends mixins(Vue, UserMixin) {
  contactEmail =
    Config.ContactEmailAddress + "?subject=Request for Module Pricing";

  navigateBack(): void {
    this.$router.go(-1);
  }

  get freeTier(): Record<string, unknown> {
    return {
      heading: "Free",
      listItems: [
        {
          message:
            "XDC results on <strong>portfolio- & security-level for listed equity</strong>",
        },
        { message: "Upload your portfolio via CSV" },
        { message: "Use of the IEA Provider" },
        { message: "Access <strong>2017 base year</strong> data" },
      ],
      buttonText: "Register for free",
      isPremiumCard: false,
      moduleIsActive: this.isUserFreeTier,
    };
  }

  get premiumTier(): Record<string, unknown> {
    return {
      heading: "Premium",
      listItems: [
        {
          message:
            "XDC results on <strong>portfolio- & security-level for listed equity</strong>",
        },
        {
          message:
            "Upload your portfolio & <strong>download</strong> your results via CSV and Excel files",
        },
        {
          message:
            "NGFS, IEA or OECM – <strong>you choose</strong> your provider",
        },
        {
          message:
            "Access <strong>2016, 2017, 2018, 2019, 2020, 2021 & 2022 base year</strong> data",
        },
        { message: "<strong>Edit</strong> portfolio market values" },
        {
          message:
            "<strong>Select your SSP:</strong> The Portfolio Explorer lets you choose among six Shared Socioeconomic Pathways should be used to calculate the Baseline XDC of your portfolio",
        },
        {
          message:
            "<strong>Flexible timeframe:</strong> Choose anything between 2030 and 2100 as your end year",
        },
        { message: "<strong>Personal support</strong> from our experts" },
      ],
      buttonText: "Request pricing",
      isPremiumCard: true,
      moduleIsActive: this.isUserPremiumTier,
    };
  }

  ////Add Module texts here////
  get sovereignBonds(): Record<string, unknown> {
    return {
      heading: "Sovereign Bonds",
      listItems: [
        {
          message:
            "Take the next step and <strong>expand your portfolio</strong> with sovereign bonds",
        },
        {
          message:
            "Measure and manage the temperature alignment of a <strong>multi-asset portfolio</strong> covering listed equity, corporate bonds, as well as <strong>sovereign bonds</strong>",
        },
        {
          message:
            "Visualize the Sovereign Bonds, their market values and XDCs in your portfolio with a new <strong>interactive map</strong> component",
        },
      ],
      moduleIsActive: this.userHasSovereignBondModule,
    };
  }
}
