
import { Component, Prop, Vue } from "vue-property-decorator";
import NavMenu from "@/pages/main/components/header/Sidebar.vue";
import ProfileDropdownMenu from "@/pages/main/components/header/ProfileDropdownMenu.vue";
import SignInButton from "@/pages/shared/components/buttons/SignInButton.vue";
import ContactButton from "@/pages/shared/components/buttons/ContactButton.vue";
import { mixins } from "vue-class-component";
import HeaderCheckMixin from "@/mixin/HeaderCheckMixin";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BCol, BRow } from "bootstrap-vue";
import UserMixin from "@/mixin/UserMixin";

@Component({
  components: {
    ProfileDropdownMenu,
    NavMenu,
    SignInButton,
    ContactButton,
    BaseButton,
    BCol,
    BRow,
  },
})
export default class ScenarioSelectorHeader extends mixins(
  Vue,
  HeaderCheckMixin,
  UserMixin
) {
  @Prop({ required: false, default: false }) compactMode!: boolean;
}
