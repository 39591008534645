import FileHandler from "@/services/FileHandler";
import { UploadError } from "@/enums/UploadError";
import { parse, ParseResult } from "papaparse";
import { filter } from "lodash";
import ParsedSecurity from "@/models/ParsedSecurity";

export default class CSVImporter extends FileHandler {
  static isValidCSV(file: File): boolean {
    return (
      (file.type.length > 0 || file.type === "text/csv") &&
      file.name.toLowerCase().endsWith(".csv")
    );
  }

  static getParsedNewSecurities(csvFile: File): Promise<Array<ParsedSecurity>> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async () => {
        const fileText = reader.result;

        if (fileText) {
          if (!this.isFileHeaderValid(fileText)) {
            reject(UploadError.CSV_MISSING_HEADER);

            return;
          }

          const parserConfig = this.getParserConfiguration(fileText);
          const parseResult = parse(fileText.toString(), parserConfig);

          try {
            const parsedSecuritiesResult = this.parseAllSecurities(parseResult);

            resolve(parsedSecuritiesResult);
          } catch (error: any) {
            if (error.message === `${UploadError.CSV_INCORRECT_FORMAT}`)
              reject(UploadError.CSV_INCORRECT_FORMAT);
          }
        }
      };

      reader.readAsText(csvFile);
    });
  }

  static isFileHeaderValid(fileText: string | ArrayBuffer): boolean {
    const firstLine = fileText.toString().split("\n")[0].toLowerCase();

    return (
      firstLine.includes("isin") &&
      firstLine.includes("market_value") &&
      firstLine.includes("currency")
    );
  }

  static getParserConfiguration(
    fileText: string | ArrayBuffer
  ): Record<string, unknown> {
    const firstLine = fileText.toString().split("\n")[0].toLowerCase();

    let delimiter = "\t";

    const numberOfTabs = firstLine.match(/\t/g)?.length || 0;
    const numberOfCommas = firstLine.match(/,/g)?.length || 0;
    const numberOfSemiColons = firstLine.match(/;/g)?.length || 0;

    if (numberOfTabs > numberOfCommas && numberOfTabs > numberOfSemiColons) {
      delimiter = "\t";
    } else if (
      numberOfCommas > numberOfTabs &&
      numberOfCommas > numberOfSemiColons
    ) {
      delimiter = ",";
    } else if (
      numberOfSemiColons > numberOfCommas &&
      numberOfSemiColons > numberOfTabs
    ) {
      delimiter = ";";
    }

    return {
      delimiter: delimiter,
      header: true,
      transformHeader: (header: string) => header.toLowerCase().trim(),
    };
  }

  private static parseAllSecurities(
    parseResult: ParseResult<unknown>
  ): Array<ParsedSecurity> {
    const fileData: Array<any> = parseResult.data;

    const marketValueIdx = "market_value";
    const isinIdx = "isin";
    const currencyIdx = "currency";

    const nonBlankLines = filter(
      fileData,
      (line) => !this.isLineEmpty(line, line[isinIdx])
    );

    const undefinedISINs =
      nonBlankLines.filter(
        (line) => line[isinIdx] === undefined || line[currencyIdx] === undefined
      ).length > 0;

    if (undefinedISINs) {
      throw Error(`${UploadError.CSV_INCORRECT_FORMAT}`);
    }

    //Check for existing items
    const parsedSecurities = nonBlankLines.map(
      (line) =>
        new ParsedSecurity(
          line[isinIdx],
          line[marketValueIdx],
          line[currencyIdx]
        )
    );

    // Removing duplicate securities based on isin
    return [
      ...new Map(
        parsedSecurities.map((security) => [security.isin, security])
      ).values(),
    ];
  }

  private static isLineEmpty(
    line: string[],
    isinValue: string | undefined
  ): boolean {
    if (line.length === 1) {
      return line[0].trim() === "";
    } else if (isinValue !== undefined) {
      return isinValue.trim() === "";
    }

    return true;
  }
}
