
import Security from "@/models/security/Security";
import { Component, Vue } from "vue-property-decorator";
import { BCol, BRow } from "bootstrap-vue";
import { SSP } from "@/models/SSP";

@Component({
  components: { BRow, BCol },
})
export default class PortfolioInformation extends Vue {
  scenarioMap = new Map([
    [SSP.SSP1.code, SSP.SSP1.displayName],
    [SSP.SSP2.code, SSP.SSP2.displayName],
    [SSP.SSP2_RCP60.code, SSP.SSP2_RCP60.displayName],
    [SSP.SSP3.code, SSP.SSP3.displayName],
    [SSP.SSP4.code, SSP.SSP4.displayName],
    [SSP.SSP5.code, SSP.SSP5.displayName],
  ]);

  get portfolioName(): string {
    return this.$store.state.portfolioName;
  }

  get securities(): Array<Security> {
    return this.$store.state.validSecurities;
  }

  changePortfolioNameHandler(event: Event): void {
    const target = event.target as HTMLInputElement;
    const portfolioName = target.value as string;
    this.$store.commit("setPortfolioName", portfolioName);
  }

  get selectedBaseYear(): number {
    return this.$store.state.baseYear;
  }

  get selectedTargetYear(): number {
    return this.$store.state.targetYear;
  }

  get selectedProvider(): string {
    return this.$store.state.provider;
  }

  get selectedSspCode(): string {
    const selectedSspCode = this.scenarioMap.get(this.$store.state.sspCode);

    return selectedSspCode ? selectedSspCode : "";
  }
}
