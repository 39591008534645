
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DropdownMenuItem extends Vue {
  @Prop({ required: false, default: "" }) imageUrl!: string;
  @Prop({ required: false, default: "" }) menuText!: string;
  @Prop({ required: false, default: "" }) trailingText!: string;
  @Prop({ required: false, default: false }) selected!: boolean;
  @Prop({ required: false, default: true }) isClickable!: boolean;
  @Prop({ required: false, default: "40" }) iconSize!: string;
  @Prop({ required: false, default: "55" }) menuItemHeight!: string;
  @Prop({ required: false, default: false, type: Boolean }) isReverse!: boolean;

  get isSelected(): boolean {
    return this.selected;
  }

  get isImage(): boolean {
    return this.imageUrl !== "";
  }

  get isTrailingText(): boolean {
    return this.trailingText !== "";
  }

  get cssVariables(): Record<string, string> {
    return {
      "--menuItemHeight": this.menuItemHeight + "px",
    };
  }

  get imageCss(): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + this.imageUrl + ")",
      "mask-image": "url(" + this.imageUrl + ")",
      "--iconSize": this.iconSize + "px",
    };
  }

  emitClick(): void {
    if (this.isClickable) {
      const dropdownMenu = this.$parent!.$parent!.$refs.dropdownMenu;

      if (dropdownMenu) {
        (dropdownMenu as HTMLElement).click();
      }

      this.$emit("click");
    }
  }
}
