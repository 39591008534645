import { State } from "@/store/main/State";
import Security from "@/models/security/Security";
import { difference, filter, findIndex, forEach, includes } from "lodash";
import PortfolioValues from "@/models/PortfolioValues";

export function updateListWithNewSecurities(
  state: State,
  securities: Security[],
  referenceSecurities: Security[]
): Security[] {
  let tmpSecurities = referenceSecurities;
  const existingISINs = tmpSecurities.map((s) => s.isin);

  // Split incoming values in already present and not present groups in reference to the referenceSecurities
  const existingSecurities = filter(securities, (s) =>
    includes(existingISINs, s.isin)
  );
  const nonExistingSecurities = difference(securities, existingSecurities);

  // The non-present securities can be added directly to the referenceSecurities
  tmpSecurities = tmpSecurities.concat(nonExistingSecurities);

  // Values that were already present in the referenceSecurities have to be updated
  forEach(existingSecurities, (es) => {
    const idx = findIndex(tmpSecurities, (s) => s.isin === es.isin);
    tmpSecurities.splice(idx, 1, es);
  });

  return tmpSecurities;
}

export function resetPortfolioValuesIfNecessary(state: State): void {
  // We just reset the values if we are not in the middle of importing a CSV
  // i.e. when a single ISIN is added
  // During a portfolio calc we don't care since the calculation will be triggered at the end of the process
  if (!state.importingBulkData) {
    state.needsRecalculation = true;
    state.portfolioValues = new PortfolioValues();
  }
}

export function removeSecuritiesByISIN(
  securities: Security[],
  isins: string[]
): Array<Security> {
  return filter(securities, (s) => !includes(isins, s.isin));
}
