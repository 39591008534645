import { SecurityType } from "@/models/security/SecurityType";
import { NACE_CODE_TO_SECTOR_NAME_MAP } from "@/constants/NaceCode";

export default class DataFaker {
  static getAlignment(): boolean {
    return Boolean(Math.round(Math.random()));
  }

  static randomDelay(): Promise<void> {
    const delayTime = this.generateRandomNumberWithDecimals(500, 4000, 0);

    return new Promise((resolve) => setTimeout(resolve, delayTime));
  }

  static getXDCValue(): number {
    return this.generateRandomNumberWithDecimals(1, 4, 1);
  }

  static getCountryCode(): string {
    const sampleCountryCodes = ["DE", "US", "GB", "BR", "JP", "IN", "MA"];

    return this.getRandomItemFromArray(sampleCountryCodes);
  }

  static getRandomCurrency(): string {
    const sampleCurrencyCodes = ["USD", "EUR", "GBP", "CNY", "JPY", "INR"];

    return this.getRandomItemFromArray(sampleCurrencyCodes);
  }

  static getNaceCode(): string {
    return this.getRandomItemFromArray(
      Object.keys(NACE_CODE_TO_SECTOR_NAME_MAP)
    );
  }

  static getNaceSector(naceCode: string): string {
    return NACE_CODE_TO_SECTOR_NAME_MAP[naceCode];
  }

  static getSecurityType(): string {
    const sampleSecurityTypes = Object.values(SecurityType);

    return this.getRandomItemFromArray(sampleSecurityTypes);
  }

  static getDataSource(): string {
    const sampleDataSources = ["Reported", "N/A"];

    return this.getRandomItemFromArray(sampleDataSources);
  }

  static getScope3Categories(): string {
    return this.generateRandomNumberWithDecimals(1, 15, 0).toString();
  }

  static getRandomMarketValue(): number {
    return this.generateRandomNumberWithDecimals(500, 10000, 2);
  }

  private static generateRandomNumberWithDecimals(
    min: number,
    max: number,
    decimalPlaces: number
  ): number {
    const rand = Math.random() * (max - min) + min;
    const power = Math.pow(10, decimalPlaces);

    return Math.floor(rand * power) / power;
  }

  private static getRandomItemFromArray(array: Array<string>): string {
    return array[Math.floor(Math.random() * array.length)];
  }
}
