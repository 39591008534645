
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import DropdownMenuItem from "../../shared/components/buttons/DropdownMenuItem.vue";
import AssetMixin from "../../../mixin/AssetMixin";
import { ModalID } from "@/enums/ModalID";
import ActionButton from "@/pages/shared/components/buttons/ActionButton.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";

@Component({
  components: { BaseButton, ActionButton, DropdownMenuItem },
})
export default class ChangeLogAction extends mixins(Vue, AssetMixin) {
  @Prop({ required: false, default: false }) isMenuItem!: boolean;

  openModal(): void {
    Vue.prototype.$modal.show(ModalID.CHANGELOG_MODAL);
  }
}
