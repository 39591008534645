import { AbbreviationName } from "@/models/AbbreviationName";

export class Provider implements AbbreviationName {
  public static readonly IEA = new Provider(
    "IEA",
    "IEA",
    "International Energy Agency"
  );
  public static readonly OECM = new Provider(
    "OECM",
    "OECM",
    "One Earth Climate Model"
  );
  public static readonly NGFS = new Provider(
    "NGFS",
    "NGFS",
    "Network for Greening the Financial System"
  );

  private constructor(
    public readonly displayName: string,
    public readonly code: string,
    public readonly fullName: string
  ) {}
}
