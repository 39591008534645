
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseDropdownMenu extends Vue {
  @Prop({ required: true }) imageUrl!: string;
  @Prop({ required: false, default: false }) alignRight!: boolean;

  openOptions = false;

  get isSelected(): boolean {
    return this.openOptions;
  }

  get imageCss(): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + this.imageUrl + ")",
      "mask-image": "url(" + this.imageUrl + ")",
    };
  }

  /*
    The next 3 functions are used to close the dropdown if the user clicks outside of it
   */
  mounted(): void {
    document.addEventListener("click", this.close);
  }

  beforeUnmount(): void {
    document.removeEventListener("click", this.close);
  }

  close(e: Event): void {
    const node = e.target as Node;

    if (!this.$el.contains(node)) {
      this.openOptions = false;
    }
  }
}
