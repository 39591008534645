
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SortDirection } from "@/enums/SortDirection";
import { BIconChevronUp } from "bootstrap-vue";

@Component({ components: { BIconChevronUp } })
export default class PortfolioTableHeaderCell extends Vue {
  @Prop({ required: true }) displayName!: string;
  @Prop({ required: true }) sortParameter!: string;

  get currentlySortedColumn(): string {
    return this.$store.state.portfolioTable.currentlySortedColumn;
  }

  set currentlySortedColumn(currentlySortedColumn: string) {
    this.$store.commit(
      "portfolioTable/updateCurrentlySortedColumn",
      currentlySortedColumn
    );
  }

  get currentSortDirection(): SortDirection {
    return this.$store.state.portfolioTable.currentSortDirection;
  }

  set currentSortDirection(currentSortDirection: SortDirection) {
    this.$store.commit(
      "portfolioTable/updateCurrentSortDirection",
      currentSortDirection
    );
  }

  get sortIcon(): string {
    if (this.currentlySortedColumn === this.sortParameter) {
      if (this.currentSortDirection === SortDirection.ASCENDING) {
        return "up";
      }

      return "down";
    }

    return "";
  }

  @Watch("selectedScenario")
  onScenarioAwareColumnNameChange(): void {
    if (this.currentlySortedColumn === this.sortParameter) {
      this.toggleCurrentlySortedColumn();
    }
  }

  toggleCurrentlySortedColumn(): void {
    if (this.currentlySortedColumn !== this.sortParameter) {
      this.currentlySortedColumn = this.sortParameter;
    }
  }

  toggleCurrentSortDirection(): void {
    if (this.currentlySortedColumn === this.sortParameter) {
      this.currentSortDirection =
        this.currentSortDirection === SortDirection.ASCENDING
          ? SortDirection.DESCENDING
          : SortDirection.ASCENDING;
    }
  }
}
