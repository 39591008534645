
import { Component, Vue } from "vue-property-decorator";
import { BCol, BRow } from "bootstrap-vue";
import NumberFormatter from "@/services/NumberFormatter";
import PortfolioValues from "@/models/PortfolioValues";
import LoadingSpinner from "@/pages/shared/components/LoadingSpinner.vue";
import { Status } from "@/enums/Status";
import { PARIS_ALIGNMENT_TARGET_YEAR } from "@/constants/TargetYear";
import { ALIGNMENT_THRESHOLD } from "@/constants/AlignmentThreshold";

@Component({
  components: { BRow, BCol, LoadingSpinner },
})
export default class PortfolioXDCValues extends Vue {
  ALIGNMENT_LIMIT_VALUE = ALIGNMENT_THRESHOLD;
  PARIS_NOT_ALIGNED = "Not Paris-aligned";
  PARIS_ALIGNED = "Paris-aligned";
  NOT_ALIGNED = "Not aligned";
  ALIGNED = "Aligned";

  get isLoading(): boolean {
    return this.portfolioValues.status === Status.LOADING;
  }

  get alignmentLabel(): string {
    const targetYearState = this.$store.state.targetYear;

    if (isNaN(this.portfolioBaselineXDCTotal)) {
      return "_";
    }

    if (targetYearState === PARIS_ALIGNMENT_TARGET_YEAR) {
      return this.isAligned ? this.PARIS_ALIGNED : this.PARIS_NOT_ALIGNED;
    }

    return this.isAligned ? this.ALIGNED : this.NOT_ALIGNED;
  }

  get isAligned(): boolean {
    return (
      isNaN(this.portfolioBaselineXDCTotal) ||
      this.portfolioBaselineXDCTotal <= this.ALIGNMENT_LIMIT_VALUE
    );
  }

  get alignmentColor(): string {
    const gap = this.portfolioBaselineXDCTotal;
    const color = this.getColorCode(gap);

    return "alignment-color-" + color;
  }

  getColorCode(gap: number): string {
    if (isNaN(gap)) {
      return "";
    }

    if (gap <= 1.5) {
      return "1-5";
    }

    if (gap > 1.9) {
      return "2-0";
    }

    return gap.toString().replace(".", "-");
  }

  get baselineXDCTotal(): string {
    const portfolioValues = this.portfolioValues;
    const status = portfolioValues.status;

    if (status === Status.LOADING || status === Status.ERROR) {
      return NumberFormatter.temperature(NaN);
    }

    return NumberFormatter.temperature(this.portfolioBaselineXDCTotal);
  }

  get portfolioBaselineXDCTotal(): number {
    return this.portfolioValues.baselineXDC.total;
  }

  get portfolioValues(): PortfolioValues {
    return this.$store.state.portfolioValues;
  }
}
