// TODO: maybe this should optionally check for the type as well
export default function getOrElse(
  jsonData: Record<string, any>,
  key: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  elseValue: any
): any {
  return Object.prototype.hasOwnProperty.call(jsonData, key)
    ? jsonData[key]
    : elseValue;
}
