import { Route } from "vue-router";
import TokenStorage from "@/auth/services/TokenStorage";
import AuthGateway from "@/auth/services/AuthGateway";
import { STAFF } from "@/constants/Roles";

export function noAuthIsRequired(to: Route): boolean {
  if (to.meta) {
    return !to.meta.requiresAuth;
  }

  // If no meta is present than there are no restrictions
  return true;
}

export function hasRefreshToken(): boolean {
  const refreshToken = TokenStorage.getCurrentRefreshToken();

  return refreshToken !== null;
}

export function triggerTokenRefresh(): Promise<void> {
  return AuthGateway.refreshToken();
}

export function routeIsClosedForLoggedUsers(to: Route): boolean {
  const isUserAuthenticated = TokenStorage.isTokenValid();
  const isDisabledIfLoggedIn = to.meta ? to.meta.disabledIfLoggedIn : false;

  return isDisabledIfLoggedIn && isUserAuthenticated;
}

export function userFulfillsRouteRequirements(to: Route): boolean {
  const requiredRolesForRoute = to.meta ? to.meta.roles : [];

  return (
    requiredRolesForRoute.length === 0 ||
    TokenStorage.hasRequiredRole(requiredRolesForRoute)
  );
}

export function removeTrackingForStaff(): void {
  if (TokenStorage.hasRequiredRole([STAFF])) {
    if (window && window._paq) {
      // informs the matomo backend that tracking consent has been removed
      window._paq.push(["forgetConsentGiven"]);
      // removes cookie consent and disables tracking cookies
      window._paq.push(["forgetCookieConsentGiven"]);
      window._paq.push(["disableCookies"]);
      // removes matomo javascript track reference
      delete window._paq;
    }
  }
}
