
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../mixin/AssetMixin";

@Component({
  components: { BaseButton },
})
export default class ModuleCard extends mixins(Vue, AssetMixin) {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) listItems!: string;
  @Prop({ required: true }) moduleIsActive!: boolean;
}
