
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import Config from "@/constants/Config";
import { RegisterRoute } from "@/router/Routes";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../mixin/AssetMixin";
import UserMixin from "@/mixin/UserMixin";

@Component({
  components: { BaseButton },
})
export default class TierCard extends mixins(Vue, AssetMixin, UserMixin) {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) listItems!: string;
  @Prop({ required: true }) buttonText!: string;
  @Prop({ required: true }) moduleIsActive!: boolean;
  @Prop({ required: true }) isPremiumCard!: boolean;

  registerRoutePath = RegisterRoute.path;
  contactEmail =
    Config.ContactEmailAddress + "?subject=Pricing for PE Premium Account";
}
