export enum SecurityType {
  UNKNOWN = "UNKNOWN",
  LISTED_EQUITY = "LISTED_EQUITY",
  SOVEREIGN_BOND = "SOVEREIGN_BOND",
}

export const SECURITIES_TYPE_MAP: Record<string, string> = {
  [SecurityType.UNKNOWN]: "Unknown",
  [SecurityType.LISTED_EQUITY]: "Listed Equity",
  [SecurityType.SOVEREIGN_BOND]: "Sovereign Bond",
};
