import Security from "@/models/security/Security";
import { NACE_CODE_TO_SECTOR_NAME_MAP } from "@/constants/NaceCode";

import { groupBy, round } from "lodash";

export default class PortfolioSectorBreakdown {
  static readonly groupSecuritiesBy = (
    securities: Array<Security>,
    key: string,
    formatter?: (
      groupKey: string | number | boolean
    ) => string | number | boolean
  ): Record<string, Array<Security>> => {
    return formatter
      ? groupBy(securities, (security: Security) =>
          formatter(security.naceCode)
        )
      : groupBy(securities, key);
  };

  static readonly getGroupedSecuritiesValues = (
    groupedSecurities: Record<string, Array<Security>>
  ): Record<string, number> => {
    const groupedSecuritiesValues: Record<string, number> = {};
    Object.entries(groupedSecurities).forEach((entry) => {
      const [key, securities] = entry;
      groupedSecuritiesValues[key] = securities.reduce(
        (initial: number, next) => initial + next.marketValueUSD,
        0
      );
    });

    return groupedSecuritiesValues;
  };

  static readonly getSegments = (
    groupedSecurities: Record<string, Array<Security>>,
    getGroupedSecuritiesValues: Record<string, number>
  ): Array<Record<string, any>> => {
    const totalMarketValueUSD: number = Object.values(
      getGroupedSecuritiesValues
    ).reduce((initial, current) => initial + current, 0);

    return Object.entries(groupedSecurities).map(
      ([twoDigitsNaceCode, securities]) => {
        // segment width computation
        const marketValueUSD = getGroupedSecuritiesValues[twoDigitsNaceCode];
        const segmentWidth = round(marketValueUSD / totalMarketValueUSD, 2);

        return {
          naceSector: NACE_CODE_TO_SECTOR_NAME_MAP[twoDigitsNaceCode],
          naceSecuritiesLength: securities.length,
          twoDigitsNaceCode,
          y: segmentWidth,
        };
      }
    );
  };
}
