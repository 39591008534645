import Security from "@/models/security/Security";
import { XDC } from "@/models/XDC";
import { Status } from "@/enums/Status";
import { ErrorMessage } from "@/enums/ErrorMessage";
import { SecurityType } from "@/models/security/SecurityType";

export default class UnknownSecurity extends Security {
  constructor(
    isin = "",
    companyName = "",
    naceCode = "",
    naceSector = "",
    baselineXDC = new XDC(),
    marketValue = NaN,
    marketValueUSD = NaN,
    currency = "",
    scope1Method = "",
    scope2MarketBasedMethod = "",
    scope3Categories = "",
    status = Status.NONE,
    statusMessage: ErrorMessage = ErrorMessage.NONE
  ) {
    super(
      SecurityType.UNKNOWN,
      isin,
      companyName,
      naceCode,
      naceSector,
      baselineXDC,
      marketValue,
      marketValueUSD,
      currency,
      scope1Method,
      scope2MarketBasedMethod,
      scope3Categories,
      status,
      statusMessage
    );
  }

  static fromJson(jsonData: Record<string, any>): UnknownSecurity {
    const unknownSecurity = new UnknownSecurity();
    unknownSecurity.valuesFromJson(jsonData, SecurityType.UNKNOWN);

    return unknownSecurity;
  }
}
