
import Security from "@/models/security/Security";
import { Component, Prop, Vue } from "vue-property-decorator";
import NumberFormatter from "@/services/NumberFormatter";
import LoadingSpinner from "@/pages/shared/components/LoadingSpinner.vue";
import { Status } from "@/enums/Status";
import { isEmpty } from "lodash";
import {
  SECURITIES_TYPE_MAP,
  SecurityType,
} from "@/models/security/SecurityType";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixin/UserMixin";
import CurrencyDropdown from "@/pages/shared/components/CurrencyDropdown.vue";
import Config from "@/constants/Config";
import VueNumeric from "vue-numeric";
import { ErrorMessage } from "@/enums/ErrorMessage";

@Component({
  components: { CurrencyDropdown, LoadingSpinner, VueNumeric },
})
export default class PortfolioTableRow extends mixins(Vue, UserMixin) {
  @Prop() isSecuritySelected!: boolean;
  @Prop() security!: Security;
  @Prop() securityIndex!: number;

  thousandsSeparator = Config.SEPARATOR_THOUSAND;
  decimalSeparator = Config.SEPARATOR_DECIMAL;
  decimalPrecision = Config.PRECISION_DECIMAL;
  marketValue = 0;
  errorMessageNone = ErrorMessage.NONE;

  currentSelectedMarketValue = this.marketValue;
  currentSelectedCurrency = this.$store.state.currencies.EUR.key;

  private totalScope3Categories = 15;

  get isSecurityLoading(): boolean {
    return this.security.status === Status.LOADING;
  }

  get currencyTitle(): string {
    if (this.security.currency != "") {
      return this.$store.state.currencies[this.security.currency].value;
    }

    return "";
  }

  isDropdownSelected(): boolean {
    return this.isSecuritySelected;
  }

  getScope3Categories(value: string): string {
    return isEmpty(value) ? "N/A" : `${value} / ${this.totalScope3Categories}`;
  }

  getClassName(securityType: SecurityType): string {
    return SECURITIES_TYPE_MAP[securityType];
  }

  formatTemperature(temp: number): string {
    return NumberFormatter.temperature(temp);
  }

  storeLastSelectedMarketValue(currentValue: number): void {
    this.currentSelectedMarketValue = currentValue;
  }

  updateSecurityMarketValue(newValue: number): void {
    const currentSelectedMarketValue = this.currentSelectedMarketValue;

    if (currentSelectedMarketValue !== newValue) {
      if (isNaN(newValue)) {
        this.$store.commit("setErrorMessage", "Please enter a valid number");

        return;
      }

      this.$store.dispatch(
        "updateSecurity",
        Object.assign(this.security, { marketValue: newValue })
      );
      this.currentSelectedMarketValue = newValue;
      this.updateSecurityObject();
    }
  }

  updateSecurityCurrency(newValue: string): void {
    const currentSelectedCurrency = this.currentSelectedCurrency;

    if (currentSelectedCurrency !== newValue) {
      if (newValue === "" || newValue.length != 3) {
        this.$store.commit("setErrorMessage", "Currency dropdown error");

        return;
      }

      const newObject = Object.assign(this.security, { currency: newValue });

      this.$store.dispatch("updateSecurity", newObject);
      this.currentSelectedCurrency = newValue;
      this.updateSecurityObject();
    }
  }

  updateSecurityObject(): void {
    const newObject = this.security;
    this.$store.dispatch("updateSecurityFromBackend", newObject);
  }

  created(): void {
    this.marketValue = 0;
  }
}
