
import { Component, Vue } from "vue-property-decorator";
import { BImg } from "bootstrap-vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../mixin/AssetMixin";
import { RegisterRoute } from "@/router/Routes";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import UserMixin from "@/mixin/UserMixin";

@Component({ components: { BaseButton, BImg } })
export default class TitleImageAndHeadline extends mixins(
  Vue,
  AssetMixin,
  UserMixin
) {
  registerRoutePath = RegisterRoute.path;
}
