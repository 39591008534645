
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../../mixin/AssetMixin";
import { ModalID } from "@/enums/ModalID";
import ActionButton from "@/pages/shared/components/buttons/ActionButton.vue";
import { ActionID } from "@/enums/ActionID";

@Component({
  components: { ActionButton },
})
export default class SecuritiesDeletionAction extends mixins(Vue, AssetMixin) {
  id = ActionID.DELETE_SECURITIES;

  openModal(): void {
    Vue.prototype.$modal.show(ModalID.DELETE_SECURITY);
  }

  get isButtonActive(): boolean {
    return this.$store.getters.selectedSecuritiesCount > 0;
  }
}
