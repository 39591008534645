var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h4',{staticClass:"provider-description"},[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.selectorOptions),function(option){return _c('div',{key:option.code,staticClass:"radio-button-container"},[_c('div',{staticClass:"toggle-selection",on:{"click":function($event){return _vm.toggleSelection(option.code)}}},[_c('BRow',[_c('BCol',{staticClass:"radio-button-text",attrs:{"cols":"3"}},[_c('span',{staticClass:"radio-button-image",class:[
              option.code === _vm.selectorValue ? 'selected' : 'unselected',
            ]}),_c('span',{staticClass:"radio-button-key bold p1-style",class:[
              option.code === _vm.selectorValue
                ? 'radio-button-color'
                : 'setColorDefault',
            ]},[_vm._v(_vm._s(option.displayName))])]),_c('BCol',{staticClass:"radio-button-text",attrs:{"cols":"9"}},[_c('span',{staticClass:"radio-button-value p1-style"},[_vm._v(_vm._s(option.fullName))])])],1)],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }