class MatomoTrackHelper {
  trackHandler: any;

  constructor(window: Window) {
    this.trackHandler = window._paq;
  }

  trackButtonClick(eventName: string): void {
    this.trackHandler.push(["trackEvent", "ButtonClick", eventName]);
  }
}

export default MatomoTrackHelper;
