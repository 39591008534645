import InformationGateway from "@/services/InformationGateway";
import { State } from "@/store/main/State";
import { InformationStoreState } from "@/store/information/InformationStoreState";
import Config from "@/constants/Config";

export const getInitialInformationState = (): InformationStoreState => {
  return {
    engineVersion: "-",
  };
};

export const informationState: InformationStoreState =
  getInitialInformationState();

export default {
  namespaced: true,
  state: informationState,
  getters: {},
  mutations: {
    clearState(state: State): void {
      Object.assign(state, getInitialInformationState());
    },
    setEngineVersion(state: InformationStoreState, version: string): void {
      state.engineVersion = version;
    },
  },
  actions: {
    getXDCVersion({ commit }: { commit: any }): void {
      if (Config.DEV_MODE) {
        commit("setEngineVersion", "DEV");
      } else
        InformationGateway.getXDCVersion().then((version: string) =>
          commit("setEngineVersion", version)
        );
    },
  },
};
