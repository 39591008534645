
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CircularProgress extends Vue {
  @Prop({ required: false, default: 20 }) radius!: number;
  @Prop({ required: false, default: 1 }) stroke!: number;

  get normalizedRadius(): number {
    return this.radius - this.stroke * 2;
  }
}
