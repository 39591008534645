
import { Component, Vue } from "vue-property-decorator";
import BaseAccordion from "@/pages/faq/components/BaseAccordion.vue";
import AccordionItem from "@/pages/faq/models/AccordionItem";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import Config from "@/constants/Config";
import { BCol, BIconChevronLeft, BRow } from "bootstrap-vue";
import ChangeLogModal from "@/pages/faq/modals/ChangeLogModal.vue";

@Component({
  components: {
    BaseButton,
    BaseAccordion,
    ChangeLogModal,
    BRow,
    BCol,
    BIconChevronLeft,
  },
})
export default class FAQPage extends Vue {
  contactEmail = Config.ContactEmailAddress;

  methodology = [
    new AccordionItem(
      "Where can I find the methodology?",
      'The methodology can be found on <a href="https://www.right-basedonscience.de/en/xdc-model/" target="_blank">https://www.right-basedonscience.de/en/xdc-model/</a>'
    ),
  ];

  usage = [
    new AccordionItem(
      "How can I upload a portfolio?",
      "A portfolio can be uploaded as a CSV file containing the ISINs, market values and currencies for each entity. Market values are required to calculate accurate portfolio-level XDCs and to assess portfolio alignment. If you are only looking for company-specific information, market values are not needed.<br /> <br />The file should contain the headers: ISIN, Market Value, and Currency, with currency given by its ISO-3 code (e.g., EUR, USD)."
    ),
    new AccordionItem(
      "If I want to change the portfolio, is it necessary to upload a new CSV file?",
      'This is not necessary. You can make changes and adjustments to your portfolio directly in the software.<br /><br />To add a new security, click on the "Add security" button in the top right-hand corner and add the ISIN, market value (optional) and currency (optional). Optional information is needed for portfolio-level results. If you only need security-level information, you can leave the optional sections blank.<br /><br />To delete a security, navigate to the Analytics tab, select the security you wish to delete (it will remain highlighted in purple) and then click "Delete selected security" in the top right-hand corner.<br /> <br />To amend the market value of a security, simply click in the market value selection for a particular security and type in a new value.'
    ),
    new AccordionItem(
      "Can the results be displayed in Excel?",
      "Yes, this is part of our Premium features. Simply go to the box in the top right-hand corner and click on the “Download XLS” button. A single file will be downloaded with the results of the portfolio and the securities for different scenarios. This will be shown on different tabs in the Excel file."
    ),
    new AccordionItem(
      "What asset classes are covered? Are bonds included?",
      "Our database currently covers publicly traded equities and sovereign bonds. However, the XDC value for an individual company&quot;s equity and bond is the same. To include bonds, substitute the bond ISIN for that same company&quot;s equity ISIN, using the bond&quot;s book value as the market value.<br /><br />XDC values for other asset classes such as private equity are also available on request." +
        '<br />Contact us at <a href="' +
        this.contactEmail +
        '?subject=Other assets request"  target="_blank">PE-support@right-basedonscience.de</a> to request pricing.'
    ),
    new AccordionItem(
      'What information is found in "What’s New"?',
      "Here you can see changes that occur in either the XDC Model or in the XDC Portfolio Explorer software. This allows you to be informed about any exciting new changes that will be made in the future."
    ),
    new AccordionItem(
      "How secure is my data? How is the portfolio data that I upload or manually enter stored? Can it be accessed by right° or any other party?",
      "We understand how valuable your portfolio data is. None of the data you upload is saved by XDC Portfolio Explorer (which is why we recommend regularly downloading a CSV for your own use) or by right°. Whether you upload your portfolio data via CSV or manually add / remove / change securities and portfolio weights, none of the information that you input is saved in any manner. It 'lives' only in your computer.<br /><br />However, for calculation of the XDC values the data is transmitted to the XDC Model. We send all data using a secure HTTP channel using SSL (Secure Sockets Layer) encryption, which protects against malicious attacks trying to 'listen' to the communication. We take great care to transmit only the data that is required and not log or save any of your information, in order to maintain the confidentiality that your data deserves."
    ),
  ];

  features = [
    new AccordionItem(
      "What do the percentages aligned/not aligned refer to in Overview?",
      "This is the market value percentage of aligned/not aligned securities in the portfolio. The weight of each security is calculated by converting the currency for all securities into a common currency and dividing the market value by the value of the portfolio."
    ),
    new AccordionItem(
      "What does the Sector Breakdown chart tell me?",
      "If you scroll on the pie chart, you can see the individual NACE code and the number of assets of that sector in your portfolio."
    ),
    new AccordionItem(
      "What are the criteria for Best and Worst Securities in the Overview?",
      "These are the companies that have the lowest/highest Baseline XDC, and thereby the best/worst climate performance in your portfolio. Please note: Portfolio weights are not considered here, so these securities may or may not have a pronounced effect on your overall portfolio results."
    ),
  ];

  concepts = [
    new AccordionItem(
      "What is the emission intensity (EI) of an entity?",
      "The emission intensity of an entity is the ratio of emissions to a particular factor, depending on the asset class.<br><br>" +
        "Companies (private and listed equities, bonds): emissions / gross value added (GVA).<br><br>" +
        "Sovereign bonds: emissions / capita (population).<br><br>" +
        "Real estate: emissions / sqm."
    ),
    new AccordionItem(
      "What benchmarks / reference pathways are used for the calculation of the Baseline XDC? What is the climate performance and how is it calculated?",
      "To determine the climate performance of a company, its projected baseline EI pathway from the base year until 2100 is compared to various sector-specific benchmarks or reference pathways.<br> <br>" +
        "These are: <br><br>" +
        "<ul>" +
        "<li>1) The projected baseline EI pathway for the median of the company’s own sector.</li><br>" +
        "<li>2) The target EI pathways for the company’s own sector derived from climate change mitigation scenarios. As a user you can choose one of the following three options:</li><br>" +
        "<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. International Energy Agency (IEA) 1.5°C, 1.75°C and 2°C scenarios; </li>" +
        "<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. Network for Greening the Financial System (NGFS) 1.5°C and 2°C scenarios;</li>" +
        "<li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. One Earth Climate Model (OECM) 1.5°C and 2°C scenarios.</li>" +
        "</ul>"
    ),
    new AccordionItem(
      "Are XDCs sector specific?",
      "Yes and no. To calculate an XDC, the entity’s EI pathway is compared to benchmarks / reference pathways specific to the NACE sector in which it belongs (see above). This determines the entity’s climate performance and ensures that in the calculation banks are compared to banks and mining companies are compared to mining companies. However, this climate performance is then mapped to global emissions, before calculating the resulting temperature. By focusing on the question “what if the whole world performed like this entity?”, XDCs for different entities and from different sectors become comparable."
    ),
    new AccordionItem(
      "How are the globally upscaled emissions calculated?",
      "Once the annual climate performance of an entity in relation to the reference pathways has been calculated, this same performance is mapped to the world, i.e. “what if the world performed in the same way related to the global emission pathways for 1.5°C / 1.75°C / 2°C global warming?” From this new “world performing as the entity”-pathway, a quantity of emissions over time can be derived and input into a climate model. The result is the degree of global warming if the entire world exhibited the same climate performance as the entity in question."
    ),
    new AccordionItem(
      "What is Paris Alignment?",
      "The Paris Agreement of 2015 specifies a goal of global warming ‘well below 2°C’, ideally no more than 1.5°C above pre-industrial levels by 2100.<br><br>" +
        "Therefore, Paris Alignment can be claimed only for XDCs referring to the target year 2100. While ‘well below 2°C’ is not specified exactly, we follow the temperature limit of the International Energy Agency “Beyond 2 Degrees Scenario” (B2DS), which corresponds to a 50% likelihood of keeping global warming below 1.75°C. <br><br>" +
        "An XDC of 1.4°C 2040 is not Paris-aligned.<br><br>" +
        "An XDC of 1.8°C in 2100 is not Paris-aligned.<br><br>" +
        "An XDC of 1.7°C in 2100 is Paris-aligned. <br><br>" +
        "Users of XDC Portfolio Explorer can adjust the mitigation scenarios (IEA; NGFS; OECM) as well as the timeframe for analysis. Results will then be displayed as “aligned” to these particular settings. However, they will not be marked as Paris-aligned. "
    ),
    new AccordionItem(
      "How are portfolio-level XDCs calculated?",
      "First, we start by calculating the attribution factor of each security. The attribution factor is the amount of emissions owned by the investor for a particular security. This is calculated as the sum of equity owned and the book value of debt for a given entity in the portfolio divided by the sum of the market capitalization plus the book value of debt for the entire entity. In other words, if you own 1% of the equity and debt of an entity, you “own” 1% of the entity’s emissions. <br><br>" +
        "This calculation is done for every security in the portfolio to determine the entire amount of “owned” emissions and this is compared to the appropriate sector benchmarks, similar to what is done at the security level. "
    ),
    new AccordionItem(
      "Why does the XDC Portfolio Explorer use market values instead of weights?",
      'This is to follow a recommendation from the TCFD, which aims at the amount of emissions “owned” by an investor, as described above. Source: <a href="https://www.tcfdhub.org/wp-content/uploads/2021/10/PAT_Measuring_Portfolio_Alignment_Technical_Considerations.pdf" target="_blank">PAT_Measuring_Portfolio_Alignment_Technical_Considerations.pdf (tcfdhub.org)'
    ),

    new AccordionItem(
      "Why do we use book value for bonds instead of market value?",
      "Following the recommendation by the TCFD, book value (the amount owed by an entity) is to be used to determine the amount of emissions attributable to one’s portfolio. Using the market value will likely be close to the book value but would result in the Portfolio Baseline XDC being incorrect."
    ),

    new AccordionItem(
      "How can the XDC Portfolio Explorer be used to steer my portfolio towards alignment?",
      "There are three ways to steer a portfolio towards alignment: <br><br>" +
        "a. Removing non-aligned securities from the portfolio. Selling all non-aligned securities from your portfolio would leave you with only aligned securities. As a result, the portfolio itself would also be aligned. <br><br>" +
        "b. Adding aligned securities to the portfolio. By purchasing securities that are aligned, this will help decrease the Baseline XDC of the portfolio, bringing the entire portfolio closer to alignment – effectively balancing out the non-aligned holdings. <br><br>" +
        "c. Changing the market values of the securities in your portfolio. If you do not want to fully divest from non-aligned securities, selling portions of non-aligned securities and purchasing / increasing aligned securities will bring the portfolio closer to alignment. "
    ),
    new AccordionItem(
      "Can I compare the portfolio’s performance to another portfolio?",
      "Of course!  After you’ve uploaded your portfolio, you can also upload another portfolio or the fund’s benchmark to directly see which has a better climate performance."
    ),

    new AccordionItem(
      "Why is the Portfolio Baseline XDC not a weighted average of the Baseline XDCs of the portfolio?",
      "This is because the XDC Model reflects the non-linearity of the climate system. Doubling emissions does not correspond to doubling temperatures. Rather than averaging all security-level XDCs in a portfolio, the portfolio is itself treated as an entity (like a company) with its ‘owned’ share of emissions (see above <strong>“How are portfolio-level XDCs calculated?”).</strong> "
    ),
    new AccordionItem(
      "How are emissions scopes counted? How do you deal with double-counting of emissions?",
      "Since emissions in Scopes 2 and 3 for one entity are also part of the Scope 1 emissions for another entity (e.g. the energy provider) and since other entities also create GVA with these emissions, there is a risk of double-counting. To account for this, the XDC calculations use 100% of the Scope 1 emissions, but only 50% each for Scopes 2 and 3. <br><br>" +
        "For sovereign bonds, the inputs are a country’s emissions as a whole and are not divided into Scopes 1, 2, and 3. As such, 100% of the emissions are always used."
    ),
    new AccordionItem(
      "How do you deal with data gaps, uncertainty, and lacking standardization of data - especially Scope 3 emissions data?",
      "We work with data from a third-party data provider who fills data gaps when reporting is incomplete. When we receive the data, we run additional plausibility checks ourselves. Data that companies submit to us is benchmarked against sector averages to make sure we spot gaps and ensure reliability. For any company, you can see whether the scope data was self-reported or modelled. Specifically for Scope 3 data, you see how many subcategories (out of 15) were reported. <br><br>" +
        "Our XDC Model team is currently working on uncertainty quantification to eventually make data from different sources comparable."
    ),
    new AccordionItem(
      "What are the SSPs and how are they used for Baseline XDC calculation?",
      'The Shared Socioeconomic Pathways (SSPs) are a set of five scenarios that "describe plausible major global developments that together would lead in the future to different challenges for mitigation and adaptation to climate change." (Source: UNECE) One of these scenarios, SSP2, is described as the "Middle of the Road" or "Current Trends Continue" pathway. It is used for our baseline projections of the company\'s GVA and emission development as well as for the global GVA development. <br><br>' +
        "Source: (2017) Riahi, K. / van Vuuren, D. et al. The Shared Socioeconomic Pathways and their energy, land use, and greenhouse gas emissions implications: An overview. In: Global Environmental Change, Volume 42, pp. 153-168."
    ),
    new AccordionItem(
      "What are the NACE sectors?",
      "The “Nomenclature statistique des Activités économiques dans la Communauté Européenne” (NACE) is the European standard system for classifying industries. It applies four levels (sections - identified by letters A-U; divisions, groups, and classes - each identified by 2-, 3- or 4-digit numbers, respectively). An overview of all NACE classifications can be found on the" +
        '<a href="https://ec.europa.eu/eurostat/ramon/nomenclatures/index.cfm?TargetUrl=LST_NOM_DTL&StrNom=NACE_REV2&StrLanguageCode=EN&IntPcKey=&StrLayoutCode=HIERARCHIC&IntCurrentPage=1" target="_blank"> Eurostat "Reference And Management Of Nomenclatures" </a>'
    ),
  ];

  sovereignBonds = [
    new AccordionItem(
      "What inputs are used in the calculation of sovereign bonds?",
      "The inputs of sovereign bonds differ from that of listed equity. Listed equity uses the emissions at scope level and the GVA (which is the sum of EBITDA and personnel costs). Sovereign bonds, however, look at the emissions and population within the country."
    ),
    new AccordionItem(
      "Why do sovereign bonds not have XDCs for each scope like listed equity?",
      "Sovereign bonds emissions are provided in aggregate, they are not split out by scope, as with listed equity. This means that we can have a total Baseline XDC, but a Scope 1/2/3 XDC cannot be calculated."
    ),
    new AccordionItem(
      "Are all sovereign bonds for a particular country the same regardless of their characteristics?",
      "Yes. If my portfolio contains two sovereign bonds for the same country with different maturity dates, the Baseline and Target XDCs will remain the same. The characteristics of the sovereign bond have no effect on the resulting XDCs. In other words, a German bond set to mature tomorrow will have the same XDC as one set to mature 30 years from today."
    ),
  ];

  settings = [
    new AccordionItem(
      "Base Year",
      "With the base year selection, users can choose which year will be used for the selection of emission and financial data of the securities and subsequent calculation of the Baseline XDC. The most up to date emissions and financial data will be the most recent year available as the default."
    ),
    new AccordionItem(
      "End year",
      "Users can select the timeframe for XDC calculation, beginning in a base year and ending in any given year until 2100. However, Paris Alignment refers exclusively to the target year 2100."
    ),
    new AccordionItem(
      "SSP",
      "The SSP (Shared Socioeconomic Pathways) selection allow you to choose which of the SSP scenarios will be applied during the calculation of the Baseline XDC. Each SSP has its own growth assumptions regarding emissions, GVA, and population. The SSPs are a set of 5 scenarios that outline alternative societal trends until 2100."
    ),
    new AccordionItem(
      "Provider",
      "We currently have 3 providers at disposal: IEA, NGFS and OECM. The providers bring different scenarios assumptions and narratives and thus lead to some variation on the results due to their different interpretations of the conditions. "
    ),
  ];

  troubleshooting = [
    new AccordionItem(
      "Why is there no Baseline XDC for a particular security?",
      "There are a few possible reasons why a security may not have a Baseline XDC: <br><br>" +
        "a. If the security is a bond, it will not be included in our database. However, the XDC value for an individual company's equity and bond is the same. To include bonds, substitute the bond ISIN for that same company's equity ISIN.<br><br>" +
        "b. If a company is in the agricultural sector (NACE 1-3), we cannot calculate a Baseline XDC for it at the present time. The non-industrial emissions in agriculture (such as methane) require an adjusted methodological approach. This is currently in development. <br><br>" +
        'c. We do not currently have the security in our database. In such cases, we need to order emission data for the company in question from our data provider, Urgentem. Please contact us at <a href="' +
        this.contactEmail +
        '?subject=Security Request"  target="_blank">PE-support@right-basedonscience.de</a> to create a request <br><br>' +
        "d. The company has not reported the necessary financial information needed to calculate the GVA. This could be because they did not publish any data for the base year, or they chose not to disclose their personnel costs. If financial information is either missing or incomplete, then no Baseline XDC can be calculated. <br><br>" +
        "e. The company has a negative GVA. The first step in calculating a Baseline XDC is the calculation of the emissions intensity (EI). This is as efficiency measure, how many tons of CO2e are needed to generate €1 million. If this figure is negative, the EI loses all practical meaning."
    ),

    new AccordionItem(
      "Why have the portfolio-level XDCs disappeared?",
      'If you make a change to the portfolio, such as editing the weight or the constituents, the portfolio-level XDCs will automatically disappear, since they no longer accurately convey the updated information. To refresh the portfolio calculation with the changes, simply click on the "Calculate" button in the bottom left-hand corner.'
    ),
  ];

  navigateBack(): void {
    this.$router.go(-1);
  }

  downloadTemplate(type: string): void {
    if (Config.DEV_MODE) {
      alert("Not available in DEV_MODE");

      return;
    }

    const anchor = document.createElement("a");

    if (type === "CsvWIthMarketValue") {
      anchor.setAttribute("href", Config.PEMarketValueCSVTemplateLink);
      anchor.setAttribute("target", "_blank");
      anchor.setAttribute("download", `pe_sample_file_with_market_value.csv`);
    } else {
      anchor.setAttribute("href", Config.PECSVTemplateLink);
      anchor.setAttribute("target", "_blank");
      anchor.setAttribute("download", `pe_sample_file.csv`);
    }

    anchor.click();
  }
}
