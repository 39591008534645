import { AbbreviationName } from "@/models/AbbreviationName";

export class SSP implements AbbreviationName {
  public static readonly SSP1 = new SSP(
    "SSP1",
    "SSP1_BASELINE",
    "Sustainability (Taking the Green Road)"
  );
  public static readonly SSP2 = new SSP(
    "SSP2",
    "SSP2_BASELINE",
    "Middle of the Road (Baseline)"
  );
  public static readonly SSP2_RCP60 = new SSP(
    "SSP2 RCP60",
    "SSP2_RCP60",
    "Middle of the Road (RCP60)"
  );
  public static readonly SSP3 = new SSP(
    "SSP3",
    "SSP3_BASELINE",
    "Regional Rivalry (A Rocky Road)"
  );
  public static readonly SSP4 = new SSP(
    "SSP4",
    "SSP4_BASELINE",
    "A Road divided"
  );
  public static readonly SSP5 = new SSP(
    "SSP5",
    "SSP5_BASELINE",
    "Fossil-fueled Development"
  );

  private constructor(
    public readonly displayName: string,
    public readonly code: string,
    public readonly fullName: string
  ) {}
}
