
import { Component, Prop, Vue } from "vue-property-decorator";
import PageButton from "@/pages/shared/components/buttons/PageButton.vue";
import NavDropdownMenu from "@/pages/main/components/header/NavDropdownMenu.vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";
import { AnalyticsRoute, OverviewRoute } from "@/router/Routes";
import UserMixin from "@/mixin/UserMixin";

@Component({
  components: { NavDropdownMenu, PageButton },
})
export default class Sidebar extends mixins(Vue, AssetMixin, UserMixin) {
  @Prop({ required: false, default: false }) compactMode!: boolean;

  overviewRoutePath = OverviewRoute.path;
  analyticsRoutePath = AnalyticsRoute.path;

  get currentPath(): string {
    return this.$route.path;
  }
}
