import { Component, Vue } from "vue-property-decorator";

@Component
export default class AssetMixin extends Vue {
  imageUrl(image: string): string {
    const imageDictionary: Record<string, string> = {
      add: "addcomp_btn.svg",
      analytics: "analytics_btn.svg",
      contact: "contact_btn.svg",
      delete: "del_btn.svg",
      downloadCSV: "down_csv_btn.svg",
      downloadExcel: "down_excel_btn.svg",
      hidePwd: "hide-pw_btn.svg",
      login: "login_btn.svg",
      logout: "logout_btn.svg",
      new: "newport_btn.svg",
      overview: "overview_btn.svg",
      right: "right_logo.svg",
      showPwd: "show-pw_btn.svg",
      sectorBreakdownChartBackground: "sector_scale.svg",
      upload: "up_csv_btn.svg",
      menu: "menu_icon.svg",
      profile: "profile_icon.svg",
      closeButton: "close_btn.svg",
      faq: "faq_btn.svg",
      websiteIcon: "website_btn.svg",
      linkedinIcon: "linkedin_btn.svg",
      twitterIcon: "twitter_btn.svg",
      upgrade: "upgrade.svg",
      letsTalk: "lets-talk_icon.svg",
      check: "check_btn.svg",
      settingsIcon: "settings_icon.svg",
      checkmarkGreen: "icon_checkmark.png",
      bell: "changelog_icon.svg",
      // Dropdown
      dropdown: "arrow_down.svg",
    };

    // Require context causes issues with jest and testing
    // We use require instead.
    // See: https://github.com/facebook/create-react-app/issues/517
    // const images = require.context('../assets/')
    return require(`@/assets/icons/${imageDictionary[image]}`);
  }

  changeLogUrl(): any {
    const changeLogFileName = "CHANGELOG.md";

    return require(`@/assets/changelogs/${changeLogFileName}`);
  }
}
