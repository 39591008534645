
import { Component, Vue } from "vue-property-decorator";
import PageButton from "@/pages/shared/components/buttons/PageButton.vue";
import AlignedSecuritiesBreakdownCard from "@/pages/overview/components/AlignedSecuritiesBreakdownCard.vue";
import PortfolioSectorBreakdownCard from "@/pages/overview/components/PortfolioSectorBreakdownCard.vue";
import TopSecuritiesCard from "@/pages/overview/components/TopSecuritiesCard.vue";
import { BCol, BRow } from "bootstrap-vue";
import NoDataCard from "@/pages/shared/components/NoDataCard.vue";
import MapCard from "@/pages/overview/components/MapCard.vue";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixin/UserMixin";
import CurrenciesMixin from "@/mixin/CurrenciesMixin";

@Component({
  components: {
    AlignedSecuritiesBreakdownCard,
    PortfolioSectorBreakdownCard,
    TopSecuritiesCard,
    MapCard,
    PageButton,
    NoDataCard,
    BCol,
    BRow,
  },
})
export default class OverviewPage extends mixins(
  Vue,
  UserMixin,
  CurrenciesMixin
) {
  get isDataPresent(): boolean {
    return this.$store.getters.totalSecuritiesCount > 0;
  }

  created(): void {
    this.getCurrencies();
  }
}
