
import { Component, Vue } from "vue-property-decorator";
import Security from "@/models/security/Security";
import BaseCard from "@/pages/overview/components/base/BaseCard.vue";
import { mixins } from "vue-class-component";
import SecurityHelperMixin from "@/mixin/SecurityHelperMixin";
import { isNaN } from "lodash";

@Component({
  components: { BaseCard },
})
export default class TopSecuritiesCard extends mixins(
  Vue,
  SecurityHelperMixin
) {
  private showWorst = false;
  private numberOfTopSecurities = 10;

  created(): void {
    this.changeNumberOfTopSecuritiesToDisplay();
    window.addEventListener(
      "resize",
      this.changeNumberOfTopSecuritiesToDisplay
    );
  }

  changeNumberOfTopSecuritiesToDisplay(): void {
    if (window.innerWidth >= 1560) {
      this.numberOfTopSecurities = 10;
    } else {
      this.numberOfTopSecurities = 5;
    }
  }

  get topSecurities(): Array<Security> {
    return this.validSecurities
      .sort(this.sortFunction(this.showWorst))
      .filter((security) => this.getBaselineXDCTotal(security))
      .slice(0, this.numberOfTopSecurities);
  }

  // TODO New Metric should be replaced by the right value
  getBaselineXDCTotal(security: Security): number {
    return security.baselineXDC.total;
  }

  sortFunction(
    showWorst: boolean
  ): (current: Security, next: Security) => number {
    return (current: Security, next: Security) => {
      return this.sortSecurity(current, next, showWorst);
    };
  }

  sortSecurity(
    current: Security,
    next: Security,
    sortWorstFirst: boolean
  ): number {
    const currentSecurityXDC = this.getBaselineXDCTotal(current);
    const nextSecurityXDC = this.getBaselineXDCTotal(next);

    if (isNaN(currentSecurityXDC)) {
      return -1;
    }

    if (isNaN(nextSecurityXDC)) {
      return 1;
    }

    if (currentSecurityXDC === nextSecurityXDC) {
      return this.compare(
        current.marketValue,
        next.marketValue,
        sortWorstFirst
      );
    }

    return this.compare(currentSecurityXDC, nextSecurityXDC, sortWorstFirst);
  }

  // this function sorts undefined to the bottom, no matter the sorting order
  compare(a: number, b: number, sortWorstFirst: boolean): number {
    if (sortWorstFirst) {
      return b - a;
    } else {
      return a - b;
    }
  }

  destroyed(): void {
    window.removeEventListener(
      "resize",
      this.changeNumberOfTopSecuritiesToDisplay
    );
  }
}
