
import { Component, Vue } from "vue-property-decorator";
import PortfolioTable from "@/pages/analytics/components/table/PortfolioTable.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BRow } from "bootstrap-vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../mixin/AssetMixin";
import Security from "@/models/security/Security";
import NoDataCard from "@/pages/shared/components/NoDataCard.vue";
import SecurityHelperMixin from "@/mixin/SecurityHelperMixin";
import CurrenciesMixin from "@/mixin/CurrenciesMixin";
import { MetricEventType } from "@/enums/MetricEventType";
import MetricsMixin from "@/mixin/MetricsMixin";

@Component({
  components: {
    BaseButton,
    PortfolioTable,
    NoDataCard,
    BRow,
  },
})
export default class AnalyticsPage extends mixins(
  Vue,
  AssetMixin,
  SecurityHelperMixin,
  CurrenciesMixin,
  MetricsMixin
) {
  get isDataPresent(): boolean {
    return this.$store.getters.totalSecuritiesCount > 0;
  }

  get portfolioName(): string {
    return this.$store.state.portfolioName;
  }

  get needsRecalculation(): boolean {
    return this.$store.state.needsRecalculation;
  }

  get isMarketValueZero(): boolean {
    let totalMarketValue = 0;

    this.validSecurities.forEach((security) => {
      totalMarketValue += security.marketValue;
    });

    return totalMarketValue === 0;
  }

  get noValidSecuritiesAvailable(): boolean {
    const validSecurities = this.validSecurities.filter(function (
      security: Security
    ) {
      return !security.hasError();
    });

    return validSecurities.length === 0;
  }

  get singleOrMultipleSecuritiesSuffix(): string {
    return this.validSecurities.length === 1 ? "" : "s";
  }

  calculatePortfolioValues(): void {
    this.$store.dispatch("calculatePortfolioValues");
    this.sendMetric(MetricEventType.CLICK_CALCULATE_PORTFOLIO_BUTTON);
  }

  created(): void {
    this.getCurrencies();
  }
}
