export class SectorInformation {
  twoDigitsNaceCode: number;
  naceSector: string;
  naceSecuritiesLength: number;

  constructor(
    twoDigitsNaceCode = NaN,
    naceSector = "",
    naceSecuritiesLength = NaN
  ) {
    this.twoDigitsNaceCode = twoDigitsNaceCode;
    this.naceSector = naceSector;
    this.naceSecuritiesLength = naceSecuritiesLength;
  }
}
