import AuthGateway from "@/auth/services/AuthGateway";
import Config from "@/constants/Config";
import { RestType } from "@/auth/config/RestType";
import DevModeGateway from "@/services/DevModeGateway";

export default class CurrencyGateway {
  static CURRENCY_GATEWAY_BASE_URL = Config.ApiGatewayUrl + "/api/v1";

  static getCurrencies(): Promise<string> {
    let backEndResponse;

    if (Config.DEV_MODE) {
      backEndResponse = DevModeGateway.getCurrencies();
    } else {
      backEndResponse = AuthGateway.sendRequestWithAuth(
        this.CURRENCY_GATEWAY_BASE_URL,
        RestType.GET,
        "/currencies",
        {},
        {}
      );
    }

    return backEndResponse
      .then((response: any) => {
        if (response === undefined) {
          return "";
        }

        return response.data as string;
      })
      .catch(() => {
        return "";
      });
  }
}
