
import { Component, Vue } from "vue-property-decorator";
import ActionButton from "@/pages/shared/components/buttons/ActionButton.vue";
import Security from "@/models/security/Security";
import PortfolioValues from "@/models/PortfolioValues";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";
import UserMixin from "@/mixin/UserMixin";
import { ModalID } from "@/enums/ModalID";
import Excel from "exceljs";
import { ExcelExporter } from "@/services/excel/ExcelExporter";
import { ActionID } from "@/enums/ActionID";
import MetricsMixin from "@/mixin/MetricsMixin";
import { MetricEventType } from "@/enums/MetricEventType";

@Component({
  components: { ActionButton },
})
export default class ExcelExportAction extends mixins(
  Vue,
  AssetMixin,
  UserMixin,
  MetricsMixin
) {
  id = ActionID.EXCEL_EXPORT;

  get isDisabled(): boolean {
    const importingBulkData = this.$store.state.importingBulkData;
    const securityCount = this.$store.getters.totalSecuritiesCount;

    // download button is inactive, if importing OR if no securities available
    return importingBulkData || securityCount === 0;
  }

  downloadExcel(): void {
    if (this.isUserPremiumTier) {
      this.downloadXLSX();
      this.sendMetric(MetricEventType.CLICK_EXCEL_DOWNLOAD);
    } else {
      Vue.prototype.$modal.show(ModalID.UPGRADE_MODAL);
    }
  }

  private async downloadXLSX(): Promise<void> {
    const validSecurities = this.$store.state.validSecurities;
    const invalidSecurities = this.$store.state.invalidSecurities;
    const baseYear = this.$store.state.baseYear;
    const targetYear = this.$store.state.targetYear;
    const provider = this.$store.state.provider;
    const sspCode = this.$store.state.sspCode;

    const excelExporter = new ExcelExporter(
      baseYear,
      targetYear,
      provider,
      sspCode
    );

    const workbook = await this.createWorkbook(
      excelExporter,
      validSecurities,
      invalidSecurities
    );

    const data = await workbook.xlsx.writeBuffer();
    const portfolioName = this.$store.state.portfolioName;
    excelExporter.executeDownload(data, portfolioName);
  }

  async createWorkbook(
    excelExporter: ExcelExporter,
    validSecurities: Security[],
    invalidSecurities: Security[]
  ): Promise<Excel.Workbook> {
    const currentPortfolioValues: PortfolioValues =
      this.$store.state.portfolioValues;

    const exportSecurities: Security[] =
      validSecurities.concat(invalidSecurities);

    return await excelExporter.createExcelExport(
      exportSecurities,
      currentPortfolioValues
    );
  }
}
