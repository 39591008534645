import { render, staticRenderFns } from "./TopSecuritiesCard.vue?vue&type=template&id=c289ed52&scoped=true&"
import script from "./TopSecuritiesCard.vue?vue&type=script&lang=ts&"
export * from "./TopSecuritiesCard.vue?vue&type=script&lang=ts&"
import style0 from "./TopSecuritiesCard.vue?vue&type=style&index=0&id=c289ed52&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c289ed52",
  null
  
)

export default component.exports