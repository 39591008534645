import Currency from "@/enums/Currency";

export const exampleTopFiveCurrencyCode: Record<string, Currency> = {
  EUR: {
    key: "EUR",
    value: "Euro",
    isTopFive: true,
  },
  CNY: {
    key: "CNY",
    value: "Chinese yuan",
    isTopFive: true,
  },
  GBP: {
    key: "GBP",
    value: "Pound sterling",
    isTopFive: true,
  },
  JPY: {
    key: "JPY",
    value: "Japanese yen",
    isTopFive: true,
  },
  USD: {
    key: "USD",
    value: "United States dollar",
    isTopFive: true,
  },
};
export const exampleCurrencyCodes: Record<string, Currency> = {
  AED: {
    key: "AED",
    value: "United Arab Emirates dirham",
    isTopFive: false,
  },
  AFN: {
    key: "AFN",
    value: "Afghan afghani",
    isTopFive: false,
  },
  ALL: {
    key: "ALL",
    value: "Albanian lek",
    isTopFive: false,
  },
  AMD: {
    key: "AMD",
    value: "Armenian dram",
    isTopFive: false,
  },
  ANG: {
    key: "ANG",
    value: "Netherlands Antillean guilder",
    isTopFive: false,
  },
  AOA: {
    key: "AOA",
    value: "Angolan kwanza",
    isTopFive: false,
  },
  ARS: {
    key: "ARS",
    value: "Argentine peso",
    isTopFive: false,
  },
  AUD: {
    key: "AUD",
    value: "Australian dollar",
    isTopFive: false,
  },
  AWG: {
    key: "AWG",
    value: "Aruban florin",
    isTopFive: false,
  },
  AZN: {
    key: "AZN",
    value: "Azerbaijani manat",
    isTopFive: false,
  },
  BAM: {
    key: "BAM",
    value: "Bosnia and Herzegovina convertible mark",
    isTopFive: false,
  },
  BBD: {
    key: "BBD",
    value: "Barbados dollar",
    isTopFive: false,
  },
  BDT: {
    key: "BDT",
    value: "Bangladeshi taka",
    isTopFive: false,
  },
  BGN: {
    key: "BGN",
    value: "Bulgarian lev",
    isTopFive: false,
  },
  BHD: {
    key: "BHD",
    value: "Bahraini dinar",
    isTopFive: false,
  },
  BIF: {
    key: "BIF",
    value: "Burundian franc",
    isTopFive: false,
  },
  BMD: {
    key: "BMD",
    value: "Bermudian dollar",
    isTopFive: false,
  },
  BND: {
    key: "BND",
    value: "Brunei dollar",
    isTopFive: false,
  },
  BOB: {
    key: "BOB",
    value: "Boliviano",
    isTopFive: false,
  },
  BOV: {
    key: "BOV",
    value: "Bolivian Mvdol (funds code)",
    isTopFive: false,
  },
  BRL: {
    key: "BRL",
    value: "Brazilian real",
    isTopFive: false,
  },
  BSD: {
    key: "BSD",
    value: "Bahamian dollar",
    isTopFive: false,
  },
  BTN: {
    key: "BTN",
    value: "Bhutanese ngultrum",
    isTopFive: false,
  },
  BWP: {
    key: "BWP",
    value: "Botswana pula",
    isTopFive: false,
  },
  BYN: {
    key: "BYN",
    value: "Belarusian ruble",
    isTopFive: false,
  },
  BZD: {
    key: "BZD",
    value: "Belize dollar",
    isTopFive: false,
  },
  CAD: {
    key: "CAD",
    value: "Canadian dollar",
    isTopFive: false,
  },
  CDF: {
    key: "CDF",
    value: "Congolese franc",
    isTopFive: false,
  },
  CHE: {
    key: "CHE",
    value: "WIR euro (complementary currency)",
    isTopFive: false,
  },
  CHF: {
    key: "CHF",
    value: "Swiss franc",
    isTopFive: false,
  },
  CHW: {
    key: "CHW",
    value: "WIR franc (complementary currency)",
    isTopFive: false,
  },
  CLF: {
    key: "CLF",
    value: "Unidad de Fomento (funds code)",
    isTopFive: false,
  },
  CLP: {
    key: "CLP",
    value: "Chilean peso",
    isTopFive: false,
  },
  CNY: {
    key: "CNY",
    value: "Chinese yuan",
    isTopFive: true,
  },
  COP: {
    key: "COP",
    value: "Colombian peso",
    isTopFive: false,
  },
  COU: {
    key: "COU",
    value: "Unidad de Valor Real (UVR) (funds code)",
    isTopFive: false,
  },
  CRC: {
    key: "CRC",
    value: "Costa Rican colon",
    isTopFive: false,
  },
  CUC: {
    key: "CUC",
    value: "Cuban convertible peso",
    isTopFive: false,
  },
  CUP: {
    key: "CUP",
    value: "Cuban peso",
    isTopFive: false,
  },
  CVE: {
    key: "CVE",
    value: "Cape Verdean escudo",
    isTopFive: false,
  },
  CZK: {
    key: "CZK",
    value: "Czech koruna",
    isTopFive: false,
  },
  DJF: {
    key: "DJF",
    value: "Djiboutian franc",
    isTopFive: false,
  },
  DKK: {
    key: "DKK",
    value: "Danish krone",
    isTopFive: false,
  },
  DOP: {
    key: "DOP",
    value: "Dominican peso",
    isTopFive: false,
  },
  DZD: {
    key: "DZD",
    value: "Algerian dinar",
    isTopFive: false,
  },
  EGP: {
    key: "EGP",
    value: "Egyptian pound",
    isTopFive: false,
  },
  ERN: {
    key: "ERN",
    value: "Eritrean nakfa",
    isTopFive: false,
  },
  ETB: {
    key: "ETB",
    value: "Ethiopian birr",
    isTopFive: false,
  },
  EUR: {
    key: "EUR",
    value: "Euro",
    isTopFive: true,
  },
  FJD: {
    key: "FJD",
    value: "Fiji dollar",
    isTopFive: false,
  },
  FKP: {
    key: "FKP",
    value: "Falkland Islands pound",
    isTopFive: false,
  },
  GBP: {
    key: "GBP",
    value: "Pound sterling",
    isTopFive: true,
  },
  GEL: {
    key: "GEL",
    value: "Georgian lari",
    isTopFive: false,
  },
  GHS: {
    key: "GHS",
    value: "Ghanaian cedi",
    isTopFive: false,
  },
  GIP: {
    key: "GIP",
    value: "Gibraltar pound",
    isTopFive: false,
  },
  GMD: {
    key: "GMD",
    value: "Gambian dalasi",
    isTopFive: false,
  },
  GNF: {
    key: "GNF",
    value: "Guinean franc",
    isTopFive: false,
  },
  GTQ: {
    key: "GTQ",
    value: "Guatemalan quetzal",
    isTopFive: false,
  },
  GYD: {
    key: "GYD",
    value: "Guyanese dollar",
    isTopFive: false,
  },
  HKD: {
    key: "HKD",
    value: "Hong Kong dollar",
    isTopFive: false,
  },
  HNL: {
    key: "HNL",
    value: "Honduran lempira",
    isTopFive: false,
  },
  HRK: {
    key: "HRK",
    value: "Croatian kuna",
    isTopFive: false,
  },
  HTG: {
    key: "HTG",
    value: "Haitian gourde",
    isTopFive: false,
  },
  HUF: {
    key: "HUF",
    value: "Hungarian forint",
    isTopFive: false,
  },
  IDR: {
    key: "IDR",
    value: "Indonesian rupiah",
    isTopFive: false,
  },
  ILS: {
    key: "ILS",
    value: "Israeli new shekel",
    isTopFive: false,
  },
  INR: {
    key: "INR",
    value: "Indian rupee",
    isTopFive: false,
  },
  IQD: {
    key: "IQD",
    value: "Iraqi dinar",
    isTopFive: false,
  },
  IRR: {
    key: "IRR",
    value: "Iranian rial",
    isTopFive: false,
  },
  ISK: {
    key: "ISK",
    value: "Icelandic króna",
    isTopFive: false,
  },
  JMD: {
    key: "JMD",
    value: "Jamaican dollar",
    isTopFive: false,
  },
  JOD: {
    key: "JOD",
    value: "Jordanian dinar",
    isTopFive: false,
  },
  JPY: {
    key: "JPY",
    value: "Japanese yen",
    isTopFive: true,
  },
  KES: {
    key: "KES",
    value: "Kenyan shilling",
    isTopFive: false,
  },
  KGS: {
    key: "KGS",
    value: "Kyrgyzstani som",
    isTopFive: false,
  },
  KHR: {
    key: "KHR",
    value: "Cambodian riel",
    isTopFive: false,
  },
  KMF: {
    key: "KMF",
    value: "Comoro franc",
    isTopFive: false,
  },
  KPW: {
    key: "KPW",
    value: "North Korean won",
    isTopFive: false,
  },
  KRW: {
    key: "KRW",
    value: "South Korean won",
    isTopFive: false,
  },
  KWD: {
    key: "KWD",
    value: "Kuwaiti dinar",
    isTopFive: false,
  },
  KYD: {
    key: "KYD",
    value: "Cayman Islands dollar",
    isTopFive: false,
  },
  KZT: {
    key: "KZT",
    value: "Kazakhstani tenge",
    isTopFive: false,
  },
  LAK: {
    key: "LAK",
    value: "Lao kip",
    isTopFive: false,
  },
  LBP: {
    key: "LBP",
    value: "Lebanese pound",
    isTopFive: false,
  },
  LKR: {
    key: "LKR",
    value: "Sri Lankan rupee",
    isTopFive: false,
  },
  LRD: {
    key: "LRD",
    value: "Liberian dollar",
    isTopFive: false,
  },
  LSL: {
    key: "LSL",
    value: "Lesotho loti",
    isTopFive: false,
  },
  LYD: {
    key: "LYD",
    value: "Libyan dinar",
    isTopFive: false,
  },
  MAD: {
    key: "MAD",
    value: "Moroccan dirham",
    isTopFive: false,
  },
  MDL: {
    key: "MDL",
    value: "Moldovan leu",
    isTopFive: false,
  },
  MGA: {
    key: "MGA",
    value: "Malagasy ariary",
    isTopFive: false,
  },
  MKD: {
    key: "MKD",
    value: "Macedonian denar",
    isTopFive: false,
  },
  MMK: {
    key: "MMK",
    value: "Myanmar kyat",
    isTopFive: false,
  },
  MNT: {
    key: "MNT",
    value: "Mongolian tögrög",
    isTopFive: false,
  },
  MOP: {
    key: "MOP",
    value: "Macanese pataca",
    isTopFive: false,
  },
  MRU: {
    key: "MRU",
    value: "Mauritanian ouguiya",
    isTopFive: false,
  },
  MUR: {
    key: "MUR",
    value: "Mauritian rupee",
    isTopFive: false,
  },
  MVR: {
    key: "MVR",
    value: "Maldivian rufiyaa",
    isTopFive: false,
  },
  MWK: {
    key: "MWK",
    value: "Malawian kwacha",
    isTopFive: false,
  },
  MXN: {
    key: "MXN",
    value: "Mexican peso",
    isTopFive: false,
  },
  MXV: {
    key: "MXV",
    value: "Mexican Unidad de Inversion (UDI) (funds code)",
    isTopFive: false,
  },
  MYR: {
    key: "MYR",
    value: "Malaysian ringgit",
    isTopFive: false,
  },
  MZN: {
    key: "MZN",
    value: "Mozambican metical",
    isTopFive: false,
  },
  NAD: {
    key: "NAD",
    value: "Namibian dollar",
    isTopFive: false,
  },
  NGN: {
    key: "NGN",
    value: "Nigerian naira",
    isTopFive: false,
  },
  NIO: {
    key: "NIO",
    value: "Nicaraguan córdoba",
    isTopFive: false,
  },
  NOK: {
    key: "NOK",
    value: "Norwegian krone",
    isTopFive: false,
  },
  NPR: {
    key: "NPR",
    value: "Nepalese rupee",
    isTopFive: false,
  },
  NZD: {
    key: "NZD",
    value: "New Zealand dollar",
    isTopFive: false,
  },
  OMR: {
    key: "OMR",
    value: "Omani rial",
    isTopFive: false,
  },
  PAB: {
    key: "PAB",
    value: "Panamanian balboa",
    isTopFive: false,
  },
  PEN: {
    key: "PEN",
    value: "Peruvian sol",
    isTopFive: false,
  },
  PGK: {
    key: "PGK",
    value: "Papua New Guinean kina",
    isTopFive: false,
  },
  PHP: {
    key: "PHP",
    value: "Philippine peso",
    isTopFive: false,
  },
  PKR: {
    key: "PKR",
    value: "Pakistani rupee",
    isTopFive: false,
  },
  PLN: {
    key: "PLN",
    value: "Polish złoty",
    isTopFive: false,
  },
  PYG: {
    key: "PYG",
    value: "Paraguayan guaraní",
    isTopFive: false,
  },
  QAR: {
    key: "QAR",
    value: "Qatari riyal",
    isTopFive: false,
  },
  RON: {
    key: "RON",
    value: "Romanian leu",
    isTopFive: false,
  },
  RSD: {
    key: "RSD",
    value: "Serbian dinar",
    isTopFive: false,
  },
  RUB: {
    key: "RUB",
    value: "Russian ruble",
    isTopFive: false,
  },
  RWF: {
    key: "RWF",
    value: "Rwandan franc",
    isTopFive: false,
  },
  SAR: {
    key: "SAR",
    value: "Saudi riyal",
    isTopFive: false,
  },
  SBD: {
    key: "SBD",
    value: "Solomon Islands dollar",
    isTopFive: false,
  },
  SCR: {
    key: "SCR",
    value: "Seychelles rupee",
    isTopFive: false,
  },
  SDG: {
    key: "SDG",
    value: "Sudanese pound",
    isTopFive: false,
  },
  SEK: {
    key: "SEK",
    value: "Swedish krona",
    isTopFive: false,
  },
  SGD: {
    key: "SGD",
    value: "Singapore dollar",
    isTopFive: false,
  },
  SHP: {
    key: "SHP",
    value: "Saint Helena pound",
    isTopFive: false,
  },
  SLL: {
    key: "SLL",
    value: "Sierra Leonean leone",
    isTopFive: false,
  },
  SOS: {
    key: "SOS",
    value: "Somali shilling",
    isTopFive: false,
  },
  SRD: {
    key: "SRD",
    value: "Surinamese dollar",
    isTopFive: false,
  },
  SSP: {
    key: "SSP",
    value: "South Sudanese pound",
    isTopFive: false,
  },
  STN: {
    key: "STN",
    value: "São Tomé and Príncipe dobra",
    isTopFive: false,
  },
  SVC: {
    key: "SVC",
    value: "Salvadoran colón",
    isTopFive: false,
  },
  SYP: {
    key: "SYP",
    value: "Syrian pound",
    isTopFive: false,
  },
  SZL: {
    key: "SZL",
    value: "Swazi lilangeni",
    isTopFive: false,
  },
  THB: {
    key: "THB",
    value: "Thai baht",
    isTopFive: false,
  },
  TJS: {
    key: "TJS",
    value: "Tajikistani somoni",
    isTopFive: false,
  },
  TMT: {
    key: "TMT",
    value: "Turkmenistan manat",
    isTopFive: false,
  },
  TND: {
    key: "TND",
    value: "Tunisian dinar",
    isTopFive: false,
  },
  TOP: {
    key: "TOP",
    value: "Tongan paʻanga",
    isTopFive: false,
  },
  TRY: {
    key: "TRY",
    value: "Turkish lira",
    isTopFive: false,
  },
  TTD: {
    key: "TTD",
    value: "Trinidad and Tobago dollar",
    isTopFive: false,
  },
  TWD: {
    key: "TWD",
    value: "New Taiwan dollar",
    isTopFive: false,
  },
  TZS: {
    key: "TZS",
    value: "Tanzanian shilling",
    isTopFive: false,
  },
  UAH: {
    key: "UAH",
    value: "Ukrainian hryvnia",
    isTopFive: false,
  },
  UGX: {
    key: "UGX",
    value: "Ugandan shilling",
    isTopFive: false,
  },
  USD: {
    key: "USD",
    value: "United States dollar",
    isTopFive: true,
  },
  USN: {
    key: "USN",
    value: "United States dollar (next day) (funds code)",
    isTopFive: false,
  },
  UYI: {
    key: "UYI",
    value: "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)",
    isTopFive: false,
  },
  UYU: {
    key: "UYU",
    value: "Uruguayan peso",
    isTopFive: false,
  },
  UZS: {
    key: "UZS",
    value: "Uzbekistan som",
    isTopFive: false,
  },
  VED: {
    key: "VED",
    value: "Venezuelan bolívar digital",
    isTopFive: false,
  },
  VES: {
    key: "VES",
    value: "Venezuelan bolívar soberano",
    isTopFive: false,
  },
  VND: {
    key: "VND",
    value: "Vietnamese đồng",
    isTopFive: false,
  },
  VUV: {
    key: "VUV",
    value: "Vanuatu vatu",
    isTopFive: false,
  },
  WST: {
    key: "WST",
    value: "Samoan tala",
    isTopFive: false,
  },
  XAF: {
    key: "XAF",
    value: "CFA franc BEAC",
    isTopFive: false,
  },
  XAG: {
    key: "XAG",
    value: "Silver (one troy ounce)",
    isTopFive: false,
  },
  XAU: {
    key: "XAU",
    value: "Gold (one troy ounce)",
    isTopFive: false,
  },
  XBA: {
    key: "XBA",
    value: "European Composite Unit (EURCO)",
    isTopFive: false,
  },
  XBB: {
    key: "XBB",
    value: "European Monetary Unit (E.M.U.-6)",
    isTopFive: false,
  },
  XBC: {
    key: "XBC",
    value: "European Unit of Account 9 (E.U.A.-9)",
    isTopFive: false,
  },
  XBD: {
    key: "XBD",
    value: "European Unit of Account 17 (E.U.A.-17)",
    isTopFive: false,
  },
  XCD: {
    key: "XCD",
    value: "East Caribbean dollar",
    isTopFive: false,
  },
  XDR: {
    key: "XDR",
    value: "Special drawing rights",
    isTopFive: false,
  },
  XOF: {
    key: "XOF",
    value: "CFA franc BCEAO",
    isTopFive: false,
  },
  XPD: {
    key: "XPD",
    value: "Palladium (one troy ounce)",
    isTopFive: false,
  },
  XPF: {
    key: "XPF",
    value: "CFP franc (franc Pacifique)",
    isTopFive: false,
  },
  XPT: {
    key: "XPT",
    value: "Platinum (one troy ounce)",
    isTopFive: false,
  },
  XSU: {
    key: "XSU",
    value: "Sucre",
    isTopFive: false,
  },
  XUA: {
    key: "XUA",
    value: "ABD unit of Account",
    isTopFive: false,
  },
  YER: {
    key: "YER",
    value: "Yemeni rial",
    isTopFive: false,
  },
  ZAR: {
    key: "ZAR",
    value: "South African rand",
    isTopFive: false,
  },
  ZMW: {
    key: "ZMW",
    value: "Zambian kwacha",
    isTopFive: false,
  },
  ZWL: {
    key: "ZWL",
    value: "Zimbabwean dollar",
    isTopFive: false,
  },
};
