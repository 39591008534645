
import { Component, Vue } from "vue-property-decorator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BCol, BRow } from "bootstrap-vue";
import { ModalID } from "@/enums/ModalID";
import { MetricEventType } from "@/enums/MetricEventType";
import { mixins } from "vue-class-component";
import MetricsMixin from "@/mixin/MetricsMixin";

@Component({
  components: {
    BaseModal,
    BaseButton,
    BRow,
    BCol,
  },
})
export default class SecuritiesDeletionModal extends mixins(Vue, MetricsMixin) {
  private id = ModalID.DELETE_SECURITY;

  deleteSecurities(): void {
    this.sendMetric(
      MetricEventType.REMOVED_ISINS,
      [...this.selectedSecurityISINs].length
    );
    this.$store.dispatch("deleteSecurities", [...this.selectedSecurityISINs]);
    Vue.prototype.$modal.hide(this.id);
  }

  get selectedSecurityISINs(): string[] {
    return this.$store.state.selectedSecurityISINs;
  }
}
