import Security from "@/models/security/Security";
import { SortDirection } from "@/enums/SortDirection";
import { get, isNaN } from "lodash";

export default class SecuritySorter {
  static sort(
    securities: Array<Security>,
    currentSortedColumn: string,
    sortDirection: SortDirection
  ): Security[] {
    return securities.sort((a: Security, b: Security) => {
      const compA = this.getSortedValue(a, currentSortedColumn);
      const compB = this.getSortedValue(b, currentSortedColumn);

      let sortAscending = 1;

      if (sortDirection === SortDirection.DESCENDING) {
        sortAscending = -1;
      }

      if (compA === undefined || isNaN(compA)) return -1 * sortAscending;

      if (compB === undefined || isNaN(compB)) return sortAscending;

      if (compA < compB) return -1 * sortAscending;

      if (compA > compB) return sortAscending;

      return 0;
    });
  }

  private static getSortedValue(
    security: Security,
    currentSortedColumn: string
  ): any {
    const value = get(security, currentSortedColumn);

    if (typeof value === "string" || value instanceof String) {
      // To guarantee sorting is case INSENSITIVE on strings
      return value.toLowerCase();
    }

    return value;
  }
}
