
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";

@Component
export default class BaseModal extends mixins(Vue, AssetMixin) {
  @Prop({ required: true }) id!: string;
  @Prop({ required: false, default: false, type: Boolean })
  notClosable!: boolean;
  @Prop({ required: false, default: "base", type: String }) size!: string;

  showModal = false;

  show(): void {
    this.showModal = true;
  }

  hide(): void {
    if (this.showModal && !this.notClosable) {
      this.showModal = false;
      this.$emit("hide");
    }
  }

  beforeMount(): void {
    Vue.prototype.$modal.register(this.id);

    Vue.prototype.$modal.eventBus.$on("show", (idSent: string) => {
      if (idSent === this.id) {
        this.show();
      }
    });

    Vue.prototype.$modal.eventBus.$on("hide", (idSent: string) => {
      if (idSent === this.id) {
        this.hide();
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.key === "Escape") {
        self.hide();
      }
    });
  }
}
