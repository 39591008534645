export default class CSVImportFeedback {
  securitiesCount: number;
  alreadyPresentISINs: string[];
  previousValidISINsCount: number;
  previousInvalidISINs: string[];
  incorrectFormatSecurities: string[];
  errorMessage: string;

  constructor(
    securitiesCount: number,
    alreadyPresentISINs: string[],
    previousValidISINsCount: number,
    previousInvalidISINs: string[],
    incorrectFormatISINs: string[],
    errorMessage: string
  ) {
    this.securitiesCount = securitiesCount;
    this.alreadyPresentISINs = alreadyPresentISINs;
    this.previousValidISINsCount = previousValidISINsCount;
    this.previousInvalidISINs = previousInvalidISINs;
    this.incorrectFormatSecurities = incorrectFormatISINs;
    this.errorMessage = errorMessage;
  }
}
