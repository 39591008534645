import { AxiosResponse } from "axios";
import DataFaker from "@/services/DataFaker";
import Config from "@/constants/Config";
import { exampleCurrencyCodes } from "./testCurrencies";

export default class DevModeGateway {
  static async getCurrencies(): Promise<AxiosResponse> {
    const fakeCurrencies = this.getFakeCurrencyList();
    await DataFaker.randomDelay();

    return this.createAxiosResponse(fakeCurrencies);
  }

  private static getFakeCurrencyList(): Array<string> {
    return Object(exampleCurrencyCodes);
  }

  static async getSecurities(isins: Array<string>): Promise<AxiosResponse> {
    const fakeSecurities = this.getFakeSecurities(isins);
    await DataFaker.randomDelay();

    return this.createAxiosResponse(fakeSecurities);
  }

  private static getFakeSecurities(
    isins: Array<string>
  ): Array<Record<string, unknown>> {
    const listOfObjects = [];

    for (let i = 0; i < isins.length; i++) {
      const naceCode = DataFaker.getNaceCode();
      const marketValue = DataFaker.getRandomMarketValue();
      const marketValueUSD = marketValue + 100;
      const singleObject = {
        securityData: {
          securityType: DataFaker.getSecurityType(),
          isin: isins[i],
          companyName: `sample company ${i}`,
          naceCode: naceCode,
          naceSector: DataFaker.getNaceSector(naceCode),
          countryCode: DataFaker.getCountryCode(),
          currency: DataFaker.getRandomCurrency(),
          marketValue: marketValue,
          marketValueUSD: marketValueUSD,
          scope1Method: DataFaker.getDataSource(),
          scope2MarketBasedMethod: DataFaker.getDataSource(),
          scope3Categories: DataFaker.getScope3Categories(),
        },
        baseline: {
          total: DataFaker.getXDCValue(),
          scope1: DataFaker.getXDCValue(),
          scope2: DataFaker.getXDCValue(),
          scope3: DataFaker.getXDCValue(),
        },
        securityXDCs: {
          baseline: {
            total: DataFaker.getXDCValue(),
            scope1: DataFaker.getXDCValue(),
            scope2: DataFaker.getXDCValue(),
            scope3: DataFaker.getXDCValue(),
          },
          sector: {
            total: DataFaker.getXDCValue(),
            scope1: DataFaker.getXDCValue(),
            scope2: DataFaker.getXDCValue(),
            scope3: DataFaker.getXDCValue(),
          },
          sectorTargets: [
            {
              name: "IEA_15DS",
              scope1: DataFaker.getXDCValue(),
              scope2: DataFaker.getXDCValue(),
              scope3: DataFaker.getXDCValue(),
              total: DataFaker.getXDCValue(),
              gap: DataFaker.getXDCValue(),
              isAligned: DataFaker.getAlignment(),
            },
            {
              name: "IEA_B2DS",
              scope1: DataFaker.getXDCValue(),
              scope2: DataFaker.getXDCValue(),
              scope3: DataFaker.getXDCValue(),
              total: DataFaker.getXDCValue(),
              gap: DataFaker.getXDCValue(),
              isAligned: DataFaker.getAlignment(),
            },
            {
              name: "IEA_2DS",
              scope1: DataFaker.getXDCValue(),
              scope2: DataFaker.getXDCValue(),
              scope3: DataFaker.getXDCValue(),
              total: DataFaker.getXDCValue(),
              gap: DataFaker.getXDCValue(),
              isAligned: DataFaker.getAlignment(),
            },
          ],
        },
        errorMessage: null,
      };

      listOfObjects.push(singleObject);
    }

    return listOfObjects;
  }

  static async getPortfolio(): Promise<AxiosResponse> {
    const fakePortfolioValues = this.getFakePortfolioValues();
    await DataFaker.randomDelay();

    return this.createAxiosResponse(fakePortfolioValues);
  }

  private static getFakePortfolioValues(): Record<string, unknown> {
    return {
      baselineTotal: DataFaker.getXDCValue(),
      sectorBaselineTotal: DataFaker.getXDCValue(),
      sectorTargets: [
        {
          name: "IEA_15DS",
          scope1: DataFaker.getXDCValue(),
          scope2: DataFaker.getXDCValue(),
          scope3: DataFaker.getXDCValue(),
          total: 2.4,
          gap: 1.0,
          isAligned: false,
        },
        {
          name: "IEA_B2DS",
          scope1: DataFaker.getXDCValue(),
          scope2: DataFaker.getXDCValue(),
          scope3: DataFaker.getXDCValue(),
          total: 2.0,
          gap: 0.0,
          isAligned: true,
        },
        {
          name: "IEA_2DS",
          scope1: DataFaker.getXDCValue(),
          scope2: DataFaker.getXDCValue(),
          scope3: DataFaker.getXDCValue(),
          total: 2.9,
          gap: 0.5,
          isAligned: false,
        },
      ],
      isinsNotAccounted: [],
      error: false,
    };
  }

  static async login(): Promise<AxiosResponse> {
    const devLoginJson = this.getDevJson();

    return this.createAxiosResponse(devLoginJson);
  }

  private static getDevJson(): Record<string, unknown> {
    return {
      user: {
        registrations: [
          {
            applicationId: process.env.VUE_APP_AUTH_APPLICATION_ID,
          },
        ],
      },
      refreshToken: Config.DEV_TOKEN,
      token: Config.DEV_TOKEN,
    };
  }

  private static createAxiosResponse(
    fakeResponseData: any
  ): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const response = {
        data: fakeResponseData,
        status: 200,
        statusText: "",
        headers: {
          "": "",
        },
        config: {},
        request: null,
      };
      resolve(response);
    });
  }
}
