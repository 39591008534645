import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import { NACE_CODE_TO_SECTOR_NAME_MAP } from "@/constants/NaceCode";
import { split } from "lodash";
import NumberFormatter from "@/services/NumberFormatter";
import { ErrorMessage } from "@/enums/ErrorMessage";
import getOrElse from "@/models/ModelParserHelper";
import { Status } from "@/enums/Status";
import {
  SECURITIES_TYPE_MAP,
  SecurityType,
} from "@/models/security/SecurityType";
import { XDC } from "@/models/XDC";

export default class Security {
  type: SecurityType;
  isin: string;
  companyName: string;
  naceCode: string;
  naceSector: string;
  baselineXDC: XDC;
  marketValue: number;
  marketValueUSD: number;
  currency: string;
  scope1Method: string;
  scope2MarketBasedMethod: string;
  scope3Categories: string;
  status: Status;
  statusMessage: ErrorMessage;

  constructor(
    type: SecurityType = SecurityType.UNKNOWN,
    isin = "",
    companyName = "",
    naceCode = "",
    naceSector = "",
    baselineXDC = new XDC(),
    marketValue = 0.0,
    marketValueUSD = 0.0,
    currency = "",
    scope1Method = "",
    scope2MarketBasedMethod = "",
    scope3Categories = "",
    status = Status.NONE,
    statusMessage: ErrorMessage = ErrorMessage.NONE
  ) {
    this.type = type;
    this.isin = isin;
    this.companyName = companyName;
    this.naceCode = naceCode;
    this.naceSector = naceSector;
    this.baselineXDC = baselineXDC;
    this.marketValue = marketValue;
    this.marketValueUSD = marketValueUSD;
    this.currency = currency;
    this.scope1Method = scope1Method;
    this.scope2MarketBasedMethod = scope2MarketBasedMethod;
    this.scope3Categories = scope3Categories;
    this.status = status;
    this.statusMessage = statusMessage;
  }

  static loading(securityRequestPayload: SecurityRequestPayload): Security {
    //TODO confirm change after backend implementation
    const { isin, marketValue, currency } = securityRequestPayload;

    return new Security(
      SecurityType.UNKNOWN,
      isin,
      "",
      "",
      "",
      new XDC(),
      marketValue,
      0.0,
      currency,
      "",
      "",
      "",
      Status.LOADING,
      ErrorMessage.NONE
    );
  }

  hasError(): boolean {
    return this.status === Status.ERROR;
  }

  static apiError(securityRequestPayload: SecurityRequestPayload): Security {
    return Object.assign(Security.loading(securityRequestPayload), {
      status: Status.ERROR,
      statusMessage: ErrorMessage.SERVER_ERROR,
    });
  }

  getErrorMessage(): string {
    if (this.statusMessage) {
      return this.statusMessage;
    }

    return "No error";
  }

  // function is used in ExcelExporter
  export(
    baseYear: number,
    targetYear: number,
    provider: string,
    sspCode: string
  ): Record<string, any> {
    const baseInformationObject: Record<string, any> = {
      ISIN: this.isin,
      Errors: this.getErrorMessage(),
      Class: SECURITIES_TYPE_MAP[this.type],
      Name: this.companyName,
      "Market Value": NumberFormatter.number(this.marketValue),
      Currency: this.currency,
      "Baseline XDC total": NumberFormatter.tempForExport(
        this.baselineXDC.total
      ),
      "Baseline XDC Scope1": NumberFormatter.tempForExport(
        this.baselineXDC.scope1
      ),
      "Baseline XDC Scope2": NumberFormatter.tempForExport(
        this.baselineXDC.scope2
      ),
      "Baseline XDC Scope3": NumberFormatter.tempForExport(
        this.baselineXDC.scope3
      ),
      "NACE Code": this.naceCode,
      "NACE Sector": this.naceSector,
    };

    const scope3Categories =
      this.scope3Categories.length > 0
        ? `${this.scope3Categories}` + " / 15"
        : "_";

    const disclosureObject = {
      "Scope1 Calculation Method": this.scope1Method,
      "Scope2 Market Based Method": this.scope2MarketBasedMethod,
      "Scope3 Disclosed Categories": scope3Categories,
    };

    const configurationParameters = {
      "Base Year": baseYear,
      "Target Year": targetYear,
      "Provider Option": provider,
      "SSP Code": sspCode,
    };

    return Object.assign(
      {},
      baseInformationObject,
      disclosureObject,
      configurationParameters
    );
  }

  valuesFromJson(jsonData: Record<string, any>, type: SecurityType): void {
    const securityData = getOrElse(jsonData, "securityData", {});

    this.type = type;
    this.isin = getOrElse(securityData, "isin", "");
    this.companyName = getOrElse(securityData, "companyName", "");

    const fullNaceCode: string = getOrElse(securityData, "naceCode", "");
    this.naceCode = split(fullNaceCode, ".")[0];
    this.naceSector = NACE_CODE_TO_SECTOR_NAME_MAP[this.naceCode];

    const baseline = getOrElse(jsonData, "baseline", {});
    this.baselineXDC = Security.getXDCFromJson(baseline);

    this.marketValue = getOrElse(securityData, "marketValue", "");
    this.marketValueUSD = getOrElse(securityData, "marketValueUSD", "");
    this.currency = getOrElse(securityData, "currency", "");

    this.scope1Method = this.formatScopeEmissionMethodInfo(
      securityData,
      "scope1Method"
    );
    this.scope2MarketBasedMethod = this.formatScopeEmissionMethodInfo(
      securityData,
      "scope2MarketBasedMethod"
    );
    this.scope3Categories = getOrElse(securityData, "scope3Categories", "");

    this.status = Security.getStatus(jsonData);
    this.statusMessage = Security.getStatusMessage(jsonData, this.status);
  }

  private static getXDCFromJson(jsonObject: Record<string, any>): XDC {
    return new XDC(
      getOrElse(jsonObject, "total", NaN),
      getOrElse(jsonObject, "scope1", NaN),
      getOrElse(jsonObject, "scope2", NaN),
      getOrElse(jsonObject, "scope3", NaN)
    );
  }

  private static getStatus(jsonData: Record<string, any>): Status {
    const errorMessage = getOrElse(jsonData, "errorMessage", false);

    if (errorMessage) {
      return Status.ERROR;
    }

    return Status.NONE;
  }

  static getStatusMessage(
    jsonData: Record<string, any>,
    securityStatus: Status
  ): ErrorMessage {
    if (securityStatus === Status.ERROR) {
      const stringValue: keyof typeof ErrorMessage = getOrElse(
        jsonData,
        "errorMessage",
        ErrorMessage.NONE
      );

      return ErrorMessage[stringValue];
    }

    return ErrorMessage.NONE;
  }

  formatScopeEmissionMethodInfo(
    jsonData: Record<string, any>,
    key: string
  ): string {
    const unabridgedMethodInfo = getOrElse(jsonData, key, "N/A");

    return this.getMethodAbbreviation(unabridgedMethodInfo);
  }

  getMethodAbbreviation(unabridgedMethodInfo: string): string {
    let abbreviated = "N/A";
    const legacyUnabridgedToAbbreviation: Record<string, string> = {
      "Winsorized - Upper Threshold - Industry winsor": "Winsorized",
      "Winsorized - Lower Threshold - Up to sector max": "Winsorized",
      "Winsorized - Lower Threshold - Industry winsor": "Winsorized",
      "Accepted Total Scope12 minus Accepted Location": "Derived",
      "Inferred - Average - Up to sector winsor": "Inferred",
      "Inferred - Average - Up to sector max": "Inferred",
      Reported: "Reported",
      "Winsorized - Lower Threshold - Up to sector winsor": "Winsorized",
      "Inferred - Average - Up to subsector max": "Inferred",
      "Winsorized - Lower Threshold - Up to subsector winsor": "Winsorized",
      "Winsorized - Upper Threshold - Up to subsector winsor": "Winsorized",
      "Inferred - Average - Industry winsor": "Inferred",
      "Winsorized - Lower Threshold - Industry max": "Winsorized",
      "Inferred - Average - Up to subsector winsor": "Inferred",
      "Inferred - Average - Industry max": "Inferred",
      "Winsorized - Lower Threshold - Up to subsector max": "Winsorized",
      "Winsorized - Upper Threshold - Up to sector winsor": "Winsorized",
    };

    const newUnabridgedToAbbreviation: Record<string, string> = {
      "Estimated from average intensity": "Estimate (avg)",
      "Estimated from scope 1-to-2 ratio": "Estimate (ratio)",
      "Winsorized to lower threshold": "Winsorized",
      "Winsorized to upper threshold": "Winsorized",
      Reported: "Reported",
    };

    Object.entries(legacyUnabridgedToAbbreviation).forEach(
      (unabridgedToAbbreviation) => {
        const [unabridged, abbreviation] = unabridgedToAbbreviation;

        if (unabridgedMethodInfo.toLowerCase() === unabridged.toLowerCase()) {
          abbreviated = abbreviation;
        }
      }
    );

    Object.entries(newUnabridgedToAbbreviation).forEach(
      (unabridgedToAbbreviation) => {
        const [unabridged, abbreviation] = unabridgedToAbbreviation;

        if (unabridgedMethodInfo.toLowerCase() === unabridged.toLowerCase()) {
          abbreviated = abbreviation;
        }
      }
    );

    return abbreviated;
  }
}
