export default class AccordionItem {
  title: string;
  text: string;
  isSelected: boolean;

  constructor(title: string, text: string, selected = false) {
    this.title = title;
    this.text = text;
    this.isSelected = selected;
  }
}
