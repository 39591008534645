import { Component, Vue } from "vue-property-decorator";
import { sum, isNaN } from "lodash";
import Security from "@/models/security/Security";

@Component
export default class SecurityHelperMixin extends Vue {
  normalizedMarketValuesUSD(passedSecurities: Security[]): number[] {
    return passedSecurities.map((s) => {
      const calculated =
        (100 / this.totalMarketValueUSDPercentage) * s.marketValueUSD;

      return isNaN(calculated) ? 0 : calculated;
    });
  }

  get totalMarketValueUSDPercentage(): number {
    return sum(this.validSecurities.map((security) => security.marketValueUSD));
  }

  get validSecurities(): Security[] {
    return this.$store.state.validSecurities;
  }
}
