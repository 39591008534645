export enum ErrorMessage {
  NONE = "",
  SERVER_ERROR = "Server not available, try again later",
  DATA_API_TIMEOUT_ERROR = "Timeout during request, please try again later",
  DATA_API_ERROR = "Data request failed, try again later",
  DATA_API_ERROR_NOT_PRESENT_IN_DB = "Company Information not available",
  MODEL_API_ERROR_UNSUPPORTED_NACE_CODE = "XDC Calculation not possible - Agricultural Sector (NACE 01, 02, 03)",
  MODEL_API_ERROR_CALCULATION_NOT_POSSIBLE = "XDC Calculation not possible",
  MODEL_API_ERROR_CALCULATION_NOT_POSSIBLE_GVA_NEGATIVE = "XDC Calculation not possible - GVA Negative",
  MODEL_API_ERROR_CALCULATION_NOT_POSSIBLE_GVA_EQUAL_TO_ZERO = "XDC Calculation not possible - Missing Information",
  MODULE_SB_ROLE_NOT_PRESENT_PAID_TIER = "Sovereign Bonds Module not purchased",
  MODULE_SB_ROLE_NOT_PRESENT_FREE_TIER = "Sovereign Bonds Module can be acquired as a premium user",
}
