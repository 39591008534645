
import { Component, Vue } from "vue-property-decorator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import { difference } from "lodash";
import Security from "@/models/security/Security";
import CircularProgress from "@/pages/main/components/modals/CircularProgress.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import CSVImportFeedback from "@/models/CSVImportFeedback";
import { Status } from "@/enums/Status";
import { ModalID } from "@/enums/ModalID";

@Component({
  components: { BaseButton, CircularProgress, BaseModal },
})
export default class CSVUploadFeedbackModal extends Vue {
  private readonly id = ModalID.UPLOAD_FEEDBACK_MODAL;

  close(): void {
    Vue.prototype.$modal.hide(this.id);
  }

  get isImportingData(): boolean {
    return this.$store.state.importingBulkData;
  }

  get feedback(): CSVImportFeedback {
    return this.$store.state.csvImportFeedback;
  }

  get errorMessage(): string {
    return this.feedback.errorMessage;
  }

  get validISINsReturnedCount(): number {
    const currentCount = this.$store.state.validSecurities.length;
    const previousCount = this.feedback.previousValidISINsCount;

    return currentCount - previousCount;
  }

  get invalidISINsReturned(): Array<string> {
    const currentInvalid = this.$store.state.invalidSecurities
      .filter((s: Security) => s.status !== Status.LOADING)
      .map((s: Security) => s.isin);

    return difference(currentInvalid, this.feedback.previousInvalidISINs);
  }

  get incorrectFormatSecurities(): Array<string> {
    return this.feedback.incorrectFormatSecurities;
  }

  get totalReturned(): number {
    return this.validISINsReturnedCount + this.invalidISINsReturned.length;
  }
}
