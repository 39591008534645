export default class NumberFormatter {
  private static oneDigitNumberFormatter = new Intl.NumberFormat("en-EN", {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });

  private static twoDigitNumberFormatter = new Intl.NumberFormat("en-EN", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  private static fourDigitNumberFormatter = new Intl.NumberFormat("en-EN", {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  });

  static number(value: number): string {
    return this.twoDigitNumberFormatter.format(value);
  }

  static isNumber(value: number): boolean {
    return !isNaN(value);
  }

  static isNegative(value: number): boolean {
    return value < 0;
  }

  static temperature(number: number, showSymbol = true): string {
    let formattedTemperature = "";

    if (NumberFormatter.isNumber(number)) {
      if (number && NumberFormatter.isNegative(number)) {
        formattedTemperature = "- ";
      }

      formattedTemperature += this.oneDigitNumberFormatter.format(
        Math.abs(number as number)
      );
    } else {
      formattedTemperature = "_";
    }

    if (showSymbol) {
      formattedTemperature += " °C";
    }

    return formattedTemperature;
  }

  static formatNumber(value: number): string {
    return NumberFormatter.isNumber(value) ? value.toString() : "_";
  }

  static formatString(value: string): string {
    return value.length > 0 ? value : "_";
  }

  static tempForExport(value: number): string {
    return this.temperature(value, false).replace(" ", "");
  }
}
