
import { Component, Prop, Vue } from "vue-property-decorator";
import { BCol, BFormRadioGroup, BRow } from "bootstrap-vue";

@Component({
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
  },
})
export default class ParameterSelector extends Vue {
  @Prop({ required: true }) selectorOptions!: any;
  @Prop({ required: true }) selectorValue!: string;
  @Prop({ required: true }) title!: string;

  toggleSelection(value: string): void {
    this.$emit("valueChanged", value);
  }
}
