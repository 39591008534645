import { MutationTree } from "vuex";
import { getInitialState, State } from "./State";
import PortfolioValues from "@/models/PortfolioValues";
import Security from "@/models/security/Security";
import CSVImportFeedback from "@/models/CSVImportFeedback";
import {
  removeSecuritiesByISIN,
  resetPortfolioValuesIfNecessary,
  updateListWithNewSecurities,
} from "@/store/utils/MutationUtils";
import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import Currency from "@/enums/Currency";

export const mutations: MutationTree<State> = {
  upsertToValidSecurities(state: State, securities: Security[]) {
    const validSecurities = state.validSecurities as Security[];

    // Update it with incoming clean values
    state.validSecurities = updateListWithNewSecurities(
      state,
      securities,
      validSecurities
    );

    resetPortfolioValuesIfNecessary(state);
  },
  upsertToInvalidSecurities(state: State, securities: Security[]) {
    const invalidSecurities = state.invalidSecurities as Security[];

    // Update it with incoming clean values
    state.invalidSecurities = updateListWithNewSecurities(
      state,
      securities,
      invalidSecurities
    );
  },
  addToSelectedSecurityISINs(state: State, isin: string): void {
    const index = state.selectedSecurityISINs.indexOf(isin);

    if (index === -1) state.selectedSecurityISINs.push(isin);
  },
  deleteAllValidSecurities(state: State): void {
    state.validSecurities = [];
  },
  deleteFromValidSecurities(state: State, isins: string[]) {
    const securities = state.validSecurities as Security[];

    state.validSecurities = removeSecuritiesByISIN(securities, isins);

    resetPortfolioValuesIfNecessary(state);
  },
  deleteAllInvalidSecurities(state: State): void {
    state.invalidSecurities = [];
  },
  deleteFromInvalidSecurities(state: State, isins: string[]) {
    const securities = state.invalidSecurities as Security[];

    state.invalidSecurities = removeSecuritiesByISIN(securities, isins);
  },
  clearState(state: State) {
    Object.assign(state, getInitialState());
  },
  clearSelectedSecurityISINs(state: State): void {
    state.selectedSecurityISINs = [];
  },
  clearPortfolio(state: State): void {
    setTimeout(() => {
      state.validSecurities = [];
      state.invalidSecurities = [];
      state.needsRecalculation = false;
      state.portfolioValues = new PortfolioValues();
    }, 500);
  },
  deleteFromSelectedSecurityISINs(state: State, isin: string): void {
    const index = state.selectedSecurityISINs.indexOf(isin);

    if (index !== -1) state.selectedSecurityISINs.splice(index, 1);
  },
  setBaseYear(state: State, baseYear: number) {
    state.baseYear = baseYear;
  },
  setErrorMessage(state: State, errorMessage: string) {
    state.hasErrorMessage = errorMessage !== "";
    state.errorMessage = errorMessage;
  },
  setFeedbackMessage(state: State, feedbackMessage: string) {
    state.hasFeedbackMessage = feedbackMessage !== "";
    state.feedbackMessage = feedbackMessage;
  },
  setPortfolioName(state: State, portfolioName: string) {
    state.portfolioName = portfolioName;
  },
  setImportingBulkData(state: State, importingBulkData: boolean) {
    state.importingBulkData = importingBulkData;
  },
  setPortfolioValues(state: State, portfolioValues: PortfolioValues) {
    state.portfolioValues = portfolioValues;
    state.needsRecalculation = false;
  },
  increaseCurrentSecurityDataCalls(state: State) {
    state.ongoingDataRequests = state.ongoingDataRequests + 1;
  },
  decreaseCurrentSecurityDataCalls(state: State) {
    state.ongoingDataRequests =
      state.ongoingDataRequests === 0 ? 0 : state.ongoingDataRequests - 1;
  },
  resetCurrentSecurityDataCalls(state: State) {
    state.ongoingDataRequests = 0;
    state.companyRequestQueue = [];
  },
  setCSVFeedback(state: State, feedback: CSVImportFeedback) {
    state.csvImportFeedback = feedback;
  },
  addSecurityRequestToQueue(state: State, request: SecurityRequestPayload) {
    state.companyRequestQueue.push(request);
  },
  setTargetYear(state: State, targetYear: number) {
    state.targetYear = targetYear;
  },
  setProvider(state: State, provider: string) {
    state.provider = provider;
  },
  setSspCode(state: State, sspCode: string) {
    state.sspCode = sspCode;
  },

  setCurrencies(state: State, currencies: Record<string, Currency>) {
    state.currencies = currencies;
  },
};
