
import { Component, Vue } from "vue-property-decorator";
import BaseCard from "@/pages/overview/components/base/BaseCard.vue";
import Security from "@/models/security/Security";
import { SecurityType } from "@/models/security/SecurityType";
import SovereignBond from "@/models/security/SovereignBond";
import NumberFormatter from "@/services/NumberFormatter";
import SecurityHelperMixin from "@/mixin/SecurityHelperMixin";
import { mixins } from "vue-class-component";

@Component({
  components: { BaseCard },
})
export default class MapCard extends mixins(Vue, SecurityHelperMixin) {
  map: any;

  // Colors have to be done in JS unfortunately so no matching to theme
  regionStyle = {
    initial: {
      fill: "#F7F7F7",
      fillOpacity: 1,
      stroke: "#AAB3AE",
      strokeWidth: 0.2,
    },
    selected: {
      fill: "#4747474D",
      stroke: "#474747",
    },
    selectedHover: {
      fill: "#4C57A6",
      fillOpacity: 1,
      stroke: "#3D4585",
    },
  };

  innerInfo = {
    country: "_",
    bondCount: "_",
    baseline: "_",
    portfolioShare: "_",
  };

  mounted(): void {
    const mapRef: any = this.$refs.map;

    if (mapRef) {
      this.map = mapRef.getMap();
    }

    window.addEventListener("resize", () => {
      if (this.map) {
        this.map.updateSize();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  regionTooltipShow(tooltip: any, code: string): void {
    const included = this.regions.includes(code);

    if (included) {
      tooltip.getElement().classList.add("selected");
    } else {
      tooltip.getElement().classList.remove("selected");
    }

    this.updateInnerInfoPanel(code, tooltip.text());
  }

  updateInnerInfoPanel(code: string, countryName: string): void {
    const sovereignBondsList = this.sovereignBonds.filter(
      (sb) => sb.country === code
    );

    if (sovereignBondsList.length > 0) {
      const referenceBond: SovereignBond = sovereignBondsList[0];

      const baseline = NumberFormatter.temperature(
        referenceBond.baselineXDC.total
      );

      const bondCount = `${sovereignBondsList.length} `;

      const portfolioShare = this.normalizedMarketValuesUSD(sovereignBondsList)
        .reduce((prevVal, currVal) => prevVal + currVal)
        .toFixed(4);

      this.innerInfo = {
        country: countryName,
        bondCount: bondCount,
        baseline: baseline,
        portfolioShare: portfolioShare,
      };
    } else {
      this.innerInfo = {
        country: "_",
        bondCount: "_",
        baseline: "_",
        portfolioShare: "_",
      };
    }
  }

  get regions(): string[] {
    const allSovereignBonds = this.sovereignBonds;

    if (allSovereignBonds.length > 0) {
      const bonds = allSovereignBonds.map((s: SovereignBond) => s.country);

      if (this.map) {
        // Necessary or else the map won't trigger a redrawing
        this.map.setSelected("regions", bonds);
      }

      return bonds;
    }

    if (this.map) {
      this.map.clearSelectedRegions();
    }

    return [];
  }

  get sovereignBonds(): SovereignBond[] {
    return this.validSecurities
      .filter((s: Security) => s.type === SecurityType.SOVEREIGN_BOND)
      .map((s: Security) => s as SovereignBond);
  }

  ////zoom methods
  zoomIn(): void {
    this.map.setScale(
      this.map.scale * this.map.params.zoomStep,
      this.map.width / 2,
      this.map.height / 2,
      false,
      this.map.params.zoomAnimate
    );
  }

  zoomOut(): void {
    this.map.setScale(
      this.map.scale / this.map.params.zoomStep,
      this.map.width / 2,
      this.map.height / 2,
      false,
      this.map.params.zoomAnimate
    );
  }
}
