
import { Component, Vue } from "vue-property-decorator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BCol, BForm, BRow } from "bootstrap-vue";
import { ModalID } from "@/enums/ModalID";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixin/UserMixin";
import CSVExporterMixin from "@/mixin/CSVExporterMixin";
import MetricsMixin from "@/mixin/MetricsMixin";
import { MetricEventType } from "@/enums/MetricEventType";

@Component({
  components: { BaseButton, BaseModal, BRow, BCol, BForm },
})
export default class NewPortfolioModal extends mixins(
  Vue,
  UserMixin,
  CSVExporterMixin,
  MetricsMixin
) {
  private readonly id = ModalID.NEW_PORTFOLIO;

  createNewPortfolio(): void {
    this.$store.commit("setImportingBulkData", false);
    this.$store.commit("resetCurrentSecurityDataCalls");
    this.$store.commit("clearPortfolio");
    this.$store.commit("setPortfolioName", "New Portfolio");
    Vue.prototype.$modal.hide(this.id);
  }

  downloadPortfolioCSV(): void {
    if (this.isUserPremiumTier) {
      this.downloadSecuritiesCSV();
      this.sendMetric(MetricEventType.CLICK_CSV_DOWNLOAD);
    } else {
      Vue.prototype.$modal.show(ModalID.UPGRADE_MODAL);
    }
  }
}
