export default class FileHandler {
  static getFileOfEvent(event: Event): File {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    return files[0];
  }

  protected static downloadCSV(exportString: string, fileName: string): void {
    const encodedFile = encodeURI(exportString);
    const fixedEncodedURI = encodedFile.replaceAll("#", "%23");

    const href = `data:text/csv;charset=utf-8,${fixedEncodedURI}`;

    this.downloadTypedFile(href, fileName);
  }

  protected static downloadXLSX(exportData: BlobPart, fileName: string): void {
    const blob = new Blob([exportData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const href = URL.createObjectURL(blob);

    this.downloadTypedFile(href, fileName);
  }

  protected static downloadTypedFile(href: string, fileName: string): void {
    const anchor = document.createElement("a");

    anchor.setAttribute("href", href);
    anchor.setAttribute("download", fileName);
    anchor.click();
  }

  static createFileName(
    portfolioName: string,
    suffix = "",
    extension = ""
  ): string {
    const escapedName = portfolioName
      .replace(/\s/g, "_")
      .replace(/[^0-9a-zA-Z_]+/g, "");

    const dateString = this.createDateString();

    let fileName = `${dateString}_${escapedName}`;

    if (suffix !== "") {
      fileName += `_${suffix}`;
    }

    if (extension !== "") {
      fileName += extension;
    }

    return fileName;
  }

  private static createDateString(): string {
    const today = new Date();

    const month = `00${today.getMonth() + 1}`.slice(-2);

    const day = `00${today.getDate()}`.slice(-2);

    return `${today.getFullYear()}-${month}-${day}`;
  }

  static nameFromFile(file: File): string {
    return file.name.replaceAll("_", " ").split(".")[0];
  }
}
