import { Component, Vue } from "vue-property-decorator";
import Security from "@/models/security/Security";
import CSVExporter from "@/services/csv/CSVExporter";
import { SECURITIES_TYPE_MAP } from "@/models/security/SecurityType";
import NumberFormatter from "@/services/NumberFormatter";

@Component
export default class CSVExporterMixin extends Vue {
  downloadSecuritiesCSV(): void {
    const validSecurities = this.$store.state.validSecurities;
    const invalidSecurities = this.$store.state.invalidSecurities;
    const baseYear = this.$store.state.baseYear;
    const targetYear = this.$store.state.targetYear;
    const provider = this.$store.state.provider;
    const sspCode = this.$store.state.sspCode;

    const exportSecurities = validSecurities
      .concat(invalidSecurities)
      .map((security: Security) =>
        this.CSVExport(security, baseYear, targetYear, provider, sspCode)
      );
    const csv = CSVExporter.createCSV(exportSecurities);
    const portfolioName = this.$store.state.portfolioName;

    CSVExporter.executeDownload(csv, portfolioName, "securities");
  }

  //For CSVExport only and should not be used for Excel Export
  private CSVExport(
    security: Security,
    baseYear: number,
    targetYear: number,
    provider: string,
    sspCode: string
  ): Record<string, any> {
    const baseInformationObject: Record<string, any> = {
      isin: security.isin,
      errors: security.getErrorMessage(),
      class: SECURITIES_TYPE_MAP[security.type],
      name: this.addDoubleQuotesToString(security.companyName),
      market_value: security.marketValue,
      currency: security.currency,
      baseline_xdc_total: NumberFormatter.tempForExport(
        security.baselineXDC.total
      ),
      baseline_xdc_scope1: NumberFormatter.tempForExport(
        security.baselineXDC.scope1
      ),
      baseline_xdc_scope2: NumberFormatter.tempForExport(
        security.baselineXDC.scope2
      ),
      baseline_xdc_scope3: NumberFormatter.tempForExport(
        security.baselineXDC.scope3
      ),
      nace_code: security.naceCode,
      nace_sector: this.addDoubleQuotesToString(security.naceSector),
    };

    const scope3Categories =
      security.scope3Categories.length > 0
        ? `${security.scope3Categories}` + "/15"
        : "_";

    const disclosureObject = {
      scope1_calculation_method: security.scope1Method,
      scope2_market_based_method: security.scope2MarketBasedMethod,
      scope3_disclosed_categories:
        this.addDoubleQuotesToString(scope3Categories),
    };

    const configurationParameters = {
      base_year: baseYear,
      target_year: targetYear,
      provider_option: provider,
      ssp_code: sspCode,
    };

    return Object.assign(
      {},
      baseInformationObject,
      disclosureObject,
      configurationParameters
    );
  }

  addDoubleQuotesToString(value: string): string {
    return '"' + value + '"';
  }
}
