import NumberFormatter from "@/services/NumberFormatter";
import getOrElse from "@/models/ModelParserHelper";
import { XDC } from "@/models/XDC";
import { Status } from "@/enums/Status";

export default class PortfolioValues {
  baselineXDC: XDC;
  status: Status;

  constructor(status = Status.NONE, baselineXDC = new XDC()) {
    this.status = status;
    this.baselineXDC = baselineXDC;
  }

  static fromJson(jsonData: Record<string, any>): PortfolioValues {
    const isError = getOrElse(jsonData, "error", false);
    const status = isError ? Status.ERROR : Status.NONE;

    return new PortfolioValues(
      status,
      this.getXDCFromJson(jsonData, "baseline")
    );
  }

  private static getXDCFromJson(
    jsonObject: Record<string, any>,
    prefix: string
  ): XDC {
    // For the portfolio we just have the total value being sent from the Backend
    return new XDC(getOrElse(jsonObject, `${prefix}Total`, NaN));
  }

  // function is used in Excel Exporter
  exportToFile(
    baseYear: number,
    targetYear: number,
    provider: string,
    sspCode: string
  ): Array<Record<string, unknown>> {
    const baseInformationObject = {
      "Baseline Total": NumberFormatter.tempForExport(this.baselineXDC.total),
    };

    const configurationParameters = {
      "Base Year": baseYear,
      "Target Year": targetYear,
      "Provider Option": provider,
      "SSP Code": sspCode,
    };

    return [Object.assign({}, baseInformationObject, configurationParameters)];
  }
}
