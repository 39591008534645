import Security from "@/models/security/Security";
import { Status } from "@/enums/Status";
import { ErrorMessage } from "@/enums/ErrorMessage";
import { SecurityType } from "@/models/security/SecurityType";
import { XDC } from "@/models/XDC";

export default class ListedEquity extends Security {
  constructor(
    isin = "",
    companyName = "",
    naceCode = "",
    naceSector = "",
    baselineXDC = new XDC(),
    marketValue = NaN,
    marketValueUSD = NaN,
    currency = "",
    scope1Method = "",
    scope2MarketBasedMethod = "",
    scope3Categories = "",
    status = Status.NONE,
    statusMessage: ErrorMessage = ErrorMessage.NONE
  ) {
    super(
      SecurityType.LISTED_EQUITY,
      isin,
      companyName,
      naceCode,
      naceSector,
      baselineXDC,
      marketValue,
      marketValueUSD,
      currency,
      scope1Method,
      scope2MarketBasedMethod,
      scope3Categories,
      status,
      statusMessage
    );
  }

  static fromJson(jsonData: Record<string, any>): ListedEquity {
    const equity = new ListedEquity();
    equity.valuesFromJson(jsonData, SecurityType.LISTED_EQUITY);

    return equity;
  }
}
