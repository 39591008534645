export default class ISINValidator {
  static validate(isin: string): boolean {
    const isinRegexPattern = new RegExp(/^[A-Z]{2}[A-Z0-9]{9}[0-9]$/g);

    if ([...isin.matchAll(isinRegexPattern)].length === 0) {
      return false;
    }

    const s = ISINValidator._transformToBase10String(isin);

    return ISINValidator._luhnTest(s);
  }

  static _transformToBase10String(isin: string): string {
    let s = "";
    for (let i = 0; i < isin.length; i++) {
      const code = isin.charCodeAt(i);

      if (ISINValidator._isASCIINumber(code)) {
        s = s + String.fromCharCode(code);
      } else {
        s = s + ISINValidator._transformLetterToBase10(code).toString();
      }
    }

    return s;
  }

  static _isASCIINumber(c: number): boolean {
    return c >= 48 && c <= 57;
  }

  // In ASCII, A = 65 so to have A = 10 we decrease by 55.
  static _transformLetterToBase10(c: number): number {
    return c - 55;
  }

  static _luhnTest(number: string): boolean {
    let s1 = 0;
    let s2 = 0;
    const reversed = number.split("").reverse().join("");

    let oddDigit = true;
    for (let i = 0; i < reversed.length; i++) {
      // We decrease by 48 to get the actual integer corresponding to the ASCII value
      const code = reversed.charCodeAt(i);
      const digit = code - 48;

      //This is for odd digits, they are 1-indexed in the algorithm.
      if (oddDigit) {
        oddDigit = false;
        s1 += digit;
      } else {
        // Add 2 * digit for 0-4, add 2 * digit - 9 for 5-9.
        oddDigit = true;

        s2 += 2 * digit;

        if (digit >= 5) {
          s2 -= 9;
        }
      }
    }

    const sum = s1 + s2;
    const mod = sum % 10;

    return mod === 0;
  }
}
