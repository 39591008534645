import Security from "@/models/security/Security";
import { Status } from "@/enums/Status";
import { ErrorMessage } from "@/enums/ErrorMessage";
import { SecurityType } from "@/models/security/SecurityType";
import getOrElse from "@/models/ModelParserHelper";
import { XDC } from "@/models/XDC";

export default class SovereignBond extends Security {
  //2-Letter country code
  country: string;

  constructor(
    isin = "",
    companyName = "",
    naceCode = "",
    naceSector = "",
    baselineXDC = new XDC(),
    marketValue = NaN,
    marketValueUSD = NaN,
    currency = "",
    scope1Method = "",
    scope2MarketBasedMethod = "",
    scope3Categories = "",
    status = Status.NONE,
    statusMessage: ErrorMessage = ErrorMessage.NONE,
    country = ""
  ) {
    super(
      SecurityType.SOVEREIGN_BOND,
      isin,
      companyName,
      naceCode,
      naceSector,
      baselineXDC,
      marketValue,
      marketValueUSD,
      currency,
      scope1Method,
      scope2MarketBasedMethod,
      scope3Categories,
      status,
      statusMessage
    );

    this.country = country;
  }

  static fromJson(jsonData: Record<string, any>): SovereignBond {
    const equity = new SovereignBond();
    equity.valuesFromJson(jsonData, SecurityType.SOVEREIGN_BOND);

    const securityData = getOrElse(jsonData, "securityData", {});
    equity.country = getOrElse(securityData, "countryCode", "DE");

    return equity;
  }
}
