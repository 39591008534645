import jwt from "jsonwebtoken";
import Config from "@/constants/Config";

export default class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = "pmt-jwt";
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = "pmt-refreshToken";

  /***************
   * Verification
   ***************/

  public static isTokenValid(): boolean {
    const token = this.getToken();

    if (token === Config.DEV_TOKEN) {
      return true;
    } else if (token) {
      const tokenDecoded = jwt.decode(token);

      return (
        tokenDecoded &&
        tokenDecoded.exp * 1000 > new Date().getTime() &&
        tokenDecoded.email_verified
      );
    }

    return false;
  }

  public static hasRequiredRole(requiredRoles: Array<string> | null): boolean {
    if (Config.DEV_MODE) {
      return true;
    } else if (requiredRoles) {
      const userRoles = this.getRoles();

      return userRoles.some((role) => {
        return requiredRoles.includes(role);
      });
    } else {
      return true;
    }
  }

  public static isEmailVerified(): boolean {
    const token = this.getToken();

    if (token === Config.DEV_TOKEN) {
      return true;
    } else if (token) {
      const tokenDecoded = jwt.decode(token);

      return tokenDecoded && tokenDecoded.email_verified;
    }

    return false;
  }

  /************
   * Accessors
   ************/
  public static getRoles(): Array<string> {
    if (this.isTokenValid()) {
      const tokenDecoded = jwt.decode(this.getToken());

      if (tokenDecoded) {
        return tokenDecoded.roles ? tokenDecoded.roles : [];
      }
    }

    return [];
  }

  public static getRefreshHeader(): Record<string, any> {
    return { refreshToken: this.getCurrentRefreshToken() };
  }

  static getCurrentRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  /**************
   * Persistence
   **************/
  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }
}
