
import { Component, Vue } from "vue-property-decorator";
import Config from "@/constants/Config";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";

@Component({})
export default class ContactButton extends mixins(Vue, AssetMixin) {
  contactImage = this.imageUrl("contact");

  get ContactEmailAddress(): string {
    return Config.ContactEmailAddress;
  }

  get imageCss(): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + this.contactImage + ")",
      "mask-image": "url(" + this.contactImage + ")",
    };
  }
}
