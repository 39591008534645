import { Component, Vue } from "vue-property-decorator";

@Component
export default class CheckEmailMixin extends Vue {
  email = "";

  emailState: boolean | null = null;

  checkEmail(): void {
    if (!this.emailIsValid()) {
      this.emailState = false;
    } else {
      this.emailState = null;
    }
  }

  emailIsValid(): boolean {
    if (this.email === "") {
      return false;
    }

    return this.isEmailPatternValid(this.email);
  }

  isEmailPatternValid(maybeEmail: string): boolean {
    const emailRegexPattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const matches = maybeEmail.match(emailRegexPattern);

    return (matches && matches.length > 0) || false;
  }
}
