
import { Component, Prop, Vue } from "vue-property-decorator";
import Security from "@/models/security/Security";
import ActionButton from "@/pages/shared/components/buttons/ActionButton.vue";
import PortfolioValues from "@/models/PortfolioValues";
import FeedbackMixin from "@/mixin/FeedbackMixin";
import { mixins } from "vue-class-component";
import { BFormFile, BInputGroup } from "bootstrap-vue";
import AssetMixin from "../../../../mixin/AssetMixin";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { ModalID } from "@/enums/ModalID";
import CSVImporter from "@/services/csv/CSVImporter";
import { UploadError } from "@/enums/UploadError";
import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import { chunk, partition } from "lodash";
import Config from "@/constants/Config";
import ISINValidator from "@/services/ISINValidator";
import { ActionID } from "@/enums/ActionID";
import { MetricEventType } from "@/enums/MetricEventType";
import MetricsMixin from "@/mixin/MetricsMixin";

@Component({
  components: { ActionButton, BInputGroup, BFormFile, BaseButton },
})
export default class CSVImportAction extends mixins(
  Vue,
  FeedbackMixin,
  AssetMixin,
  MetricsMixin
) {
  @Prop({ required: false, default: false, type: Boolean })
  isInToolbar!: boolean;

  id = ActionID.CSV_UPLOAD;

  file = null;

  get baseYear(): number {
    return this.$store.state.baseYear;
  }

  uploadCsv(event: Event): void {
    const csvFile = CSVImporter.getFileOfEvent(event);

    if (!CSVImporter.isValidCSV(csvFile)) {
      this.showErrorMessage(UploadError.CSV_WRONG_EXTENSION_FILE);

      return;
    }

    this.$store.commit("setImportingBulkData", true);
    this.$store.dispatch("addLoadingPortfolioValues");
    this.openFeedbackPopUp();

    const portfolioName = CSVImporter.nameFromFile(csvFile);
    this.$store.commit("setPortfolioName", portfolioName);

    const previouslySeenISINs = this.$store.getters.allSecurities.map(
      (s: Security) => s.isin
    );
    const previouslyValidCount = this.$store.getters.validSecuritiesCount;
    const previouslyInvalidISINs = this.$store.state.invalidSecurities.map(
      (s: Security) => s.isin
    );

    // Call to parse all from file
    CSVImporter.getParsedNewSecurities(csvFile)
      .then((parsedSecurities) => {
        const [alreadyPresentSecurities, newSecurities] = partition(
          parsedSecurities,
          (security) => previouslySeenISINs.includes(security.isin)
        );

        const currencies = Object.keys(this.$store.state.currencies);

        const [correctFormatSecurities, incorrectFormatSecurities] = partition(
          newSecurities,
          (security) =>
            ISINValidator.validate(security.isin) &&
            currencies.indexOf(security.currency) != -1 &&
            typeof security.marketValue === "number" &&
            !Number.isNaN(security.marketValue)
        );

        // Update feedback
        this.showFeedback(
          correctFormatSecurities.length,
          alreadyPresentSecurities.map((s) => s.isin),
          previouslyValidCount,
          previouslyInvalidISINs,
          incorrectFormatSecurities.map((s) => s.isin),
          ""
        );

        // call backend
        // Work with non-existing ones. All the process happens asynchronously :)
        const securityRequestPayloads: SecurityRequestPayload[] =
          correctFormatSecurities.map(
            (security) =>
              new SecurityRequestPayload(
                security.isin,
                security.marketValue,
                security.currency,
                this.baseYear
              )
          );

        const chunks: SecurityRequestPayload[][] = chunk(
          securityRequestPayloads,
          Config.REQUEST_CHUNK_SIZE
        );

        if (chunks.length !== 0) {
          chunks.forEach((chunk) =>
            this.$store.dispatch("addSecurities", chunk)
          );
        } else {
          this.$store.commit("setImportingBulkData", false);
          this.$store.dispatch("calculatePortfolioValues");
        }

        this.sendMetric(MetricEventType.CLICK_CSV_UPLOAD);
      })
      .catch((reason) => this.showErrorMessage(reason));
  }

  private openFeedbackPopUp(): void {
    this.resetFeedback();
    Vue.prototype.$modal.show(ModalID.UPLOAD_FEEDBACK_MODAL);
  }

  private showErrorMessage(error: UploadError): void {
    const errorMessage = this.getErrorMessage(error);
    this.openFeedbackPopUp();
    this.showFeedback(0, [], 0, [], [], errorMessage);
    this.$store.commit("setImportingBulkData", false);
    this.$store.commit("setPortfolioValues", new PortfolioValues());
    this.$store.commit("setPortfolioName", "New Portfolio");
  }

  private getErrorMessage(error: UploadError): string {
    switch (error) {
      case UploadError.CSV_WRONG_EXTENSION_FILE:
        return (
          "The file uploaded is not a valid CSV file.<br/ > <br />Please refer to the FAQ page under " +
          '<strong>"How can I upload a portfolio?"</strong> for more information on what extension and format the file should be.'
        );
      case UploadError.CSV_MISSING_HEADER:
        return "The file does not appear to be in the correct format - some of the necessary Headers might be missing or incorrect.";
      case UploadError.CSV_INCORRECT_FORMAT:
        return (
          "The CSV file uploaded appears to be formatted incorrectly.<br/ > <br />Please refer to the sample files on the FAQ page under " +
          '<strong>"How can I upload a portfolio?"</strong> for more information on how the file should be formatted.'
        );
    }

    return "Unknown error";
  }
}
