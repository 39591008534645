
import { Component, Vue } from "vue-property-decorator";
import { BRow } from "bootstrap-vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import Config from "@/constants/Config";
import CSVImportAction from "@/pages/main/components/toolbar/CSVImportAction.vue";

@Component({
  components: { CSVImportAction, BaseButton, BRow },
})
export default class NoDataCard extends Vue {
  downloadTemplate(): void {
    const anchor = document.createElement("a");
    anchor.href = Config.PEMarketValueCSVTemplateLink;
    anchor.setAttribute("download", `pe_sample_file_with_market_value.csv`);

    anchor.click();
  }
}
