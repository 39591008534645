
import { Component, Vue } from "vue-property-decorator";
import PageButton from "@/pages/shared/components/buttons/PageButton.vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";

@Component({
  components: { PageButton },
})
export default class SignInButton extends mixins(Vue, AssetMixin) {}
