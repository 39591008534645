import Vue from "vue";
import App from "./pages/main/App.vue";
import "./registerServiceWorker";
import router from "./router/index";
import store from "./store/main";
import { ModalPlugin, TooltipPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import jsonwebtoken from "jsonwebtoken";
import HighchartsVue from "highcharts-vue";
import "./assets/scss/_styles.scss";
import VueMatomo from "vue-matomo";
import { MATOMO_LIVE_ENVIRONMENT, MatomoConfig } from "@/matomo/matomo-config";
import MatomoTrackHelper from "@/matomo/MatomoTrackHelper";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { version } from "../package.json";

/**
 * Start of Map import
 */
import VueVectorMap from "vuevectormap";
import ModalController from "@/services/ModalController";
import TokenStorage from "@/auth/services/TokenStorage";
import { STAFF } from "@/constants/Roles";

console.info("\nApplication started 👌. Version: " + version + "\n\n");

// (1) To be able to put the jsVectorMap into the window object
declare global {
  interface Window {
    jsVectorMap: any;
  }
}

// (2) This is to avoid an error that happens inside the World map otherwise

// eslint-disable-next-line @typescript-eslint/no-var-requires
const jsVectorMap = require("jsvectormap");
window.jsVectorMap = jsVectorMap;

//import the map used
require("jsvectormap/dist/maps/world");

// (3) finally this is the usage as a vue component
Vue.use(VueVectorMap, { map: "world" });
/**
 * End of Map import
 */

// Make ONLY the BootstrapVue Tooltip plugin available
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);

Vue.use(jsonwebtoken);

Vue.use(HighchartsVue);
Vue.component("VueSlider", VueSlider);

// Our self defined modal control method
Vue.prototype.$modal = new ModalController();

Vue.config.productionTip = false;

Vue.prototype.matomo = new MatomoTrackHelper(window);

if (
  process.env.VUE_APP_MATOMO_ENV === MATOMO_LIVE_ENVIRONMENT &&
  !TokenStorage.hasRequiredRole([STAFF]) // prevents tracking for not logged in staff users
) {
  Vue.use(VueMatomo, MatomoConfig);
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
