import TokenStorage from "@/auth/services/TokenStorage";
import Vue from "vue";
import Vuex from "vuex";
import AuthStoreState from "@/auth/store/AuthStoreState";

Vue.use(Vuex);

export const getAuthInitialState = (): AuthStoreState => {
  return {
    authenticated: TokenStorage.isTokenValid(),
  };
};

export const authState: AuthStoreState = getAuthInitialState();

export const authStoreGetters = {};

export const authStoreMutations = {
  setAuthState(state: AuthStoreState): void {
    state.authenticated = TokenStorage.isTokenValid();
  },
};

export const authStoreActions = {
  clearAuthState({ commit }: { commit: any }): void {
    TokenStorage.clear();
    commit("setAuthState");
  },
  logout({ dispatch }: { commit: any; dispatch: any }): void {
    dispatch("clearAuthState");
    dispatch("clearAllStores", null, { root: true });
  },
};

export const authStoreModule = {
  namespaced: true,
  state: authState,
  getters: authStoreGetters,
  mutations: authStoreMutations,
  actions: authStoreActions,
};
