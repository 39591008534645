
import { Component, Vue } from "vue-property-decorator";
import BaseAlert from "@/pages/main/components/base/BaseAlert.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import AssetMixin from "../../../../mixin/AssetMixin";
import { mixins } from "vue-class-component";
import ChangeLogModal from "@/pages/faq/modals/ChangeLogModal.vue";
import { ModalID } from "@/enums/ModalID";
import { AlertID } from "@/enums/AlertID";

@Component({
  components: {
    BaseAlert,
    BaseButton,
    ChangeLogModal,
  },
})
export default class ChangelogAvailableAlert extends mixins(Vue, AssetMixin) {
  private readonly alertID = AlertID.CHANGELOG_AVAILABLE;

  created(): void {
    document.addEventListener("showChangeLogs", () => this.showAlert());
  }

  showAlert(): void {
    (this.$refs[this.alertID] as BaseAlert).show();
  }

  closeAlert(): void {
    (this.$refs[this.alertID] as BaseAlert).hide();
  }

  openModal(): void {
    Vue.prototype.$modal.show(ModalID.CHANGELOG_MODAL);
    this.closeAlert();
  }
}
