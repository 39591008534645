import { SortDirection } from "@/enums/SortDirection";
import { State } from "@/store/main/State";
import { PortfolioTableState } from "@/store/portfolio/PortfolioTableState";

export const getInitialPortfolioState = (): PortfolioTableState => {
  return {
    currentlySortedColumn: "isin",
    currentSortDirection: SortDirection.ASCENDING,
  };
};

export const tableState: PortfolioTableState = getInitialPortfolioState();

export default {
  namespaced: true,
  state: tableState,
  getters: {},
  mutations: {
    clearState(state: State): void {
      Object.assign(state, getInitialPortfolioState());
    },
    updateCurrentlySortedColumn(
      state: PortfolioTableState,
      should: string
    ): void {
      state.currentlySortedColumn = should;
    },
    updateCurrentSortDirection(
      state: PortfolioTableState,
      should: SortDirection
    ): void {
      state.currentSortDirection = should;
    },
  },
  actions: {},
};
