
import { Component, Vue } from "vue-property-decorator";
import SecuritiesDeletionModal from "@/pages/main/components/toolbar/delete-security/SecuritiesDeletionModal.vue";
import PageButton from "@/pages/shared/components/buttons/PageButton.vue";
import NewSecurityModal from "@/pages/main/components/toolbar/new-security/NewSecurityModal.vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";
import SecuritiesDeletionAction from "@/pages/main/components/toolbar/delete-security/SecuritiesDeletionAction.vue";
import NewSecurityAction from "@/pages/main/components/toolbar/new-security/NewSecurityAction.vue";
import NewPortfolioModal from "@/pages/main/components/toolbar/new-portfolio/NewPortfolioModal.vue";
import NewPortfolioAction from "@/pages/main/components/toolbar/new-portfolio/NewPortfolioAction.vue";
import ConfigurationAction from "@/pages/main/components/toolbar/configuration/ConfigurationAction.vue";
import ExcelExportAction from "@/pages/main/components/toolbar/ExcelExportAction.vue";
import CSVImportAction from "@/pages/main/components/toolbar/CSVImportAction.vue";
import CSVExportAction from "@/pages/main/components/toolbar/CSVExportAction.vue";

@Component({
  components: {
    SecuritiesDeletionAction,
    NewSecurityAction,
    ConfigurationAction,
    NewPortfolioAction,
    CSVImportAction,
    CSVExportAction,
    ExcelExportAction,
    SecuritiesDeletionModal,
    PageButton,
    NewSecurityModal,
    NewPortfolioModal,
  },
})
export default class Toolbar extends mixins(Vue, AssetMixin) {
  readonly displayButtons: Record<string, any> = {
    Login: [],
    Landing: [],
    Overview: ["new", "downloadCSV", "upload", "downloadExcel", "settingsIcon"],
    Analytics: [
      "new",
      "add",
      "downloadCSV",
      "upload",
      "downloadExcel",
      "delete",
      "settingsIcon",
    ],
  };

  buttonsCount(): number {
    const currentRoute = this.$route.name;

    return (
      currentRoute &&
      Object.prototype.hasOwnProperty.call(this.displayButtons, currentRoute) &&
      this.displayButtons[currentRoute].length
    );
  }

  showMenu(): boolean {
    return this.buttonsCount() > 0;
  }

  showButton(buttonName: string): boolean {
    const currentRoute = this.$route.name;

    return (
      currentRoute && this.displayButtons[currentRoute].includes(buttonName)
    );
  }
}
