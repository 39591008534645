import AuthGateway from "@/auth/services/AuthGateway";
import Config from "@/constants/Config";
import { RestType } from "@/auth/config/RestType";
import Metric from "@/models/Metric";
import TokenStorage from "@/auth/services/TokenStorage";
import { STAFF } from "@/constants/Roles";

export default class MetricsGateway {
  static PORTFOLIO_GATEWAY_BASE_URL = Config.ApiGatewayUrl + "/api/v1";

  static sendMetricData(payload: Metric): any {
    const eventType = payload.type;
    const value = payload.value;

    if (Config.DEV_MODE || TokenStorage.hasRequiredRole([STAFF])) {
      return;
    } else {
      return AuthGateway.sendRequestWithAuth(
        this.PORTFOLIO_GATEWAY_BASE_URL,
        RestType.POST,
        "/pem",
        {},
        {
          eventType: eventType,
          value: value,
        }
      );
    }
  }
}
