import store from "@/store/main";
import Security from "@/models/security/Security";
import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import AuthGateway from "@/auth/services/AuthGateway";
import Config from "@/constants/Config";
import PortfolioValues from "@/models/PortfolioValues";
import { map } from "lodash";
import { SecurityType } from "@/models/security/SecurityType";
import ListedEquity from "@/models/security/ListedEquity";
import SovereignBond from "@/models/security/SovereignBond";
import getOrElse from "@/models/ModelParserHelper";
import { RestType } from "@/auth/config/RestType";
import UnknownSecurity from "@/models/security/UnknownSecurity";
import DevModeGateway from "@/services/DevModeGateway";
import { MetricEventType } from "@/enums/MetricEventType";
import MetricsGateway from "@/services/MetricsGateway";
import Metric from "@/models/Metric";
import TokenStorage from "@/auth/services/TokenStorage";
import { PAID_TIER_ROLE, STAFF } from "@/constants/Roles";

export default class PortfolioGateway {
  static PORTFOLIO_GATEWAY_BASE_URL = Config.ApiGatewayUrl + "/api/v1";

  static getSecurity(
    securityRequestPayloads: Array<SecurityRequestPayload>
  ): Promise<Array<Security>> {
    const { baseYear } = securityRequestPayloads[0];
    const targetYear = store.state.targetYear;
    const provider = store.state.provider;
    const sspCode = store.state.sspCode;
    const isins = securityRequestPayloads.map((s) => s.isin);
    const relevantSecurityInfos = securityRequestPayloads.map((security) => ({
      isin: security.isin,
      marketValue: security.marketValue,
      currency: security.currency,
    }));

    let backEndResponse;

    if (Config.DEV_MODE) {
      backEndResponse = DevModeGateway.getSecurities(isins);
    } else {
      backEndResponse = AuthGateway.sendRequestWithAuth(
        this.PORTFOLIO_GATEWAY_BASE_URL,
        RestType.POST,
        `/securities${
          TokenStorage.hasRequiredRole([PAID_TIER_ROLE, STAFF]) ? "" : "/free"
        }`,
        {},
        {
          baseYear: baseYear,
          targetYear: targetYear,
          provider: provider,
          sspCode: sspCode,
          securities: relevantSecurityInfos,
        }
      );
    }

    return backEndResponse
      .then((response: any) => {
        return map(response.data, (jsonSec) => {
          const securityData = getOrElse(jsonSec, "securityData", {});
          const type: SecurityType = getOrElse(
            securityData,
            "securityType",
            SecurityType.UNKNOWN
          );

          let sec: Security;

          if (type === SecurityType.LISTED_EQUITY) {
            sec = ListedEquity.fromJson(jsonSec);
          } else if (type === SecurityType.SOVEREIGN_BOND) {
            sec = SovereignBond.fromJson(jsonSec);
          } else {
            sec = UnknownSecurity.fromJson(jsonSec);
          }

          return sec;
        });
      })
      .catch((error) => {
        console.error(error);

        return map(securityRequestPayloads, (s) => Security.apiError(s));
      });
  }

  static getPortfolioInformation(
    baseYear: number,
    securities: Security[]
  ): Promise<PortfolioValues> {
    store.dispatch("addLoadingPortfolioValues");

    const securitiesRelevantInfos = securities
      .filter((s) => s.marketValue > 0)
      .map((s) => ({
        marketValue: s.marketValue,
        isin: s.isin,
        currency: s.currency,
      }));

    MetricsGateway.sendMetricData(
      new Metric(MetricEventType.PORTFOLIO_SIZE, securities.length)
    );

    if (securitiesRelevantInfos.length === 0) {
      // Returning a resolved promise since the payload will be empty.
      // Which would cause the model to return an error, since the portfolio would have no values
      return Promise.resolve(new PortfolioValues());
    }

    const targetYear = store.state.targetYear;
    const provider = store.state.provider;
    const sspCode = store.state.sspCode;

    let backEndResponse;

    if (Config.DEV_MODE) {
      backEndResponse = DevModeGateway.getPortfolio();
    } else {
      backEndResponse = AuthGateway.sendRequestWithAuth(
        this.PORTFOLIO_GATEWAY_BASE_URL,
        RestType.POST,
        `/portfolio${
          TokenStorage.hasRequiredRole([PAID_TIER_ROLE, STAFF]) ? "" : "/free"
        }`,
        {},
        {
          baseYear: baseYear,
          targetYear: targetYear,
          provider: provider,
          sspCode: sspCode,
          securities: securitiesRelevantInfos,
        }
      );
    }

    return backEndResponse
      .then((response) => {
        try {
          const portfolioValuesJson = response && response.data;

          return PortfolioValues.fromJson(portfolioValuesJson);
        } catch {
          throw Error(
            `Error: invalid response to add a portfolio values information ${response}, ${response}`
          );
        }
      })
      .catch((e) => {
        throw Error(
          "Error: invalid response to add a companies information:" + e
        );
      });
  }
}
