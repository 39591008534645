
import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../mixin/AssetMixin";
import { BFormInput, BInputGroup } from "bootstrap-vue";

@Component({
  components: {
    BInputGroup,
    BFormInput,
  },
})
export default class PasswordField extends mixins(Vue, AssetMixin) {
  @Prop({ required: false, default: "Password" }) placeHolder!: string;

  passwordHolder = "";

  pwdFieldType = "password";
  passwordImage = this.imageUrl("showPwd");

  togglePassword(): void {
    if (this.pwdFieldType === "password") {
      this.pwdFieldType = "text";
      this.passwordImage = this.imageUrl("hidePwd");
    } else {
      this.pwdFieldType = "password";
      this.passwordImage = this.imageUrl("showPwd");
    }
  }

  get imageCss(): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + this.passwordImage + ")",
      "mask-image": "url(" + this.passwordImage + ")",
    };
  }

  onInput(): void {
    this.$emit("valueChanged", this.passwordHolder);
  }
}
