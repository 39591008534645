import { BASE_YEARS, FREE_TIER_BASE_YEAR } from "@/constants/BaseYear";
import { PAID_TIER_ROLE } from "@/constants/Roles";
import PortfolioValues from "@/models/PortfolioValues";
import Security from "@/models/security/Security";
import CSVImportFeedback from "@/models/CSVImportFeedback";
import TokenStorage from "@/auth/services/TokenStorage";
import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import { Provider } from "@/models/Provider";
import { SSP } from "@/models/SSP";
import Currency from "@/enums/Currency";

export interface State {
  portfolioName: string;
  portfolioValues: PortfolioValues;
  importingBulkData: boolean;
  needsRecalculation: boolean;
  baseYear: number;
  targetYear: number;
  sspCode: string;
  provider: string;
  validSecurities: Array<Security>;
  invalidSecurities: Array<Security>;
  selectedSecurityISINs: Array<string>;
  hasErrorMessage: boolean;
  errorMessage: string;
  hasFeedbackMessage: boolean;
  feedbackMessage: string;
  ongoingDataRequests: number;
  companyRequestQueue: Array<SecurityRequestPayload>;
  csvImportFeedback: CSVImportFeedback;
  currencies: Record<string, Currency>;
}

function initialBaseYear(): number {
  return TokenStorage.hasRequiredRole([PAID_TIER_ROLE])
    ? BASE_YEARS[0]
    : FREE_TIER_BASE_YEAR;
}

export const getInitialState = (): State => {
  return {
    portfolioName: "New Portfolio",
    portfolioValues: new PortfolioValues(),
    importingBulkData: false,
    needsRecalculation: false,
    baseYear: initialBaseYear(),
    targetYear: 2050,
    sspCode: SSP.SSP2_RCP60.code,
    provider: Provider.IEA.displayName,
    validSecurities: Array<Security>(),
    invalidSecurities: Array<Security>(),
    selectedSecurityISINs: Array<string>(),
    hasErrorMessage: false,
    errorMessage: "",
    hasFeedbackMessage: false,
    feedbackMessage: "",
    ongoingDataRequests: 0,
    companyRequestQueue: [],
    csvImportFeedback: new CSVImportFeedback(0, [], 0, [], [], ""),
    currencies: {},
  };
};

export const state: State = getInitialState();
