
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { LoginRoute } from "@/router/Routes";
import { BCard, BCol, BRow } from "bootstrap-vue";

@Component({
  components: { BaseButton, BRow, BCol, BCard },
})
export default class CompleteVerification extends Vue {
  loginRoutePath = LoginRoute.path;
}
