
import { Chart } from "highcharts-vue";
import { Component, Vue } from "vue-property-decorator";
import Security from "@/models/security/Security";
import PortfolioSectorBreakdown from "@/models/PortfolioSectorBreakdown";
import Highcharts, {
  ChartOptions,
  FormatterCallbackFunction,
  Options,
  PlotPieOptions,
  Point,
  SeriesPieOptions,
  TooltipOptions,
} from "highcharts";
import { SectorInformation } from "@/models/SectorInformation";
import NumberFormatter from "@/services/NumberFormatter";
import BaseCard from "@/pages/overview/components/base/BaseCard.vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../mixin/AssetMixin";
import variablePieInit from "highcharts/modules/variable-pie";
import SecurityHelperMixin from "@/mixin/SecurityHelperMixin";

variablePieInit(Highcharts);

type TooltipPoint = Point | SectorInformation;

@Component({
  components: { BaseCard, highcharts: Chart },
})
export default class PortfolioSectorBreakdownCard extends mixins(
  Vue,
  AssetMixin,
  SecurityHelperMixin
) {
  sectorInformation: SectorInformation = new SectorInformation();

  formatNumber(value: number): string {
    return NumberFormatter.formatNumber(value);
  }

  formatString(value: string): string {
    return NumberFormatter.formatString(value);
  }

  get portfolioShare(): string {
    const sharePercentage =
      (100 / this.totalMarketValueUSDPercentage) *
      this.groupedSecuritiesValues[this.sectorInformation.twoDigitsNaceCode];

    return !isNaN(sharePercentage) ? sharePercentage.toFixed(4) : "";
  }

  get groupedSecurities(): Record<string, Array<Security>> {
    return PortfolioSectorBreakdown.groupSecuritiesBy(
      this.validSecuritiesWithScenarioGap,
      "naceCode",
      (key) => key.toString().substr(0, 2)
    );
  }

  get groupedSecuritiesValues(): Record<string, number> {
    return PortfolioSectorBreakdown.getGroupedSecuritiesValues(
      this.groupedSecurities
    );
  }

  get highchartsOptions(): Options {
    const chart: ChartOptions = {
      type: "pie",
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: "transparent",
      borderWidth: 0,
      plotBorderWidth: 0,
    };

    const pieOptions: PlotPieOptions = {
      allowPointSelect: false,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      description: "Portfolio Sector Breakdown Chart",
      size: "100%",
      slicedOffset: 3,
      states: {
        hover: {
          enabled: true,
          lineWidth: 2,
          borderColor: "#4C57A6",
          color: "#4C57A6",
        },
      },
    };

    const series: Array<SeriesPieOptions> = [
      {
        type: "pie",
        innerSize: "0",
        borderWidth: 0,
        data: this.segments.map((segment) => ({
          ...segment,
          color: "#e5e5ef33",
          borderColor: "#8B8ba2",
          borderWidth: 1,
        })),
        point: {
          events: {
            mouseOver: this.onMouseOver,
            mouseOut: () => (this.sectorInformation = new SectorInformation()),
          },
        },
      },
    ];

    const tooltip: TooltipOptions = {
      className: "tooltip-sector-break",
      useHTML: true,
      headerFormat: "",
      pointFormatter: this.formatTooltip,
      shadow: false,
    };

    return {
      chart,
      credits: { enabled: false },
      plotOptions: { pie: pieOptions },
      series,
      title: { text: "" },
      tooltip,
    };
  }

  get segments(): Array<Record<string, any>> {
    return PortfolioSectorBreakdown.getSegments(
      this.groupedSecurities,
      this.groupedSecuritiesValues
    );
  }

  // TODO New Metric: Gap Should be replaced by Baseline XDC
  get validSecuritiesWithScenarioGap(): Array<Security> {
    return this.validSecurities.filter((security: Security) => {
      return !isNaN(security.baselineXDC.total);
    });
  }

  mounted(): void {
    const image = document.querySelector("image");
    image?.setAttribute("preserveAspectRatio", "");
  }

  formatTooltip: FormatterCallbackFunction<TooltipPoint> = function () {
    const tooltipPoint = this as SectorInformation;
    let content = `<p class="bold no-margin p2-style white-standard">NACE ${tooltipPoint.twoDigitsNaceCode}</p>`;
    content += '<div class="p1-style bold no-margin white-standard">';
    content += `<span class="tooltip-sector-break-assets">${tooltipPoint.naceSecuritiesLength}</span>`;
    content += `<br>`;
    content += tooltipPoint.naceSecuritiesLength > 1 ? " Assets" : " Asset";
    content += "</div>";

    return content;
  };

  onMouseOver(event: Event): void {
    const { twoDigitsNaceCode, naceSector, naceSecuritiesLength } =
      event.target as EventTarget & SectorInformation;
    this.sectorInformation = new SectorInformation(
      twoDigitsNaceCode,
      naceSector,
      naceSecuritiesLength
    );
  }
}
