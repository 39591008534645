
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CheckEmailMixin from "@/mixin/CheckEmailMixin";
import { BInputGroup, BFormInput, BFormInvalidFeedback } from "bootstrap-vue";

@Component({
  components: {
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
  },
})
export default class EmailField extends mixins(Vue, CheckEmailMixin) {
  onInput(): void {
    this.$emit("valueChanged", this.email);
  }
}
