import Security from "@/models/security/Security";
import PortfolioValues from "@/models/PortfolioValues";
import Excel, { BorderStyle, Cell, Workbook, Worksheet } from "exceljs";
import {
  capitalize,
  DataSheetHeadingRowHeight,
  EXCEL_RIGHT_FONT,
  EXCEL_RIGHT_FONT_COLOR,
  EXCEL_RIGHT_FONT_SIZE_BIG,
  EXCEL_RIGHT_FONT_SIZE_DEFAULT,
  EXCEL_RIGHT_FONT_SIZE_MEDIUM,
  EXCEL_RIGHT_FONT_SIZE_SMALL,
  EXCEL_RIGHT_GREEN,
  EXCEL_RIGHT_RED,
  OverviewSheetColumnWidths,
  OverviewSheetHeadingRowHeight,
  OverviewSheetZoomFactor,
} from "@/services/excel/ExcelStyle";
import NumberFormatter from "@/services/NumberFormatter";
import FileHandler from "@/services/FileHandler";

export class ExcelExporter extends FileHandler {
  private readonly baseYear: number;
  private readonly targetYear: number;
  private readonly provider: string;
  private readonly sspCode: string;

  constructor(
    baseYear: number,
    targetYear: number,
    provider: string,
    sspCode: string
  ) {
    super();
    this.baseYear = baseYear;
    this.targetYear = targetYear;
    this.provider = provider;
    this.sspCode = sspCode;
  }

  executeDownload(
    data: BlobPart,
    portfolioName: string,
    fileNameSuffix = ""
  ): void {
    const fileName = ExcelExporter.createFileName(
      portfolioName,
      fileNameSuffix,
      ".xlsx"
    );

    ExcelExporter.downloadXLSX(data, fileName);
  }

  public async createExcelExport(
    securities: Security[],
    portfolio: PortfolioValues
  ): Promise<Workbook> {
    const workbook = new Excel.Workbook();

    workbook.creator = "Right. based on Science";
    workbook.created = new Date();
    workbook.title = "Portfolio Export";

    this.createSecuritiesSheet(workbook, securities);

    this.createOverviewSheet(workbook, portfolio);

    return workbook;
  }

  createSecuritiesSheet(workbook: Workbook, securities: Security[]): void {
    const rawData: Array<Record<string, any>> = securities.map(
      (security: Security) => {
        return security.export(
          this.baseYear,
          this.targetYear,
          this.provider,
          this.sspCode
        );
      }
    );

    this.createDataSheet(workbook, rawData, "Securities");
  }

  createDataSheet(
    workbook: Workbook,
    exportData: Array<Record<string, any>>,
    title: string
  ): void {
    // rows may contain undefined columns, hence a random row as header reference could miss some headers,
    // hence we use the row with most entries as header reference
    const sortedExportData = exportData.sort((a, b) => {
      return b.length - a.length;
    });
    const referenceHeaderRow = sortedExportData[0];

    const header = Object.keys(referenceHeaderRow).map((key) => {
      return capitalize(key);
    });

    const data = exportData.map((row: any) => {
      const rowValues: Array<any> = [];
      header.forEach((key, index) => {
        rowValues[index] = row[key] !== undefined ? row[key] : "_";
      });

      return rowValues;
    });

    const sheet = workbook.addWorksheet(title);

    //Adding Header Row
    const headerRow = sheet.addRow(header);
    headerRow.eachCell((cell: Cell, number) => {
      cell.font = {
        bold: true,
        color: { argb: EXCEL_RIGHT_FONT_COLOR },
        size: EXCEL_RIGHT_FONT_SIZE_MEDIUM,
      };
      sheet.getColumn(number).width = DataSheetHeadingRowHeight;
    });

    data.forEach((d) => {
      const row = sheet.addRow(d);
      row.eachCell((cell: Cell) => {
        cell.style = {
          font: {
            name: EXCEL_RIGHT_FONT,
            size: EXCEL_RIGHT_FONT_SIZE_SMALL,
            color: { argb: EXCEL_RIGHT_FONT_COLOR },
          },
        };
      });
    });
  }

  createPortfolioValuesTable(
    worksheet: Worksheet,
    portfolioValues: PortfolioValues
  ): void {
    const baselineXDC = portfolioValues.baselineXDC.total;

    this.fillCell(worksheet, "H5", "Portfolio XDC", {
      name: EXCEL_RIGHT_FONT,
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      color: { argb: EXCEL_RIGHT_FONT_COLOR },
      bold: true,
    });

    this.applyBottomBorderToCellRange(
      worksheet,
      "B6:Q6",
      EXCEL_RIGHT_FONT_COLOR,
      "thin"
    );

    this.createMergedCellBlock(
      worksheet,
      "G10:I10",
      NumberFormatter.temperature(baselineXDC),
      {
        size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
        bold: true,
      }
    );

    this.applyBottomBorderToCellRange(
      worksheet,
      "G11:I11",
      EXCEL_RIGHT_FONT_COLOR,
      "thin"
    );

    this.createMergedCellBlock(worksheet, "G11:I11", "Baseline XDC", {
      size: EXCEL_RIGHT_FONT_SIZE_SMALL,
    });

    this.createMergedCellBlock(worksheet, "E14", "Base Year", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
    });

    this.createMergedCellBlock(worksheet, "E15", this.baseYear.toString(), {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    this.createMergedCellBlock(worksheet, "G14", "Target Year", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
    });

    this.createMergedCellBlock(worksheet, "G15", this.targetYear.toString(), {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    this.createMergedCellBlock(worksheet, "I14", "Provider", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
    });

    this.createMergedCellBlock(worksheet, "I15", this.provider.toString(), {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    this.createMergedCellBlock(worksheet, "K14", "SSP Code", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
    });

    this.createMergedCellBlock(worksheet, "K15", this.sspCode.toString(), {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    this.createMergedCellBlock(worksheet, "E19", "1.5 °C Aligned", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    const alignmentColorFor150 = this.alignmentColor(baselineXDC, 1.5);
    this.applyColorFillToCellRange(worksheet, "E21:E22", alignmentColorFor150);

    this.createMergedCellBlock(worksheet, "H19", "1.75°C Aligned", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    const alignmentColorFor175 = this.alignmentColor(baselineXDC, 1.75);
    this.applyColorFillToCellRange(worksheet, "H21:H22", alignmentColorFor175);

    this.createMergedCellBlock(worksheet, "K19", "2.0°C Aligned", {
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: true,
    });

    const alignmentColorFor200 = this.alignmentColor(baselineXDC, 2);
    this.applyColorFillToCellRange(worksheet, "K21:K22", alignmentColorFor200);
  }

  public createOverviewSheet(
    workbook: Workbook,
    portfolioValues: PortfolioValues
  ): void {
    const worksheet: Worksheet = workbook.addWorksheet(`Overview`);
    worksheet.views = [
      {
        zoomScale: OverviewSheetZoomFactor,
        showGridLines: false,
      },
    ];

    // column widths for columns A-Q
    OverviewSheetColumnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    this.createSheetHeading(worksheet, "Portfolio Overview");
    this.createPortfolioValuesTable(worksheet, portfolioValues);
  }

  createSheetHeading(worksheet: Worksheet, heading: string): void {
    this.fillCell(worksheet, "B2", heading, {
      name: EXCEL_RIGHT_FONT,
      color: { argb: EXCEL_RIGHT_GREEN },
      bold: true,
      size: EXCEL_RIGHT_FONT_SIZE_BIG,
    });

    worksheet.getRow(3).height = OverviewSheetHeadingRowHeight;
    worksheet.getRow(3).alignment = {
      vertical: "top",
    };

    this.applyBottomBorderToCellRange(
      worksheet,
      "B3:Q3",
      EXCEL_RIGHT_GREEN,
      "medium"
    );
  }

  createMergedCellBlock(
    worksheet: Worksheet,
    adressRange: string,
    value: string,
    style: Record<string, any> = {
      color: EXCEL_RIGHT_FONT_COLOR,
      size: EXCEL_RIGHT_FONT_SIZE_DEFAULT,
      bold: false,
    }
  ): void {
    worksheet.mergeCells(adressRange);
    const firstCell = adressRange.split(":")[0];
    worksheet.getCell(firstCell).value = value;
    worksheet.getCell(firstCell).style = {
      font: {
        name: EXCEL_RIGHT_FONT,
        size: style.size,
        color: { argb: style.color },
        bold: style.bold,
      },
    };
    worksheet.getCell(firstCell).alignment = {
      horizontal: "center",
    };
  }

  selectCellRange(sheet: Worksheet, rangeCell: string): Cell[] {
    const [startCell, endCell] = rangeCell.split(":");

    const [endCellColumn, endRow] = endCell.match(
      /[a-z]+|[^a-z]+/gi
    ) as string[];
    const [startCellColumn, startRow] = startCell.match(
      /[a-z]+|[^a-z]+/gi
    ) as string[];

    const endColumn = sheet.getColumn(endCellColumn);
    const startColumn = sheet.getColumn(startCellColumn);

    if (!endColumn) throw new Error("End column not found");

    if (!startColumn) throw new Error("Start column not found");

    const endColumnNumber = endColumn.number;
    const startColumnNumber = startColumn.number;

    const cells = [];
    for (let y = parseInt(startRow); y <= parseInt(endRow); y++) {
      const row = sheet.getRow(y);

      for (let x = startColumnNumber; x <= endColumnNumber; x++) {
        cells.push(row.getCell(x));
      }
    }

    return cells;
  }

  applyBottomBorderToCellRange(
    sheet: Worksheet,
    range: string,
    color: string,
    style: BorderStyle | undefined
  ): void {
    const cellRange = this.selectCellRange(sheet, range);
    cellRange.forEach((cell: Cell) => {
      cell.border = {
        bottom: { style: style, color: { argb: color } },
      };
    });
  }

  applyColorFillToCellRange(
    sheet: Worksheet,
    range: string,
    color: string
  ): void {
    const cellRange = this.selectCellRange(sheet, range);
    cellRange.forEach((cell: Cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "darkTrellis",
        fgColor: { argb: color },
        bgColor: { argb: color },
      };
    });
  }

  fillCell(
    worksheet: Worksheet,
    address: string,
    value: string,
    font: Record<any, any>,
    alignment: Record<any, any> | null = null
  ): void {
    worksheet.getCell(address).value = value;
    worksheet.getCell(address).font = font;

    if (alignment) {
      worksheet.getCell(address).alignment = alignment;
    }
  }

  alignmentColor(baselineXDC: number, value: number): string {
    if (baselineXDC <= value) return EXCEL_RIGHT_GREEN;

    return EXCEL_RIGHT_RED;
  }
}
