export class XDC {
  total: number;
  scope1: number;
  scope2: number;
  scope3: number;

  constructor(total = NaN, scope1 = NaN, scope2 = NaN, scope3 = NaN) {
    this.total = total;
    this.scope1 = scope1;
    this.scope2 = scope2;
    this.scope3 = scope3;
  }
}
