export const EXCEL_RIGHT_GREEN = "00C28A";
export const EXCEL_RIGHT_FONT_COLOR = "575756";
export const EXCEL_RIGHT_RED = "C20044";
export const EXCEL_RIGHT_FONT = "New Gothic MT";

export const EXCEL_RIGHT_FONT_SIZE_SMALL = 10;
export const EXCEL_RIGHT_FONT_SIZE_MEDIUM = 12;
export const EXCEL_RIGHT_FONT_SIZE_DEFAULT = 14;
export const EXCEL_RIGHT_FONT_SIZE_BIG = 20;

export const DataSheetHeadingRowHeight = 15;

export const OverviewSheetColumnWidths = [
  12, 15, 15, 8, 8, 8, 8, 8, 8, 8, 8, 9, 8, 8, 8, 8,
];

export const OverviewSheetZoomFactor = 85;

export const OverviewSheetHeadingRowHeight = 21;

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
