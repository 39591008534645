
import { Component, Vue } from "vue-property-decorator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BCol, BModal, BRow } from "bootstrap-vue";
import { PlansRoute } from "@/router/Routes";
import { ModalID } from "@/enums/ModalID";

@Component({
  components: {
    BaseModal,
    BaseButton,
    BRow,
    BCol,
    BModal,
  },
})
export default class UpgradeModal extends Vue {
  private readonly id = ModalID.UPGRADE_MODAL;
  plansRouterPath = PlansRoute.path;

  close(): void {
    Vue.prototype.$modal.hide(ModalID.UPGRADE_MODAL);
  }
}
