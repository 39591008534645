
import { Component, Prop, Vue } from "vue-property-decorator";
import { BButton, BTooltip } from "bootstrap-vue";

@Component({ components: { BButton, BTooltip } })
export default class ActionButton extends Vue {
  @Prop({ required: true }) imageUrl!: string;
  @Prop({ required: false, default: "24" }) iconSize!: string;
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: "" }) tooltip!: string;
  @Prop({ required: true, type: String }) actionId!: string;

  get cssVariables(): Record<string, string> {
    return {
      /* variables you want to pass to css */
      "--iconSize": this.iconSize + "px",
    };
  }

  get imageCss(): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + this.imageUrl + ")",
      "mask-image": "url(" + this.imageUrl + ")",
    };
  }
}
