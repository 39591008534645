import Config from "@/constants/Config";

export default class ParsedSecurity {
  isin: string;
  marketValue: number;
  currency: string;

  constructor(isin: string, marketValue: number | string, currency: string) {
    this.isin = isin.trim();
    this.marketValue = marketValue ? +marketValue : 0;
    this.currency = currency ? currency.trim() : Config.DEFAULT_CURRENCY;
  }
}
