
import { Component, Vue } from "vue-property-decorator";
import BaseDropdownMenu from "@/pages/main/components/base/BaseDropdownMenu.vue";
import DropdownMenuItem from "@/pages/shared/components/buttons/DropdownMenuItem.vue";
import { AnalyticsRoute, LandingRoute, OverviewRoute } from "@/router/Routes";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../mixin/AssetMixin";
import UserMixin from "@/mixin/UserMixin";

@Component({
  components: { DropdownMenuItem, BaseDropdownMenu },
})
export default class NavDropdownMenu extends mixins(
  Vue,
  AssetMixin,
  UserMixin
) {
  landingRoutePath = LandingRoute.path;
  overviewRoutePath = OverviewRoute.path;
  analyticsRoutePath = AnalyticsRoute.path;

  get currentPath(): string {
    return this.$route.path;
  }
}
