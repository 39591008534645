
import { Component, Vue } from "vue-property-decorator";
import ISINValidator from "@/services/ISINValidator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import Config from "@/constants/Config";
import {
  BCol,
  BForm,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BRow,
} from "bootstrap-vue";
import { ModalID } from "@/enums/ModalID";
import CurrencyDropdown from "@/pages/shared/components/CurrencyDropdown.vue";
import VueNumeric from "vue-numeric";
import { MetricEventType } from "@/enums/MetricEventType";
import { mixins } from "vue-class-component";
import MetricsMixin from "@/mixin/MetricsMixin";

@Component({
  components: {
    BaseModal,
    BaseButton,
    BCol,
    BRow,
    BForm,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    CurrencyDropdown,
    VueNumeric,
  },
})
export default class NewSecurityModal extends mixins(Vue, MetricsMixin) {
  private readonly id = ModalID.NEW_SECURITY;

  thousandsSeparator = Config.SEPARATOR_THOUSAND;
  decimalSeparator = Config.SEPARATOR_DECIMAL;
  decimalPrecision = Config.PRECISION_DECIMAL;
  selectedCurrency = "";
  marketValue = 0;
  isin = "";

  isinState: boolean | null = null;

  submitAddSecurityForm(): void {
    if (this.validateInput) {
      const baseYear = this.$store.state.baseYear;

      const securityRequestPayload = new SecurityRequestPayload(
        this.isin,
        this.marketValue,
        this.selectedCurrency,
        baseYear
      );
      this.$store.dispatch("addSecurities", [securityRequestPayload]);
      this.resetForm();
      this.sendMetric(MetricEventType.CLICK_ADD_SINGLE_ISIN);
    }
  }

  updateSelectedCurrency(newValue: string): void {
    this.selectedCurrency = newValue;
  }

  updateISINState(): void {
    if (this.inputISINIsValid) {
      this.isinState = null;
    } else {
      this.isinState = false;
    }
  }

  get inputISINIsValid(): boolean {
    return this.isISINValid && !this.isISINAlreadyInPortfolio;
  }

  get validateInput(): boolean {
    return this.inputISINIsValid && this.selectedCurrency !== "";
  }

  get isISINValid(): boolean {
    if (this.isin.trim() === "") {
      return false;
    }

    return ISINValidator.validate(this.isin);
  }

  get isISINAlreadyInPortfolio(): boolean {
    const allSecurities = [
      ...this.$store.state.validSecurities,
      ...this.$store.state.invalidSecurities,
    ];
    const idx = allSecurities.findIndex(
      (security: { isin: string }) => security.isin === this.isin
    );

    return idx !== -1;
  }

  resetForm(): void {
    this.isin = "";
    this.selectedCurrency = "";
    this.marketValue = 0.0;
    this.isinState = null;
  }
}
