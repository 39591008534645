import { Component, Vue } from "vue-property-decorator";
import TokenStorage from "@/auth/services/TokenStorage";
import { FREE_TIER_ROLE, MODULE_SB, PAID_TIER_ROLE } from "@/constants/Roles";
import Config from "@/constants/Config";

@Component
export default class UserMixin extends Vue {
  get isUserAuthenticated(): boolean | null {
    return this.$store.state.auth.authenticated;
  }

  get isUserFreeTier(): boolean {
    if (Config.DEV_MODE) {
      return false;
    }

    return (
      TokenStorage.hasRequiredRole([FREE_TIER_ROLE]) &&
      !TokenStorage.hasRequiredRole([PAID_TIER_ROLE])
    );
  }

  get isUserPremiumTier(): boolean {
    if (Config.DEV_MODE) {
      return true;
    }

    return TokenStorage.hasRequiredRole([PAID_TIER_ROLE]);
  }

  get userHasSovereignBondModule(): boolean {
    if (Config.DEV_MODE) {
      return true;
    }

    return TokenStorage.hasRequiredRole([MODULE_SB]);
  }
}
