import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import TokenStorage from "@/auth/services/TokenStorage";
import AuthConfig from "@/auth/config/AuthConfig";
import { RestType } from "@/auth/config/RestType";
import store from "@/store/main";

export default class AuthGateway {
  static sendRequestWithAuth(
    baseUrl = "",
    type: RestType,
    url: string,
    headers: Record<string, string> = {},
    data: Record<string, unknown> = {}
  ): Promise<AxiosResponse> {
    if ([RestType.GET, RestType.POST].includes(type)) {
      headers["Content-Type"] = "application/json";
    }

    if (TokenStorage.isTokenValid()) {
      return this._sendRequestWithAuth(baseUrl, type, url, headers, data);
    }

    return this.refreshToken()
      .then(() => {
        return this._sendRequestWithAuth(baseUrl, type, url, headers, data);
      })
      .catch((error: AxiosResponse) => {
        throw error;
      });
  }

  static sendRequest(
    baseUrl = "",
    type: RestType,
    url: string,
    headers: Record<string, string> = {},
    data: Record<string, any> = {}
  ): Promise<AxiosResponse> {
    const httpRequest = this._createRequest(headers, baseUrl);

    return this._executeRequest(httpRequest, type, url, data);
  }

  private static _sendRequestWithAuth(
    baseUrl: string,
    type: RestType,
    url: string,
    headers: Record<string, string>,
    data: Record<string, unknown>
  ): Promise<AxiosResponse> {
    const headersWithAuth: Record<string, string> = {
      ...headers,
      ...{ Authorization: "Bearer " + TokenStorage.getToken() },
    };

    const httpRequest = this._createRequest(headersWithAuth, baseUrl);

    return this._executeRequest(httpRequest, type, url, data);
  }

  static refreshToken(): Promise<void> {
    return this._createRequest({
      "X-FusionAuth-TenantId": AuthConfig.AUTH_TENANT_ID,
    })
      .post(AuthConfig.JWTRefreshPath, TokenStorage.getRefreshHeader())
      .then((response: AxiosResponse) => {
        const tokenJson: Record<string, any> = response.data;
        const { refreshToken, token } = tokenJson;

        TokenStorage.storeRefreshToken(refreshToken);
        TokenStorage.storeToken(token);

        store.commit("auth/setAuthState");
      })
      .catch((error) => {
        store.dispatch("auth/clearAuthState");

        return error;
      });
  }

  private static _createRequest(
    headers: Record<string, string> = {},
    baseUrl = ""
  ): AxiosInstance {
    return axios.create({
      baseURL: baseUrl === "" ? AuthConfig.AUTH_GATEWAY_BASE_URL : baseUrl,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json;  charset=utf-8",
        ...headers,
      },
    });
  }

  private static _executeRequest(
    httpRequest: AxiosInstance,
    type: RestType,
    url: string,
    body: Record<string, any>
  ): Promise<AxiosResponse> {
    const requests: Record<RestType, () => Promise<AxiosResponse>> = {
      get: () => httpRequest.get(url),
      post: () => httpRequest.post(url, body),
      put: () => httpRequest.put(url, body),
      delete: () => httpRequest.delete(url),
    };

    return requests[type]()
      .then((response) => {
        return response;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  }
}
