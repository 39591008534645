import { register } from "register-service-worker";

const currentEnv = process.env.NODE_ENV ? process.env.NODE_ENV : "development";

if (currentEnv === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      const currentVersion = process.env.VUE_APP_VERSION || "0";
      const lastSeenVersion = localStorage.getItem("lastSeenVersion") || "";

      if (currentVersion !== lastSeenVersion) {
        localStorage.setItem("lastSeenVersion", currentVersion);
        document.dispatchEvent(new CustomEvent("showChangeLogs"));
      }

      console.info("App is being served from cache by a service worker.");
    },
    registered() {
      console.info("Service worker has been registered.");
    },
    cached() {
      console.info("Content has been cached for offline use.");
    },
    updatefound() {
      console.info("New content is downloading.");
    },
    updated(registration: ServiceWorkerRegistration) {
      console.info("New content is available; please refresh.");
      document.dispatchEvent(
        new CustomEvent("appUpdated", { detail: registration })
      );
    },
    offline() {
      console.warn(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
