
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PageButton extends Vue {
  @Prop({ required: true }) imageUrl!: string;
  @Prop({ required: true }) routeUrl!: string;
  @Prop({ required: false, default: false }) selected!: boolean;
  @Prop({ required: false, default: false }) raised!: boolean;
  @Prop({ required: false, default: false }) homeIcon!: boolean;
  @Prop({ required: false, default: "" }) iconTitle!: string;

  get title(): string {
    if (this.iconTitle !== "") {
      return this.iconTitle;
    }

    return this.routeUrl.slice(1).trim(); //css capitalizes
  }

  get isSelected(): boolean {
    return this.selected;
  }

  get cssVariables(): Record<string, string> {
    return {
      "--iconSize": "50px",
    };
  }

  get imageCss(): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + this.imageUrl + ")",
      "mask-image": "url(" + this.imageUrl + ")",
    };
  }

  routeTo(): void {
    if (this.$route.path !== this.routeUrl) {
      this.$router.push(this.routeUrl);
    }
  }
}
