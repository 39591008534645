import { Vue } from "vue-property-decorator";

export default class ModalController {
  private registeredIds: Array<string> = [];

  eventBus = new Vue();

  register(id: string): void {
    this.registeredIds.push(id);
  }

  show(id: string): void {
    if (this.registeredIds.includes(id)) {
      this.eventBus.$emit("show", id);
    }
  }

  hide(id: string): void {
    if (this.registeredIds.includes(id)) {
      this.eventBus.$emit("hide", id);
    }
  }
}
