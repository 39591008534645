var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseCard',{staticClass:"top-securities-card",attrs:{"id":"top-securities-card","dense":true}},[_c('div',{staticClass:"top-securities-card-content"},[_c('div',[_c('h4',{staticClass:"gray-inactive title-holder"},[_c('span',{staticClass:"best",class:{ 'green-standard': !_vm.showWorst },on:{"click":function($event){_vm.showWorst = false}}},[_vm._v(" BEST ")]),_c('span',{staticClass:"worst",class:{ 'red-standard': _vm.showWorst },on:{"click":function($event){_vm.showWorst = true}}},[_vm._v(" WORST ")])])]),_c('table',{staticClass:"company-xdc-gap-table"},[_c('thead',[_c('tr',{staticClass:"table-head"},[_c('th',{key:"index",class:[
              'table-index',
              'bold p2-style',
              _vm.showWorst ? 'red-standard' : 'green-standard',
            ]},[_vm._v(" # ")]),_c('th',{key:"companyName",staticClass:"company-name bold p2-style"},[_vm._v("Name")]),_c('th',{key:"baselineXDC",staticClass:"baseline-xdc bold p2-style"},[_vm._v(" Baseline XDC ")])])]),_c('tbody',_vm._l((_vm.topSecurities),function(topSecurity,index){return _c('tr',{key:index,staticClass:"table-row"},[_c('td',{class:[
              'table-index',
              'bold',
              'p2-style',
              _vm.showWorst ? 'red-standard' : 'green-standard',
            ]},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"company-name bold p1-style",attrs:{"title":topSecurity.companyName}},[_vm._v(" "+_vm._s(topSecurity.companyName)+" ")]),_c('td',{class:['baseline-xdc', 'bold', 'h4-style']},[_vm._v(" "+_vm._s(topSecurity.baselineXDC.total.toFixed(1))+" °C ")])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }