
import { Component, Prop, Vue } from "vue-property-decorator";
import { LoginRoute } from "@/router/Routes";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";

@Component({
  components: {
    BaseButton,
  },
})
export default class AuthCard extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  withBackLink!: boolean;

  loginRoutePath = LoginRoute.path;
}
