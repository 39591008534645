import Config from "@/constants/Config";

export default class AuthConfig {
  static AUTH_GATEWAY_BASE_URL = Config.AuthGatewayUrl;
  static AUTH_APPLICATION_ID = Config.AuthApplicationId;
  static AUTH_TENANT_API_KEY = Config.AuthTenantApiKey;
  static AUTH_TENANT_ID = Config.AuthTenantId;

  static JWTRefreshPath = "/api/jwt/refresh";
  static RegistrationPath = "/api/user/registration/";
}
