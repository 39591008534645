import { GetterTree } from "vuex";
import { State } from "./State";
import Security from "@/models/security/Security";

export const getters: GetterTree<State, State> = {
  validSecuritiesCount(state: State): number {
    return state.validSecurities.length;
  },
  invalidSecuritiesCount(state: State): number {
    return state.invalidSecurities.length;
  },
  totalSecuritiesCount(state: State): number {
    return state.validSecurities.length + state.invalidSecurities.length;
  },
  selectedSecuritiesCount(state: State): number {
    return state.selectedSecurityISINs.length;
  },
  allSecurities(state: State): Array<Security> {
    return [...state.validSecurities, ...state.invalidSecurities];
  },
};
