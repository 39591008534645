
import { Component, Vue } from "vue-property-decorator";
import { BCol, BRow } from "bootstrap-vue";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../mixin/AssetMixin";

@Component({ components: { BRow, BCol } })
export default class Footer extends mixins(Vue, AssetMixin) {
  currentYear(): number {
    return new Date().getFullYear();
  }
}
