
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AssetMixin from "@/mixin/AssetMixin";
import Currency from "@/enums/Currency";
import { BFormInput, BFormSelectOption } from "bootstrap-vue";

@Component({
  components: {
    BFormInput,
    BFormSelectOption,
  },
})
export default class CurrencyDropdown extends mixins(Vue, AssetMixin) {
  @Prop({ default: "50" }) dropdownMinWidth!: string;
  @Prop({ default: "" }) currentCurrency!: string;
  @Prop({ default: false }) isDropdownSelected!: boolean;

  topFiveCurrencies: Currency[] = [];
  allCurrencies: Currency[] = Object.values(this.$store.state.currencies);
  currencySelected = false;

  isDropdownOpen = false;
  visibilityClass = "dropdown-closed";
  selectedCurrency = "";
  currencyList: Currency[] = [];

  dropdownUrl = this.imageUrl("dropdown");

  getTopFiveCurrencies(): Currency[] {
    return this.allCurrencies.filter((s) => s.isTopFive);
  }

  imageCss(imageUrl: string): Record<string, string> {
    return {
      "-webkit-mask-image": "url(" + imageUrl + ")",
      "mask-image": "url(" + imageUrl + ")",
    };
  }

  clearSelection(): void {
    this.currencySelected = false;
  }

  setOption(newValue: string): void {
    this.currencyList = this.allCurrencies;
    this.selectedCurrency = newValue;
    this.currencySelected = true;
    this.setVisibility(false);
    this.$emit("change", newValue);
  }

  toggleClass(): void {
    this.setVisibility(!this.isDropdownOpen);
  }

  setVisibility(visible: boolean): void {
    this.isDropdownOpen = visible;

    this.visibilityClass = visible ? "dropdown-open" : "dropdown-closed";
  }

  created(): void {
    this.currencyList = this.allCurrencies;
    this.selectedCurrency = this.currentCurrency;
    this.topFiveCurrencies = this.getTopFiveCurrencies();
  }

  @Watch("currentCurrency")
  currencyChanges(): void {
    this.selectedCurrency = this.currentCurrency;
    this.currencySelected = true;
  }

  @Watch("selectedCurrency")
  selectedCurrencyChanges(): void {
    if (
      this.selectedCurrency === "" ||
      this.selectedCurrency === this.currentCurrency
    ) {
      this.currencyList = this.allCurrencies;
    } else {
      this.currencyList = this.allCurrencies.filter((s) =>
        s.key.includes(this.selectedCurrency.toUpperCase())
      );
    }
  }

  /*
    The next 3 functions are used to close the dropdown if the user clicks outside it
   */
  mounted(): void {
    document.addEventListener("click", this.close);
  }

  beforeDestroy(): void {
    document.removeEventListener("click", this.close);
  }

  close(e: Event): void {
    const node = e.target as Node;

    if (!this.$el.contains(node)) {
      this.setVisibility(false);
    }
  }
}
