
import { Component, Vue } from "vue-property-decorator";
import Security from "@/models/security/Security";
import VirtualScroll from "@/pages/analytics/components/table/VirtualScroll.vue";
import PortfolioTableHeader from "@/pages/analytics/components/table/PortfolioTableHeader.vue";
import { SortDirection } from "@/enums/SortDirection";
import { mixins } from "vue-class-component";
import SecurityHelperMixin from "@/mixin/SecurityHelperMixin";
import SecuritySorter from "@/pages/analytics/services/SecuritySorter";

@Component({
  components: {
    VirtualScroll,
    PortfolioTableHeader,
  },
})
export default class PortfolioTable extends mixins(Vue, SecurityHelperMixin) {
  notFetchedSecuritiesWatcher!: () => void;

  get importingBulkData(): boolean {
    return this.$store.state.importingBulkData;
  }

  get sortedSecurities(): Array<Security> {
    const sortedValidSecurities = SecuritySorter.sort(
      this.validSecurities,
      this.currentlySortedColumn,
      this.currentSortDirection
    );

    const sortedInvalidSecurities = SecuritySorter.sort(
      this.invalidSecurities,
      this.currentlySortedColumn,
      this.currentSortDirection
    );

    return sortedValidSecurities.concat(sortedInvalidSecurities);
  }

  get currentlySortedColumn(): string {
    return this.$store.state.portfolioTable.currentlySortedColumn;
  }

  get currentSortDirection(): SortDirection {
    return this.$store.state.portfolioTable.currentSortDirection;
  }

  get invalidSecurities(): Array<Security> {
    return this.$store.state.invalidSecurities;
  }

  created(): void {
    // watcher: detects requests in progress and scrollsIntoView maximal every 500ms
    let timeout = false;
    this.notFetchedSecuritiesWatcher = this.$store.watch(
      (state, getters) => {
        return getters.invalidSecuritiesCount;
      },
      () => {
        if (!timeout && !this.$store.state.importingBulkData) {
          timeout = true;
          window.setTimeout(() => {
            const securities = document.querySelectorAll(".security-table-row");
            const invalidSecuritiesCount =
              this.$store.getters.invalidSecuritiesCount;
            const lastSecurity: Element | null =
              securities[securities.length - invalidSecuritiesCount];

            if (lastSecurity) {
              lastSecurity.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
            }

            timeout = false;
          }, 500);
        }
      }
    );
  }
}
