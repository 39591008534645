
import { Component, Vue } from "vue-property-decorator";
import CheckEmailMixin from "@/mixin/CheckEmailMixin";
import { mixins } from "vue-class-component";
import { BRow } from "bootstrap-vue";
import { OverviewRoute, RegisterRoute } from "@/router/Routes";
import AuthCard from "./components/AuthCard.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import PasswordField from "./components/PasswordField.vue";
import EmailField from "./components/EmailField.vue";
import TokenStorage from "@/auth/services/TokenStorage";
import router from "@/router";
import store from "@/store/main";
import Config from "@/constants/Config";

@Component({
  components: {
    BRow,
    AuthCard,
    BaseButton,
    EmailField,
    PasswordField,
  },
})
export default class Login extends mixins(Vue, CheckEmailMixin) {
  message = "";
  registerRoutePath = RegisterRoute.path;

  setEmail(newVal: string): void {
    this.email = newVal;
  }

  get isButtonEnabled(): boolean {
    return this.emailIsValid();
  }

  mounted(): void {
    if ("jwt" in this.$route.query && "refresh_jwt" in this.$route.query) {
      this.handleCallbackFromAuthClient();
    }
  }

  openAuthClient(): void {
    const url = new URL("/login", Config.AuthClientUrl);
    url.searchParams.set("app_id", Config.AuthApplicationId);
    url.searchParams.set("email", this.email);
    url.searchParams.set(
      "redirect_url",
      window.location.protocol + "//" + window.location.host
    );
    url.searchParams.set("locale", "en");
    window.open(url.toString(), "_self");
  }

  handleCallbackFromAuthClient(): void {
    const { jwt, refresh_jwt: refreshJwt } = this.$route.query as Record<
      string,
      string
    >;
    TokenStorage.storeToken(jwt);
    TokenStorage.storeRefreshToken(refreshJwt);

    if (!TokenStorage.isEmailVerified()) {
      router.push({ name: RegisterRoute.name });
    } else {
      store.commit("auth/setAuthState");
      // clear the state to set Role-dependant variables correctly after login
      this.$store.dispatch("clearAllStores");
      router.push({ name: OverviewRoute.name });
    }
  }
}
