
import { Chart } from "highcharts-vue";
import { Component, Vue } from "vue-property-decorator";
import Security from "@/models/security/Security";
import { Dictionary, groupBy, isNaN, round, sum } from "lodash";
import BaseCard from "@/pages/overview/components/base/BaseCard.vue";
import variablePieInit from "highcharts/modules/variable-pie";
import Highcharts, {
  ChartOptions,
  FormatterCallbackFunction,
  Options,
  PlotPieOptions,
  SeriesPieOptions,
  TooltipOptions,
} from "highcharts";
import { mixins } from "vue-class-component";
import SecurityHelperMixin from "@/mixin/SecurityHelperMixin";
import { Alignment } from "@/enums/Alignment";
import { ALIGNMENT_THRESHOLD } from "@/constants/AlignmentThreshold";
import { PARIS_ALIGNMENT_TARGET_YEAR } from "@/constants/TargetYear";

variablePieInit(Highcharts);

@Component({
  components: { BaseCard, highcharts: Chart },
})
export default class AlignedSecuritiesBreakdownCard extends mixins(
  Vue,
  SecurityHelperMixin
) {
  isAligned = true;
  alignedKey = Alignment.ALIGNED;
  notAlignedKey = Alignment.NOT_ALIGNED;

  get parisAlignmentPrefix(): string {
    if (this.$store.state.targetYear === PARIS_ALIGNMENT_TARGET_YEAR) {
      return "Paris";
    }

    return "";
  }

  get segments(): Array<Record<string, number | string | boolean | null>> {
    return Object.entries(this.groupedSecuritiesByGapSign)
      .map(([key, securities]) => {
        const securityPercentage = round(this.securitiesPercentage(key), 2);

        return {
          name: null,
          isAligned: key === Alignment.ALIGNED,
          color: key === Alignment.ALIGNED ? "#def7f166" : "white",
          borderColor: "#AAB3AE",
          borderWidth: 1,
          x: securities.length,
          y: securityPercentage,
        };
      })
      .sort((segment) => (segment.isAligned ? -1 : 1));
  }

  securitiesPercentage(alignment: string): number {
    const securitiesByGapSign = this.groupedSecuritiesByGapSign[alignment];

    if (!securitiesByGapSign) {
      return 0;
    }

    const group = securitiesByGapSign.filter(
      (security) =>
        !isNaN(security.marketValueUSD) && security.marketValueUSD !== 0
    );

    if (group && group.length) {
      const normalizedValues = this.normalizedMarketValuesUSD(group);

      return sum(normalizedValues);
    }

    return 0;
  }

  get groupedSecuritiesByGapSign(): Dictionary<Security[]> {
    const getSelectedScenarioAlignment = (security: Security): Alignment =>
      security.baselineXDC.total < ALIGNMENT_THRESHOLD
        ? this.alignedKey
        : this.notAlignedKey;

    return groupBy(
      this.validSecuritiesWithScenarioGap,
      getSelectedScenarioAlignment
    );
  }

  get validSecuritiesWithScenarioGap(): Array<Security> {
    return this.validSecurities.filter((security: Security) => {
      return !isNaN(security.baselineXDC.total);
    });
  }

  formatTooltip: FormatterCallbackFunction<Record<string, any>> = function () {
    let content = `<span class="white-standard highcharts-tooltip">`;

    content += `<span class="highcharts-tooltip-assets">${this.x}</span> <br> ${
      this.x > 1 ? "Assets" : "Asset"
    }</span>`;

    return content;
  };

  get highchartsOptions(): Options {
    const chart: ChartOptions = {
      type: "pie",
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      backgroundColor: "transparent",
      borderWidth: 0,
      plotBorderWidth: 0,
    };

    const pieOptions: PlotPieOptions = {
      allowPointSelect: false,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      description: "Alignment Breakdown Chart",
      size: "100%",
      slicedOffset: 3,
      shadow: {
        color: "#c9d2cd66",
        offsetX: -2,
        offsetY: 2,
        width: 5,
      },
    };

    const series: Array<SeriesPieOptions> = [
      {
        type: "pie",
        innerSize: "0",
        borderWidth: 0,
        data: this.segments.map((segment) => ({
          ...segment,
          dataLabels: { enabled: false },
          states: {
            hover: {
              color: segment.isAligned ? "#00C28A" : "#C20044",
              borderColor: segment.isAligned ? "#00c28a" : "#c20044",
              borderWidth: 2,
            },
          },
        })),
        point: {
          events: {
            mouseOver: this.onMouseOver,
            mouseOut: this.onMouseOut,
          },
        },
      },
    ];

    const tooltip: TooltipOptions = {
      className: `tooltip-aligned ${
        this.isAligned ? "background-green-hover" : "background-red-hover"
      }`,
      useHTML: true,
      headerFormat: "",
      pointFormatter: this.formatTooltip,
      shadow: false,
    };

    return {
      chart,
      credits: { enabled: false },
      plotOptions: { pie: pieOptions },
      series,
      title: { text: "" },
      tooltip,
    };
  }

  onMouseOver(event: Event): void {
    const point = event.target as EventTarget & Record<string, any>;
    this.isAligned = point.isAligned;
  }

  onMouseOut(): void {
    this.isAligned = true;
  }
}
