import AuthGateway from "@/auth/services/AuthGateway";
import Config from "@/constants/Config";
import { RestType } from "@/auth/config/RestType";

export default class InformationGateway {
  static BASE_URL = Config.ApiGatewayUrl + "/api/v1/pe";

  static getXDCVersion(): Promise<string> {
    return AuthGateway.sendRequestWithAuth(
      this.BASE_URL,
      RestType.GET,
      "/engine-version",
      {},
      {}
    )
      .then((response) => {
        if (response === undefined) {
          return "";
        }

        return response.data as string;
      })
      .catch(() => {
        return "";
      });
  }
}
