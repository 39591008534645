
import { Component, Vue } from "vue-property-decorator";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import ShowcaseItem from "@/pages/landing/components/ShowcaseItem.vue";
import { PlansRoute } from "@/router/Routes";
import { mixins } from "vue-class-component";
import AssetMixin from "../../mixin/AssetMixin";
import TitleImageAndHeadline from "@/pages/landing/components/TitleImageAndHeadline.vue";
import UserMixin from "@/mixin/UserMixin";

@Component({
  components: {
    TitleImageAndHeadline,
    ShowcaseItem,
    BaseButton,
  },
})
export default class LandingPage extends mixins(Vue, AssetMixin, UserMixin) {
  plansRoutePath = PlansRoute.path;
  showPlaceholderImage = true;
  isScrolledToTop = true;

  showcaseItems = [
    {
      tag: "FREE",
      title: "All in one single °C number",
      text: "Explore and understand your portfolio’s climate impact. <br> Compare it directly to our global climate targets. <br> All with just one intuitive number.",
      imagePath: "images/landing/Highlight_Feature-A.png",
      position: "left",
    },
    {
      tag: "FREE & PREMIUM",
      title: "Change you can see",
      text: "No need to wait. Steer your portfolio alignment by adjusting the available parameters (PREMIUM), adding, or removing assets, and immediately see the effect.",
      imagePath: "images/landing/Highlight_Feature-B.png",
      position: "right",
    },
    {
      tag: "PREMIUM",
      title: "Set your standards",
      text: "Check your portfolio against different scenarios: Choose between several providers and select a SSP pathway. <br> Adapt the timeframe to best suit your strategic goals.",
      imagePath: "images/landing/Highlight_Feature-C.png",
      position: "left",
    },
    {
      tag: "PREMIUM PLUS",
      title: "Multi-Asset Integration",
      text: "Introducing sovereign bonds! <br> Manage and steer the climate impact of your multi-asset equity, corporate, and sovereign bond portfolio. <br> Includes 168 countries for maximum flexibility.",
      imagePath: "images/landing/Highlight_Feature-D.png",
      position: "right",
    },
  ];

  created(): void {
    window.addEventListener("scroll", this.checkIfIsScrolledToTop);
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.checkIfIsScrolledToTop);
  }

  checkIfIsScrolledToTop(): void {
    let top = document.documentElement.scrollTop;
    this.isScrolledToTop = top === 0;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
