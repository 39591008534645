
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AssetMixin from "../../../../../mixin/AssetMixin";
import { ModalID } from "@/enums/ModalID";
import ActionButton from "@/pages/shared/components/buttons/ActionButton.vue";
import UserMixin from "@/mixin/UserMixin";
import { ActionID } from "@/enums/ActionID";

@Component({
  components: { ActionButton },
})
export default class ConfigurationAction extends mixins(
  Vue,
  AssetMixin,
  UserMixin
) {
  id = ActionID.CONFIGURATION;

  openModal(): void {
    if (this.isUserFreeTier) {
      // We show the upgrade instead of the settings
      Vue.prototype.$modal.show(ModalID.UPGRADE_MODAL);
    } else {
      Vue.prototype.$modal.show(ModalID.CHANGE_CONFIGURATION);
    }
  }

  get tooltipText(): string {
    if (this.isUserFreeTier) {
      return "Upgrade to be able to change Settings";
    }

    return "Settings";
  }
}
