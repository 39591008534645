import { Component, Vue } from "vue-property-decorator";
import {
  FAQRoute,
  LandingRoute,
  LoginRoute,
  PlansRoute,
  RegisterRoute,
} from "@/router/Routes";

@Component
export default class HeaderCheckMixin extends Vue {
  noHeaderRoutes = [
    LandingRoute.name,
    LoginRoute.name,
    RegisterRoute.name,
    FAQRoute.name,
    PlansRoute.name,
  ];

  contactRoutes = [LoginRoute.name];

  get isNoHeaderRoute(): boolean {
    const currentRoute = this.$route.name || "";

    return this.noHeaderRoutes.includes(currentRoute);
  }

  get isInLoginWorkflow(): boolean {
    const currentRoute = this.$route.name || "";

    return this.contactRoutes.includes(currentRoute);
  }
}
