import PortfolioValues from "@/models/PortfolioValues";
import Security from "@/models/security/Security";
import SecurityRequestPayload from "@/models/SecurityRequestPayload";
import PortfolioGateway from "@/services/PortfolioGateway";
import { ActionTree } from "vuex";
import { State } from "./State";
import { chunk, filter, map } from "lodash";
import store from "@/store/main/index";
import Config from "@/constants/Config";
import { Status } from "@/enums/Status";
import CurrencyGateway from "@/services/CurrencyGateway";

export const actions: ActionTree<State, State> = {
  clearAllStores({ commit }: { commit: any }) {
    commit("clearState");
    commit("portfolioTable/clearState");
    commit("informationStore/clearState");
  },
  addLoadingSecurity({ commit }, payload: SecurityRequestPayload[]) {
    const loadingSecurities = payload.map((s) => Security.loading(s));
    commit("upsertToInvalidSecurities", loadingSecurities);
  },
  addSecurities({ state, commit }, payload: Array<SecurityRequestPayload>) {
    store.commit("increaseCurrentSecurityDataCalls");

    if (state.ongoingDataRequests <= Config.MAX_CONCURRENT_REQUESTS) {
      store.dispatch("addLoadingSecurity", payload);
      backendSecurityRequest.call(this, payload, state, commit);
    } else {
      store.commit("addSecurityRequestToQueue", payload);
    }
  },
  updateSecurity({ commit }, payload: Security): void {
    if (payload.hasError()) {
      commit("upsertToInvalidSecurities", [payload]);
    } else {
      commit("upsertToValidSecurities", [payload]);
    }
  },
  updateSecurities({ commit }, baseYear: number): void {
    const combinedSecurities = this.state.validSecurities.concat(
      this.state.invalidSecurities
    );
    commit("deleteAllValidSecurities");
    commit("deleteAllInvalidSecurities");

    const securities = map(
      combinedSecurities.filter(
        (s: Security) => s.isin && s.marketValue >= 0 && s.currency
      ),
      (s: Security) =>
        new SecurityRequestPayload(s.isin, s.marketValue, s.currency, baseYear)
    );

    const chunks = chunk(securities, Config.REQUEST_CHUNK_SIZE);

    chunks.forEach((chunk) => store.dispatch("addSecurities", chunk));
  },
  deleteSecurities({ commit }, selectedSecurityISINs: Array<string>) {
    commit("deleteFromValidSecurities", selectedSecurityISINs);
    commit("deleteFromInvalidSecurities", selectedSecurityISINs);
    commit("clearSelectedSecurityISINs");
  },
  updateSecurityFromBackend({ state, commit }, payload: Security): void {
    const baseYear = state.baseYear;
    const securityRequestPayload = new SecurityRequestPayload(
      payload.isin,
      payload.marketValue,
      payload.currency,
      baseYear
    );
    store.commit("increaseCurrentSecurityDataCalls");

    if (state.ongoingDataRequests <= Config.MAX_CONCURRENT_REQUESTS) {
      backendSecurityRequest.call(
        this,
        [securityRequestPayload],
        state,
        commit
      );
    } else {
      store.commit("addSecurityRequestToQueue", securityRequestPayload);
    }
  },
  addLoadingPortfolioValues({ commit }) {
    commit("setPortfolioValues", new PortfolioValues(Status.LOADING));
  },
  calculatePortfolioValues({ commit, state }) {
    PortfolioGateway.getPortfolioInformation(
      state.baseYear,
      state.validSecurities
    )
      .then((portfolioValues) => commit("setPortfolioValues", portfolioValues))
      .catch((error) => {
        console.error(`Failed to add portfolio values with Error: ${error}`);
      });
  },
  async addCurrencies({ commit }) {
    await CurrencyGateway.getCurrencies()
      .then((response) => commit("setCurrencies", response))
      .catch((error) => {
        console.error(
          `Failed to load supported currencies with Error: ${error}`
        );
      });
  },
};

function backendSecurityRequest(
  payload: Array<SecurityRequestPayload>,
  state: any,
  commit: any
): void {
  PortfolioGateway.getSecurity(payload)
    .then((securities) => {
      // To avoid adding data if we reset the portfolio
      if (state.ongoingDataRequests <= 0) {
        return;
      }

      // Deal with failed securities
      const failed = filter(securities, (s) => s.hasError());

      if (failed.length !== 0) {
        commit(
          "deleteFromValidSecurities",
          failed.map((s) => s.isin)
        );
        commit("upsertToInvalidSecurities", failed);
      }

      // Then deal with good securities
      const succeeded = filter(securities, (s) => !s.hasError());

      if (succeeded.length !== 0) {
        commit(
          "deleteFromInvalidSecurities",
          succeeded.map((s) => s.isin)
        );
        commit("upsertToValidSecurities", succeeded);
      }
    })
    .catch((error) => {
      console.error(error);

      commit(
        "deleteFromValidSecurities",
        payload.map((s) => s.isin)
      );
      commit("upsertToInvalidSecurities", payload);
    })
    .finally(() => {
      store.commit("decreaseCurrentSecurityDataCalls");

      if (state.companyRequestQueue.length > 0) {
        const newPayload = state.companyRequestQueue.pop();
        backendSecurityRequest(newPayload, state, commit);
      }

      // If this is part of a CSV import or reload of portfolio and there are no more calls
      // to be done we set the importingBulkData to false and trigger a portfolio calculation
      if (state.importingBulkData && state.ongoingDataRequests === 0) {
        store.commit("setImportingBulkData", false);
        store.dispatch("calculatePortfolioValues");
      }
    });
}
