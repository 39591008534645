import { Component, Vue } from "vue-property-decorator";
import { isEmpty } from "lodash";

@Component
export default class CurrenciesMixin extends Vue {
  getCurrencies(): void {
    if (isEmpty(this.$store.state.currencies)) {
      this.fetchCurrencies();
    }
  }

  private fetchCurrencies(): void {
    this.$store.dispatch("addCurrencies");
  }
}
