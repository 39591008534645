
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PortfolioTableRow from "@/pages/analytics/components/table/PortfolioTableRow.vue";
import Security from "@/models/security/Security";

@Component({
  components: { PortfolioTableRow },
})
export default class VirtualScroll extends Vue {
  @Prop({ required: true }) securities!: Security[];
  rowHeight = 40;
  nodePadding = 5;

  visibleChildren: Security[] = [];

  // Initial values, these will be overwritten
  offsetY = 0;
  viewportHeight = 300;

  get itemCount(): number {
    return this.securities.length;
  }

  get totalContentHeight(): number {
    return this.itemCount * this.rowHeight;
  }

  getSecurityIndex(security: Security): number {
    const securityIndex = this.securities.findIndex(
      (s) => s.isin === security.isin
    );

    return securityIndex + 1;
  }

  scrollTop(): number {
    const elementById = document.getElementById("portfolio-table-container");

    if (elementById) {
      return elementById.scrollTop;
    }

    return 0;
  }

  updateViewportHeight(): void {
    // We get the portfolio-container for its height so that we can leave
    // the portfolio-table height big in order for the sticky header to work
    const parentElement = (this.$refs.vscrollViewport as HTMLElement)
      ?.parentElement?.parentElement;

    if (parentElement) {
      // 62px is the height of the header + 16px of paddings
      this.viewportHeight = parentElement.offsetHeight - 78;
    }
  }

  /**
   Out of all the items in the massive array, we only render a subset of them
   This is the starting index from which we show a few items
   */
  startNode(): number {
    const startNode =
      Math.floor(this.scrollTop() / this.rowHeight) - this.nodePadding;

    if (startNode > this.totalContentHeight) {
      return this.totalContentHeight;
    }

    return Math.max(0, startNode);
  }

  /**
   This is the number of items we show after the starting index
   If the array has a total 10000 items, we want to show items from say index 1049 till 1069
   visible node count is that number 20 and starting index is 1049
   */
  visibleNodesCount(): number {
    const visibleNodesCount =
      Math.ceil(this.viewportHeight / this.rowHeight) + 2 * this.nodePadding;

    return Math.min(this.itemCount - this.startNode(), visibleNodesCount);
  }

  updateOffsetY(): void {
    this.offsetY = this.startNode() * this.rowHeight + 1;
  }

  updateVisibleChildren(): void {
    if (this.securities.length === 0) {
      this.visibleChildren = [];
    } else {
      const nodesCount = this.visibleNodesCount();
      const referencePoint = this.startNode();

      this.visibleChildren = new Array(nodesCount)
        .fill(null)
        .map((_, index) => this.securities[index + referencePoint]);
    }
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.updateViewportHeight);
    const elementById = document.getElementById("portfolio-table-container");

    if (elementById) {
      elementById.removeEventListener("scroll", this.updateTable);
    }
  }

  mounted(): void {
    window.addEventListener("resize", this.updateViewportHeight);
    const elementById = document.getElementById("portfolio-table-container");

    if (elementById) {
      elementById.addEventListener("scroll", this.updateTable);
    }

    this.securitiesChanges();
  }

  updateTable(): void {
    this.updateOffsetY();
    this.updateVisibleChildren();
  }

  @Watch("securities")
  securitiesChanges(): void {
    this.heightFixForStickyHeader();
    this.updateViewportHeight();
    this.updateVisibleChildren();
  }

  private heightFixForStickyHeader(): void {
    const elementById = document.getElementById("portfolio-table");

    if (elementById) {
      elementById.style.height = `${this.securities.length * this.rowHeight}px`;
    }
  }

  /**
   Security Specific Functions
   */
  get selectedSecurityISINs(): Array<string> {
    return this.$store.state.selectedSecurityISINs;
  }

  isSecuritySelected(isin: string): boolean {
    return this.selectedSecurityISINs.indexOf(isin) !== -1;
  }

  toggleSecuritySelection(isin: string): void {
    this.isSecuritySelected(isin)
      ? this.$store.commit("deleteFromSelectedSecurityISINs", isin)
      : this.$store.commit("addToSelectedSecurityISINs", isin);
  }
}
