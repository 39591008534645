import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import portfolioTable from "@/store/portfolio/portfolioTable";
import { State, state } from "./State";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import informationStore from "@/store/information/InformationStore";
import { authStoreModule } from "@/auth/store/auth";

Vue.use(Vuex);

export const storeOptions: StoreOptions<State> = {
  state,
  getters,
  actions,
  mutations,
};

export const storeOptionsWithModules: StoreOptions<State> = {
  modules: { auth: authStoreModule, portfolioTable, informationStore },
  ...storeOptions,
};

export default new Vuex.Store(storeOptionsWithModules);
