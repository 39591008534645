
import { Component, Vue } from "vue-property-decorator";
import BaseModal from "@/pages/main/components/base/BaseModal.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { BCol, BRow } from "bootstrap-vue";
import ParameterSelector from "@/pages/main/components/toolbar/configuration/components/ParameterSelector.vue";
import { mixins } from "vue-class-component";
import FeedbackMixin from "@/mixin/FeedbackMixin";
import {
  ChangeLog,
  ChangeLogParser,
  LineStateType,
} from "markdown_changelog_parser";
import { ModalID } from "@/enums/ModalID";
import AssetMixin from "../../../mixin/AssetMixin";

@Component({
  components: {
    ParameterSelector,
    BaseModal,
    BaseButton,
    BRow,
    BCol,
  },
})
export default class ChangeLogModal extends mixins(
  Vue,
  FeedbackMixin,
  AssetMixin
) {
  _changelogs: ChangeLog[] = [];
  private readonly id = ModalID.CHANGELOG_MODAL;

  created(): void {
    this.loadChangeLogs();
  }

  loadChangeLogs(): void {
    // in prod raw-loader uses default attribute in tests not, review when webpack in vue got updated
    const changeLogUrl: Record<string, any> | string = this.changeLogUrl();
    const fileContent =
      typeof changeLogUrl === "object" ? changeLogUrl.default : changeLogUrl;
    const changeLogParser = new ChangeLogParser(fileContent);
    changeLogParser.parse();
    this._changelogs = changeLogParser.changeLogs;
  }

  changeLogDate(date: Date | null): string {
    if (date === null) {
      return "";
    }

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  dateIsNotNull(changelog: ChangeLog): boolean {
    return changelog.date !== null;
  }

  versionIsNotNull(changelog: ChangeLog): boolean {
    return changelog.version !== null;
  }

  get changelogs(): ChangeLog[] {
    return this._changelogs;
  }

  descriptionStyle(type: LineStateType): string {
    if (type === LineStateType.DESCRIPTION_BULLET) {
      return "bullet-point";
    } else {
      return "text";
    }
  }
}
