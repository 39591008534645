
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CheckEmailMixin from "@/mixin/CheckEmailMixin";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import {
  CompleteVerificationRoute,
  LoginRoute,
  PlansRoute,
} from "@/router/Routes";
import {
  BCard,
  BCol,
  BForm,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BRow,
} from "bootstrap-vue";
import { FusionAuthUser } from "@/auth/models/FusionAuthUser";
import PasswordField from "@/auth/pages/components/PasswordField.vue";
import { AxiosError, AxiosResponse } from "axios";
import { FusionAuthApplicationRegistration } from "@/auth/models/FusionAuthApplicationRegistration";
import AuthConfig from "@/auth/config/AuthConfig";
import { FREE_TIER_ROLE } from "@/constants/Roles";
import { FusionAuthRegistrationRequest } from "@/auth/models/RegistrationRequest";
import store from "@/store/main";
import router from "@/router";
import AuthGateway from "@/auth/services/AuthGateway";
import { RestType } from "@/auth/config/RestType";
import Config from "@/constants/Config";

@Component({
  components: {
    BaseButton,
    PasswordField,
    BRow,
    BCol,
    BCard,
    BForm,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
  },
})
export default class Register extends mixins(Vue, CheckEmailMixin) {
  loginRoutePath = LoginRoute.path;
  routePlansPath = PlansRoute.path;

  firstName = "";
  lastName = "";
  password = "";
  agreedToPrivacyPolicy = false;

  firstNameState: boolean | null = null;
  lastNameState: boolean | null = null;

  setPassword(newVal: string): void {
    this.password = newVal;
  }

  checkFirstName(): void {
    this.firstNameState = this.isValidName(this.firstName) ? null : false;
  }

  checkLastName(): void {
    this.lastNameState = this.isValidName(this.lastName) ? null : false;
  }

  isValidName(value: string): boolean {
    return /^[^±!@£$%^&*_+§¡"€#¢¶•ªº«\\/<>?:;|=.,0-9]{0,32}$/.test(value);
  }

  canRegister(): boolean {
    return (
      this.isValidName(this.firstName) &&
      this.isValidName(this.lastName) &&
      !this.isEmpty(this.firstName) &&
      !this.isEmpty(this.lastName) &&
      this.emailIsValid() &&
      this.agreedToPrivacyPolicy
    );
  }

  isEmpty(value: string): boolean {
    return value === "";
  }

  register(): void {
    if (Config.DEV_MODE) {
      alert("Not available in DEV_MODE");

      return;
    }

    const user: FusionAuthUser = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
    };

    const applicationRegistration: FusionAuthApplicationRegistration = {
      applicationId: AuthConfig.AUTH_APPLICATION_ID,
      username: user.firstName + user.lastName,
      roles: [FREE_TIER_ROLE],
    };

    const fusionAuthRegistrationRequest: FusionAuthRegistrationRequest = {
      registration: applicationRegistration,
      user: user,
      sendSetPasswordEmail: false,
      skipRegistrationVerification: true,
      skipVerification: false,
    };

    const headers = {
      Authorization: AuthConfig.AUTH_TENANT_API_KEY,
      "X-FusionAuth-TenantId": AuthConfig.AUTH_TENANT_ID,
    };

    AuthGateway.sendRequest(
      AuthConfig.AUTH_GATEWAY_BASE_URL,
      RestType.POST,
      AuthConfig.RegistrationPath,
      headers,
      fusionAuthRegistrationRequest
    )
      .then(() => {
        router.push({ name: CompleteVerificationRoute.name });
      })
      .catch((error: AxiosError) => {
        const response: AxiosResponse | undefined = error.response;
        const statusCode = response?.status;

        let message: string;

        if (statusCode === 400 && response) {
          message = response.data.fieldErrors["user.email"][0].message;
        } else {
          message = "Registration failed. Please try again later.";
        }

        store.commit("setErrorMessage", message);
      });
  }
}
