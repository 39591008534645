
import { Component, Prop, Vue } from "vue-property-decorator";
import AccordionItem from "@/pages/faq/models/AccordionItem";
import { BIconChevronRight } from "bootstrap-vue";

@Component({ components: { BIconChevronRight } })
export default class BaseAccordion extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) entries!: AccordionItem[];

  toggle(entry: AccordionItem, e: Event): void {
    const element = e.target as HTMLElement;
    const parentElement = element.parentElement;

    // If the object clicked was the text we don't want to do anything
    if (this.isTextClicked(element)) {
      return;
    }

    // Toggle the state of the entry
    entry.isSelected = !entry.isSelected;

    // Find the text item
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let itemText = this.findTextItem(parentElement!);

    // Change the height in order to trigger accordion animation
    if (itemText) {
      if (entry.isSelected) {
        itemText.style.height = itemText.scrollHeight + "px";
      } else {
        itemText.style.height = "";
      }
    }
  }

  isTextClicked(element: HTMLElement): boolean {
    let localElement: HTMLElement | null = element;

    const els = [];
    while (localElement) {
      els.unshift(localElement);
      localElement = localElement.parentElement;

      if (localElement?.classList.contains("item-wrapper")) {
        break;
      }
    }

    for (const el of els) {
      if (el.classList.contains("item-text")) {
        return true;
      }
    }

    return false;
  }

  findTextItem(parentElement: HTMLElement): any {
    let element: HTMLElement | undefined | null = parentElement;
    let foundElement = undefined;

    while (!foundElement) {
      foundElement = element?.getElementsByClassName("item-text")[0];
      element = element?.parentElement;

      //the body tag is the furthest we can go, so we stop there to avoid getting stuck in the loop forever
      if (element?.tagName == "body") {
        break;
      }
    }

    return foundElement as HTMLElement;
  }
}
