
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseButton extends Vue {
  @Prop({ required: false, default: "primary", type: String }) variant!: string;
  @Prop({ required: false, default: "medium", type: String }) size!: string;
  @Prop({ required: false, default: "button", type: String }) type!: string;
  @Prop({ required: false, default: false, type: Boolean }) disabled!: boolean;
  @Prop({ required: false, default: "", type: String }) routeToPath!: string;

  emitClick(): void {
    if (this.routeToPath !== "") {
      this.$router.push({ path: this.routeToPath });
    }

    this.$emit("click");
  }
}
