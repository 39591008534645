import FileHandler from "@/services/FileHandler";
import { unparse } from "papaparse";

export default class CSVExporter extends FileHandler {
  static createCSV(exportValues: Array<Record<string, any>>): string {
    return unparse(exportValues, { delimiter: ";" });
  }

  static executeDownload(
    csvString: string,
    portfolioName: string,
    fileNameSuffix = ""
  ): void {
    const fileName = this.createFileName(portfolioName, fileNameSuffix, ".csv");

    this.downloadCSV(csvString, fileName);
  }
}
