
import { Component, Vue } from "vue-property-decorator";
import BaseAlert from "@/pages/main/components/base/BaseAlert.vue";
import BaseButton from "@/pages/shared/components/buttons/BaseButton.vue";
import { AlertID } from "@/enums/AlertID";

@Component({
  components: {
    BaseAlert,
    BaseButton,
  },
})
export default class AppUpdateAlert extends Vue {
  private readonly alertID = AlertID.APP_UPDATE_AVAILABLE;

  refreshing = false;
  registration: ServiceWorkerRegistration | null = null;
  updateExists = false;

  created(): void {
    document.addEventListener("appUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker?.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }

      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  close(): void {
    (this.$refs[this.alertID] as BaseAlert).hide();
  }

  reloadPage(): void {
    this.updateExists = false;

    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    this.registration?.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  // Store the SW registration, so we can send it a message
  updateAvailable(event: Event): void {
    this.registration = (event as CustomEvent).detail;
    this.updateExists = true;
    (this.$refs[this.alertID] as BaseAlert).show();
  }
}
