import { Component, Vue } from "vue-property-decorator";
import CSVImportFeedback from "@/models/CSVImportFeedback";

@Component
export default class FeedbackMixin extends Vue {
  showFeedback(
    securitiesCount: number,
    alreadyPresentISINs: string[],
    previouslyValidCount: number,
    previouslyInvalidISINs: string[],
    incorrectFormatISINs: string[],
    errorMessage: string
  ): void {
    const feedback = new CSVImportFeedback(
      securitiesCount,
      alreadyPresentISINs,
      previouslyValidCount,
      previouslyInvalidISINs,
      incorrectFormatISINs,
      errorMessage
    );

    this.$store.commit("setCSVFeedback", feedback);
  }

  resetFeedback(): void {
    const feedback = new CSVImportFeedback(0, [], 0, [], [], "");
    this.$store.commit("setCSVFeedback", feedback);
  }
}
