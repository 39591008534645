
import { Component, Vue } from "vue-property-decorator";
import { BCol, BContainer, BRow } from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import { BASE_YEARS } from "@/constants/BaseYear";
import { TARGET_YEAR } from "@/constants/TargetYear";

@Component({
  components: {
    BRow,
    BContainer,
    BCol,
    VueSlider,
  },
})
export default class BaseAndTargetYearSelector extends Vue {
  private readonly greenStandard = "#00c28a";
  private readonly rangeLineShadow = "#F7F7F7";
  private readonly thumbColor = "#C9D2CD";
  maxBaseYear = BASE_YEARS[0];
  minBaseYear = BASE_YEARS[BASE_YEARS.length - 1];
  minTargetYear = TARGET_YEAR[0];
  maxTargetYear = TARGET_YEAR[TARGET_YEAR.length - 1];

  localSelectedBaseYear = this.$store.state.baseYear;
  localSelectedTargetYear = this.$store.state.targetYear;

  get selectedTargetYear(): number {
    return this.localSelectedTargetYear;
  }

  set selectedTargetYear(value: number) {
    this.localSelectedTargetYear = value;
    this.$emit("change", this.localSelectedTargetYear);
  }

  get selectedBaseYear(): number {
    return this.localSelectedBaseYear;
  }

  set selectedBaseYear(value: number) {
    this.localSelectedBaseYear = value;
    this.$emit("valueChanged", this.localSelectedBaseYear);
  }

  checkForValidBaseYearInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = Number(target.value);

    if (value < this.minBaseYear || value > this.maxBaseYear) {
      this.selectedBaseYear = this.$store.state.baseYear;
    }
  }

  checkForValidTargetYearInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = Number(target.value);

    if (value < this.minTargetYear || value > this.maxTargetYear) {
      this.selectedTargetYear = this.$store.state.targetYear;
    }
  }
}
