
import { Component, Prop, Vue } from "vue-property-decorator";
import PortfolioInformation from "@/pages/main/components/header/portfolioinfo/PortfolioInformation.vue";
import { BCol, BRow } from "bootstrap-vue";
import PortfolioXDCValues from "@/pages/main/components/header/portfoliovalues/PortfolioXDCValues.vue";
import UserMixin from "@/mixin/UserMixin";
import { mixins } from "vue-class-component";

@Component({
  components: {
    PortfolioInformation,
    PortfolioXDCValues,
    BCol,
    BRow,
  },
})
export default class ValuesHeader extends mixins(Vue, UserMixin) {
  @Prop({ required: false, default: false }) compactMode!: boolean;
}
